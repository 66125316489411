<template>
  <section id="about">
    <ul class="support-list">
      <li> ContractSupport je aplikace vyvinutá spol. HRDLIČKA spol. s r.o. - <a :href="'https://www.hrdlicka.cz/'"
          target="_blank"> HRDLIČKA | Geodézie v souvislostech.</a>
      </li>
      <li> <a :href="`${url}cfg/Help/cs/index.html`" target="_blank">Nápověda Aplikace</a>
      </li>
    </ul>

  </section>
</template>

<script>
export default {
  name: 'About',
  data: () => {
    return {
      url: process.env.BASE_URL
    };
  }
};
</script>
<style scoped>
.support-list {
  list-style-type: none;
  font-size: 20px;
  margin: 40px 10px;
}
.support-list li {
  margin: 10px;
}
</style>