<template>
  <div>
    <section class="basic-info">
      <div class="header-with-button">
        <h2 class="basic-header">
          {{this.$router.options.routes.find(({name}) => name === linkTo).meta.heading}}
        </h2>
        <Button
          v-if="formType"
          :isIcon="{type: 'addCircle', size: 21, fill: '#262626'}"
          margin="0 0 5px 0"
          @action="openAddPath(formType)" />
      </div>
      <hr />
      <p v-if="!getLoading && !data.length" class="no_records">
        Žádné položky k zobrazení
      </p>
      <span v-if="data.length">
        <Table
          ref="Table"
          :data="data"
          :pageSize="pageSize"
          selectedClass="table-info"
          :tableHeads="tableHeads"
          :control="control"
          :maxPageLinks="maxPageLinks"
          :hideSinglePage="hideSinglePage"
          :filter="filter"
          :pagination="pagination"
          :keyName="keyName" />
        <Button
          v-if="linkTo"
          class="detail-button"
          label="Zobrazit vše"
          invert
          :linkTo='`/sites/${$router.currentRoute.params.site_id}/`+ linkTo' />
      </span>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Table from '@/components/generic/Table.vue';
import Button from '@/components/generic/Button.vue';
import Spinner from '@/components/generic/Spinner.vue';
import OwnerfoliosForm from '@/components/forms/OwnerfoliosForm.vue';
import OwnersForm from '@/components/forms/OwnersForm.vue';
import ParcelsForm from '@/components/forms/ParcelsForm.vue';

export default {
  name: 'TablePreview',
  components: {
    Table,
    Button,
    Spinner,
    OwnerfoliosForm,
    ParcelsForm,
    OwnersForm
  },
  props: {
    formType: {
      type: [String, Boolean],
      required: false,
      default: true
    },
    data: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      required: false,
      default: 3
    },
    tableHeads: {
      type: Array,
      required: true
    },
    control: {
      type: Object,
      required: true
    },
    maxPageLinks: {
      type: Number,
      required: false,
      default: 1
    },
    hideSinglePage: {
      type: Boolean,
      required: false,
      default: false
    },
    filter: {
      type: Boolean,
      required: false,
      default: false
    },
    pagination: {
      type: Boolean,
      required: false,
      default: true
    },
    linkTo: {
      type: String,
      required: false
    },
    modalHeading: {
      type: String,
      required: false
    },
    keyName: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters(['getLoading'])
  },
  async created() {
    const resource = this.linkTo[0].toUpperCase() + this.linkTo.substring(1);
    const fetchData = 'fetch' + resource;
    await this.fetchResources([fetchData]);
  },
  methods: {
    ...mapActions(['fetchResources']),
    openAddPath(modal) {
      if (modal === 'page') {
        this.$router.push(
          `${this.$router.currentRoute.path}/${this.linkTo}/new`
        );
      } else {
        this.openModalForm({ mode: 'new' });
      }
    },
    openModalForm({ mode }) {
      const FORMS = {
        owners: OwnersForm,
        ownerfolios: OwnerfoliosForm,
        parcels: ParcelsForm
      };
      return this.$parent.$parent.$parent.$refs.modal.openModal({
        type: 'info',
        heading: this.modalHeading,
        component: FORMS[this.linkTo],
        componentProps: { mode },
        btns: {},
        lock: false
      });
    }
  }
};
</script>

<style scoped>
.basic-info {
  margin: 30px 0 30px 0;
  position: relative;
}
.basic-header {
  color: #5889c4;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 5px;
}
.header-with-button {
  display: flex;
  align-items: center;
}

.detail-button {
  position: absolute;
  top: 87%;
  left: 1%;
}
.add-button {
  margin-bottom: 5px;
}
</style>
