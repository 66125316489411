export const easementSPIForm = {
  resource: 'easementSPI',
  schema: [
    {
      type: 'file',
      name: 'spi',
      id: 'spi',
      label: 'Vložte soubor ve formátu VFK (SPI)',
      help: 'Přetažením nebo kliknutím vyberte jeden soubor ve formátu VFK (SPI)',
      'help-class': 'file-form-input-help',
      disabled: true,
      '@change': 'change',
      '@file-removed': 'file-removed',
      '@file-upload-complete': 'file-upload-complete'
    }
  ]
};
