class Storage {
  set(name, value) {
    localStorage.setItem(name, JSON.stringify(value));
  }

  get(name) {
    const storedValue = localStorage.getItem(name);
    return JSON.parse(storedValue);
  }
}

export const storage = new Storage();
