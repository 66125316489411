import axios from 'axios';
import router from '@/router';
import { deepClone } from '@/utils';

const state = {
  contracts: []
};

const getters = {
  getContracts: state => state.contracts,
  getContract: state => _id => state.contracts.find(({ id }) => id === +_id),
  getContractsForTable: ({ contracts }) => {
    return contracts.map(_contract => {
      const contract = deepClone(_contract, ['contractvalues'], 'exclude');
      return { ...contract, ..._contract.contractvalues };
    });
  }
};

const actions = {
  fetchContracts: async ({ commit }) => {
    try {
      const { data } = await axios.get(`/sites/${router.currentRoute.params.site_id}/contracts`);
      commit('setContracts', data.data);
    } catch (error) {
      console.error('Failed to fetch contracts...');
      console.error(error);
    }
  },
  async updateContracts({ commit }, updateContracts) {
    const { site_id } = router.currentRoute.params;
    try {
      const { data } = await axios.patch(`/sites/${site_id}/contracts`, updateContracts);
      commit('updateContracts', data.data);
    } catch (error) {
      console.log('Contracts failed to be updated.');
      console.error(error);
    }
  }
};

const mutations = {
  setContracts: (state, payload) => (state.contracts = payload),
  updateContracts: (state, payload) => {
    payload.forEach(contract => {
      const idx = state.contracts.findIndex(({ id }) => id === contract.id);
      if (idx !== -1) {
        state.contracts.splice(idx, 1, contract);
        state.contracts = [...state.contracts];
      }
    });
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
