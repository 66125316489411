<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz, Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: 'Table-class' component for displaying 'ownerfolios' resource data in a table
:dependencies: Spinner, Table, vuex
:version: 1
:updated: 2021-07-30
-->

<template>
  <div>
    <Spinner v-if="getLoading" />
    <section v-if="!getLoading && getOwnerfolios.length">
      <Table
        ref="Table"
        :data="getOwnerfolios"
        :pageSize="100"
        selectedClass="table-info"
        :tableHeads="TABLE_CONFIG.T_OWNERFOLIOS"
        :control="CONTROL_CONFIG.C_OWNERFOLIOS"
        @deleteRow="removeOwnerfolio($event)" />
    </section>
    <p v-if="!getLoading && !getOwnerfolios.length" class="no_records">
      Žádné položky k zobrazení
    </p>
  </div>
</template>

<script>
import Spinner from '@/components/generic/Spinner.vue';
import Table from '@/components/generic/Table.vue';
import { TABLE_CONFIG, CONTROL_CONFIG } from '@/config';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OwnerfoliosTable',
  components: {
    Spinner,
    Table
  },
  created() {
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.TABLE_CONFIG = TABLE_CONFIG;
    this.fetchResources(['fetchOwnerfolios']);
  },
  computed: mapGetters(['getOwnerfolios', 'getLoading']),
  methods: mapActions(['fetchResources', 'removeOwnerfolio'])
};
</script>
