<template>
  <div class="rulePopup">
    <FormulateInput
      type="rule"
      class="ruleStep ruleColumn"
      label="Pravidlo"
      :options="getRules.map(({code, name}) => ({value: code, label: name}))"
      :rule="rule"
      :mode="mode"
      :subjects="subjects.length >0 ? subjects : []"
      @ruleSelected="selectRule($event)"
      @ruleUpdated="updateRule($event)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RulesForm',
  data() {
    return {
      rule: {}
    };
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    initialRuleValue: {
      type: Object
    },
    subjects: {
      type: Array
    }
  },
  created() {
    this.rule = this.initialRuleValue || {};
  },
  computed: mapGetters(['getRules']),
  methods: {
    selectRule(data) {
      this.rule = { ...this.rule, ...data };
    },
    updateRule({ value, idx }) {
      this.rule.rulevalues[idx].value = value;
      this.rule.rulevalues = [...this.rule.rulevalues];
    }
  }
};
</script>