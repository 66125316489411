<template>
  <div id="app">
    <Login v-if="!isLoggedIn" />
    <div v-else class="container">
      <AppHeader />
      <Menu :config="CONFIG_MENU" />
      <Main :showRouter="true" ref='main'>
        <Breadcrumbs :rootCrumbs="rootCrumbs" />
        <MainButtons />
      </Main>
      <Footer />
    </div>
    <Modal ref="modal" />
  </div>
</template>

<script>
import {
  AppHeader,
  Menu,
  Main,
  Breadcrumbs,
  Footer,
  Modal,
  Button,
  MainButtons,
  Login
} from '@/components';

import { mapActions, mapGetters } from 'vuex';
import { CONFIG_MENU, rootCrumbs } from '@/config';

export default {
  name: 'app',
  components: {
    AppHeader,
    Menu,
    Main,
    Breadcrumbs,
    Footer,
    Modal,
    Button,
    MainButtons,
    Login
  },
  created() {
    this.CONFIG_MENU = CONFIG_MENU;
    this.rootCrumbs = rootCrumbs;
    if (this.loginExpired) this.logout();
    if (this.isLoggedIn) this.getData();
  },
  computed: mapGetters(['getCadastres', 'loginExpired', 'isLoggedIn']),
  methods: {
    ...mapActions(['fetchResources', 'logout']),
    getData() {
      this.fetchResources(['fetchCadastres', 'fetchCodeList']);
      if (process.env.VUE_APP_ENV === 'swagger') {
        console.log(
          '%cSWAGGER VIRT SERVER backed APP INSTANCE',
          'color: red; font-size: 20px; font-weight: 700'
        );
      }
      console.table({
        NODE_ENV: process.env.NODE_ENV,
        BASE_URL: process.env.BASE_URL,
        LANGUAGE: process.env.VUE_APP_LANG
      });
    }
  }
};
</script>

<style >
@import './styles/index.css';
@import './styles/grid.css';
</style>
