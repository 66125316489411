const state = {
  selectedRows: [],
  updatedRows: [],
  tableClipboardConfig: {
    ownerfolio_number: 0,
    parcel_number: 1
  },
  clipboardData: ''
};

const getters = {
  getSelectedRows: state => state.selectedRows,
  getUpdatedRows: state => state.updatedRows,
  getTableClipboardConfig: state => state.tableClipboardConfig,
  getClipboardData: state => state.clipboardData
};

const actions = {
  addRow: async ({ commit }, id) => {
    commit('addRows', id);
  },
  removeRows: async ({ commit }, ids) => {
    commit('removeRows', ids);
  },
  clearRows: async ({ commit }) => {
    commit('clearRows');
  },
  addUpdatedRows: async ({ commit }, row) => {
    commit('addUpdatedRows', row);
  },
  removeUpdatedRows: async ({ commit }, ids) => {
    commit('removeUpdatedRows', ids);
  },
  clearUpdatedRows: async ({ commit }) => {
    commit('clearUpdatedRows');
  },
  changeClipboardTable: async ({ commit }, config) => {
    commit('setTableClipboardConfig', config);
  },
  changeClipboardData: async ({ commit }, data) => {
    commit('setClipboardData', data);
  }
};

const mutations = {
  addRows: (state, payload) => {
    state.selectedRows = [payload, ...state.selectedRows];
  },
  removeRows: (state, payload) => {
    if (!Array.isArray(payload)) {
      throw new Error('Only type of payload accepted by removeRows mutation is Array.');
    }
    state.selectedRows = state.selectedRows.filter(id => !payload.includes(id));
  },
  clearRows: state => {
    state.selectedRows = [];
  },
  addUpdatedRows: (state, payload) => {
    state.updatedRows = [...state.updatedRows, payload];
  },
  removeUpdatedRows: (state, payload) => {
    if (!Array.isArray(payload)) {
      throw new Error('Only type of payload accepted by removeUpdatedRows mutation is Array.');
    }
    payload.forEach(id => (state.updatedRows = state.updatedRows.filter(row => row.id !== id)));
  },
  clearUpdatedRows: state => {
    state.updatedRows = [];
  },
  setTableClipboardConfig: (state, payload) => {
    state.tableClipboardConfig = payload;
  },
  setClipboardData: (state, payload) => {
    state.clipboardData = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
