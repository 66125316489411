import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=1c10a053&scoped=true"
import script from "./ErrorPage.vue?vue&type=script&lang=js"
export * from "./ErrorPage.vue?vue&type=script&lang=js"
import style0 from "./ErrorPage.vue?vue&type=style&index=0&id=1c10a053&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c10a053",
  null
  
)

export default component.exports