<template>
  <div class="login-window">
    <FormulateForm @submit="login($event)" class="login-form">
      <img alt="Úžasné logo společnosti HRDLIČKA spol. s r.o." :src="imgLogo" :style="{ objectFit: 'contain' }"
        height="30px" width="230px" />
      <FormulateInput type="text" name="login" label="Uživatelské jméno" outer-class="login-field-wrapper"
        input-class="login-field-input" />
      <FormulateInput type="password" name="password" label="Heslo" outer-class="login-field-wrapper"
        input-class="login-field-input" />
      <FormulateInput type="submit" label="PŘIHLÁSIT SE" outer-class="login-field-wrapper"
        input-class="login-form-submit-btn" />
    </FormulateForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/generic/Icon.vue';

export default {
  name: 'Login',
  data: () => {
    return {
      imgLogo: `${process.env.BASE_URL}img/logo.png`
    };
  },
  components: {
    AppIcon: Icon
  },
  methods: mapActions(['login'])
};
</script>

<style>
.login-window {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--main-color);
  color: #eee;
  position: relative;
}

.login-form {
  display: grid;
  padding: 2vmin;
  margin-bottom: 60px;
}

.login-field-wrapper {
  display: grid;
  width: 240px;
  margin-top: 15px;
}

.login-field-input {
  padding: 8px 14px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.login-field-input:focus {
  background-color: rgba(255, 255, 255, 0.9);
  outline: none;
}

.login-form-submit-btn {
  padding: 10px;
  font-size: 16px;
  color: #eee;
  background: rgba(0, 50, 200, 0.1);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: 0.45s;
}

.login-form-submit-btn:hover {
  background-color: #eee;
  color: var(--main-color);
  font-weight: 700;
}

.login-wrong-credentials {
  color: rgb(255, 0, 0);
  padding: 15px;
  background-color: rgba(255, 150, 150, 0.45);
  margin-top: -50px;
  border-radius: 5px;
  border: 1px solid rgba(255, 0, 0, 0.45);
}
</style>