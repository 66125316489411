export const ALERT_TEXT = {
  CONTRACTS: 'Vyplňte prosím povinná pole: Název smlouvy a Číslo smlouvy',
  VFK: 'Uložte prosím nahraný soubor VFK',
  SPI: 'Uložte prosím nahraný soubor SPI',
  PDF: 'Uložte prosím nahraný soubor PDF',
  ICO_1: 'Rodné číslo nemůže obsahovat více jak 11 znaků',
  ICO_2: 'Rodné číslo nemůže obsahovat více jak 11 znaků (u druhé osoby)',
  FORMAT: 'Špatný formát souboru. Musí být formát VFK!',
  FORMATPDF: 'Špatný formát souboru. Musí být formát PDF!',
  ERROR_FORMAT: 'Odeslaná data neodpovídají očekávanému formátu, obraťte se na administrátora.',
  ERROR_GENERIC: 'Nastala chyba při odesílání dat, obraťte se prosím na administrátora!',
  missingDigitizationMsg: cadaster =>
    `Katastrální území '${cadaster}', nemá provedenou kompletní digitalizaci, výsledky prostorové analýzy jsou pouze orientační.`,
  cadasterNonExistingMsg: cadaster => `Vybraný katastr '${cadaster}' neexistuje.`
};

export const CONTROL_ALERT = {
  type: 'warning',
  heading: 'Pozor',
  text: 'Máte neuložené změny',
  btns: {},
  customBtns: [
    { label: 'Zahodit změny', type: 'deny', color: 'warning', invert: true },
    { label: 'Uložit změny', type: 'confirm', color: 'warning' }
  ]
};

export const DELETE_ALERT = {
  text: 'Opravdu chcete vymazat vybrané řádky?',
  type: 'warning',
  heading: 'Pozor',
  customBtns: [
    { label: 'Ano', type: 'confirm', color: 'main' },
    { label: 'Ne', type: 'deny', color: 'cancel' }
  ]
};

export const ALERT_EASEMENTS = {
  text: 'Věcná břemena byla úspěšně analyzována',
  type: 'success',
  heading: '',
  btns: {},
  customBtns: [{ label: 'OK', type: 'confirm', color: 'success' }]
};
