<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.type}` "
    :data-type="context.type">
    <select class="ruleValue" name="rank" required v-model.number="context.model">
      <option
        :value="id=index+1"
        v-for="(subject,index) in subjects"
        :key="index">{{index+1}}.{{getSubjectType[subject.subjecttype_code]}}</option>
    </select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FormulateRank',
  data: () => {
    return {
      edit: false,
      buttonText: 'Přidat pravidlo'
    };
  },
  props: {
    context: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    subjects: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapGetters(['getSubjectType'])
  },
  methods: {
    addRule(rule) {
      if (this.edit) {
        /* use index here */
        this.$emit('addRule', { rule, edit: this.edit });
      } else {
        this.$emit('addRule', { rule, edit: this.edit });
      }
    }
  }
};
</script>
