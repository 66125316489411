// helper function designed for creating deep clones of Arrays or Objects
// it is intended to be used mostly for cloning data Objects
// it does not deep clone any Functions, they remain to be shallow copied only.
// deepClone of null returns an empty Object: {}

// @filter parameter accepts an Array with key names as strings, that are to be filtered from level-0 of an Object
// by default @filterMode parameter is 'include':  all keys stated in the @filter array will be kept in the resulting cloned object
// @filterMode can be also set to 'exclude': all keys will stay, but those stated in the @filter array
// note that both @filter and @filterMode affects only Objects keys in level-0,
// i.e. all nested objects will come out in the same shape they came in
export const deepClone = (object, filter, filterMode = 'include', filterRecursive = false) => {
  // error handling
  if (!object) throw new Error('An Object or An Array has to be passed into the deepClone');
  if (typeof object !== 'object') throw new Error('Only Objects or Arrays can be deepCloned.');
  if (filter && !Array.isArray(filter))
    throw new Error('filter has to be an Array of strings representing level-0 object keys.');
  if (filter && !filter.every(key => typeof key === 'string'))
    throw new Error('filter has to be an Array of strings representing level-0 object keys.');
  if (!['include', 'exclude'].includes(filterMode))
    throw new Error(`available filter modes are only 'include' and 'exclude'`);

  // cloning
  // helper variables
  let result;
  let recFilter;
  // Array cloning
  if (Array.isArray(object)) {
    result = [];
    for (let item of object) {
      if (typeof item === 'object' && item !== null) {
        // apply filter to nested objects if filterRecursive is enabled
        if (filterRecursive) recFilter = filter;
        result.push(deepClone(item, recFilter, filterMode, filterRecursive));
      } else {
        result.push(item);
      }
    }
  }
  // Object cloning
  else {
    result = {};
    for (let key in object) {
      // filtering Object keys
      if (filter && filterMode === 'include') {
        if (!filter.includes(key)) continue;
      }
      if (filter && filterMode === 'exclude') {
        if (filter.includes(key)) continue;
      }
      // actual cloning
      if (typeof object[key] === 'object' && object[key] !== null) {
        // apply filter to nested objects if filterRecursive is enabled
        if (filterRecursive) recFilter = filter;
        result[key] = deepClone(object[key], recFilter, filterMode, filterRecursive);
      } else {
        result[key] = object[key];
      }
    }
  }
  return result;
};

// helper function to do complex reshape of an Object. It implements deepClone and enables to
// filter out certain keys as well as merge the object with an arbitrary other Object

// !! does not work well with arrays and nested arrays, should be used for plain objects only
export const reshape = (object, merge, filter, filterMode = 'include', filterRecursive = false) => {
  //error handling
  if (!object && typeof object !== 'object')
    throw new Error('An Object has to be passed into the reshape as first parameter.');
  if (!merge && typeof merge !== 'object')
    throw new Error(
      'An object to be merged into the main object is expected as the second parameter.'
    );
  if (Array.isArray(object) || object === null)
    throw new Error('Plain JS Object is allowed only to be reshaped.');

  // cloning the inputs
  const filterClone = deepClone(object, filter, filterMode, filterRecursive);
  const mergeClone = deepClone(merge);

  // checking and solving key conflicts
  for (let key in mergeClone) {
    if (typeof mergeClone[key] === 'object' && typeof filterClone[key] === 'object') {
      for (let innerKey in mergeClone[key]) {
        if (filterClone[key][innerKey] !== undefined) continue;
        const innerUpdate = {};
        innerUpdate[innerKey] = mergeClone[key][innerKey];
        mergeClone[key] = reshape(filterClone[key], innerUpdate);
      }
    } else if (filterClone[key] !== undefined) {
      mergeClone[key] = filterClone[key];
    }
  }

  // final merge
  return { ...filterClone, ...mergeClone };
};

// helper function that can tweak values of an object based on its key in a single go
export const reformat = (object, rules) => {
  //error handling
  if (!object && typeof object !== 'object')
    throw new Error('An object is expected as the first parameter.');
  if (!rules && typeof rules !== 'object')
    throw new Error('The second param is expected to be an object, containing reformatting rules.');
  if (Object.keys(rules).some(key => typeof rules[key] !== 'function'))
    throw new Error('The rules object is expected to contain only functions.');

  // cloning the input
  const reformatted = deepClone(object);

  // reformatting - recalculating
  for (let key in reformatted) {
    if (rules[key]) {
      reformatted[key] = rules[key](reformatted[key]);
    }
  }
  return reformatted;
};

export const ratio = {
  parse: ratio => {
    const [partnumerator, partdenominator] = ratio.split('/');
    return {
      partnumerator: +partnumerator,
      partdenominator: partdenominator ? +partdenominator : +partnumerator
    };
  },
  fromParts: ({ partnumerator, partdenominator }) => {
    return `${partnumerator}/${partdenominator}`;
  }
};
