export const equipmentsForm = {
  resource: 'equipments',
  schema: [
    {
      row: 1,
      type: 'text',
      name: 'name',
      label: 'Název',
      class: 'requiredlabel',
      validation: 'required'
    }
  ]
};
