<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.type} formTable` "
    :data-type="context.type">
    <Spinner v-if="loadingSpinner" />
    <div v-if="tableData.length >0 && tableHeads">
      <Table
        class="table-top"
        ref="Table"
        :data="tableData"
        :pageSize="100"
        selectionMode="none"
        selectedClass="table-info"
        :tableHeads="tableHeads"
        :control="CONTROL_CONFIG.C_FORMULATE_TABLE"
        :options="options"
        :changeColl="changeColl"
        :filter='false' />
      <div class="popupRow" v-if="!getLoading">
        <Button
          width="200"
          height="40"
          padding="10 17"
          margin="20px 9px 0"
          textAlign="center"
          v-if="!hideButton &&!changeColl"
          label="Potvrdit data"
          @action="sendParcelTable" />
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from 'vue-simple-spinner';
import Button from '@/components/generic/Button';
import Table from '@/components/generic/Table';

import { TABLE_CONFIG, CONTROL_CONFIG } from '@/config';
export default {
  name: 'FormulateTable',
  components: { Table, Spinner, Button },
  props: {
    context: {
      type: Object,
      required: true
    },
    code: {
      type: Number,
      required: true
    },
    changeColl: {
      type: Boolean,
      required: false,
      default: false
    },
    uuid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hideButton: false,
      tableData: [],
      tableHeads: TABLE_CONFIG.T_AREACSV,
      options: [],
      loadingSpinner: false
    };
  },
  created() {
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.options = [
      { name: 'getNumType', data: this.getNumType },
      { name: 'getEvidenceCodes', data: this.getEvidenceCodes }
    ];
    if (this.changeColl) {
      this.tableHeads = TABLE_CONFIG.T_SETTINGCSV;
      this.createBasicTable();
    } else {
      this.parseTable();
    }
  },
  computed: mapGetters([
    'getLoading',
    'getNumType',
    'getEvidenceCodes',
    'getEvidence',
    'getTableClipboardConfig',
    'getClipboardData',
    'getUpdatedRows'
  ]),
  methods: {
    ...mapActions([
      'tabParcelsSoft',
      'tabParcelsHard',
      'changePopupHtml',
      'clearUpdatedRows',
      'setVfkRespXLS'
    ]),
    newTable() {
      this.changePopupHtml(false);
      this.tableData = [];
    },
    createBasicTable() {
      this.tableHeads = [];
      // this creates a table without parsing the data
      for (let i = 0; i < this.getClipboardData.length - 1; i++) {
        let cell = this.getClipboardData[i];
        cell.map((c, index) => {
          if (i === 0) {
            this.tableHeads = [
              ...this.tableHeads,
              { value: index, name: 'empty' }
            ];
          }
          return c;
        });

        let row = cell.reduce(
          (ac, cell, index) =>
            (ac = { ...ac, ...{ [index]: cell.replace(/\n/g, '') } }),
          {}
        );
        this.tableData = [...this.tableData, row];
      }
    },
    async parseTable() {
      this.hideButton = false;
      for (let i = 0; i < this.getClipboardData.length - 1; i++) {
        let cell = this.getClipboardData[i];
        let row = this.tableValidation(cell, i);
        if (row === undefined) {
          return;
        }
        this.tableData = [...this.tableData, row];
      }
    },

    tableParsingEvidence(cell) {
      if (cell === undefined) return;
      const evidenceDic = Object.keys(this.getEvidence);
      let evidence = '';
      cell = cell.toUpperCase();
      for (let type of evidenceDic) {
        if (cell.match(type) !== null) {
          evidence = cell.match(type)[0];
          cell = cell.replace(type, '');
        }
      }
      if (evidence.length === 0) {
        evidence = 'KN';
      }
      return this.tableParsingPart(cell, evidence);
    },

    tableParsingPart(cell, evidence) {
      let partDic = ['D[0-9]+'];
      let part = '';
      let regEx = new RegExp(/[D]+[0-9]+/g);
      cell = cell.toUpperCase();
      for (var i = 0; i < partDic.length; i++) {
        if (cell.match(partDic[i]) != null) {
          part = cell.match([partDic[i]]).join('').replace('D', '');
          cell = cell.replace(regEx, '');
        }
      }
      return this.tableParsingCadaster(cell, evidence, part);
    },

    tableParsingCadaster(cell, evidence, part) {
      let cadaster = '';
      cell = cell.toLowerCase();
      let cadDic = ['pkú', 'pku', 'p.k.u.', 'p.k.ú.'];
      for (var i = 0; i < cadDic.length; i++) {
        if (cell.match(cadDic[i] + '+[0-9]+') != null) {
          cadaster = cell.match(cadDic[i] + '[0-9]+').join('');
          cadaster = cadaster.replace(cadDic[i], '');
          let regEx = new RegExp(`${cadDic[i]}+[0-9]+`);
          cell = cell.replace(regEx, '');
        }
      }
      return this.tableParsingType(cell, evidence, part, cadaster);
    },

    tableParsingType(cell, evidence, part, cadaster) {
      let typeDic = ['st.', '.st', 'st', '.s', 's.'];
      let type = '';
      cell = cell.toLowerCase();
      for (var i = 0; i < typeDic.length; i++) {
        if (cell.match(typeDic[i]) != null) {
          type = cell.match(typeDic[i]).join('');
          cell = cell.replace(typeDic[i], '');
        }
      }
      if (type.length) {
        type = 'ST';
      } else {
        type = 'PO';
      }
      return this.tableParsingParcel(cell, evidence, part, cadaster, type);
    },

    tableParsingParcel(cell, evidence, part, cadaster, type) {
      let parcel = '';
      parcel = cell.trim();
      let par = new RegExp(/^[+-]?\d+(\/\d+)?$/);
      if (!par.test(parcel)) {
        parcel = parcel + '❌';
      }
      return {
        parcel: parcel,
        evidence: evidence,
        part: part,
        cadaster: cadaster,
        type: type
      };
    },

    tableValidation(cell, id) {
      let parsedData = {};
      cell = cell.map((c) => (c = c.replace(/\n/g, '')));
      parsedData = this.tableParsingEvidence(
        cell[this.getTableClipboardConfig.parcel_number]
      );
      if (parsedData === undefined) {
        return;
      }
      let onlyNum = new RegExp('^[0-9]+$||^[0-9]+$m');
      let deci = new RegExp('^[0-9]*[,][0-9]+$||^[0-9]+$');
      if (!onlyNum.test(cell[this.getTableClipboardConfig.ownerfolio_number])) {
        this.hideButton = true;
        cell[this.getTableClipboardConfig.ownerfolio_number] =
          cell[this.getTableClipboardConfig.ownerfolio_number] + '❌';
      }
      let row = {
        numtype: parsedData.type,
        ownerfolio_number:
          cell[this.getTableClipboardConfig.ownerfolio_number] || '',
        parcel_number: parsedData.parcel || '',
        evidencetype: parsedData.evidence || 'KN',
        parcelpart: parsedData.part.toString() || '',
        originalcadaster: parsedData.cadaster.toString() || '',
        id: id,
        area: cell[this.getTableClipboardConfig.area] || '',
        length: cell[this.getTableClipboardConfig['length']] || ''
      };
      if (cell.length > 2) {
        if (!deci.test(cell[cell[this.getTableClipboardConfig.area]])) {
          this.hideButton = true;
          cell[this.getTableClipboardConfig.area] =
            cell[this.getTableClipboardConfig.area] + '❌';
        }
        if (!deci.test(cell[cell[this.getTableClipboardConfig['length']]])) {
          this.hideButton = true;
          cell[this.getTableClipboardConfig['length']] =
            cell[this.getTableClipboardConfig['length']] + '❌';
        }
      }
      return row;
    },
    async sendParcelTable() {
      for (let i = 0; i < this.tableData.length; i++) {
        this.getUpdatedRows.map((up) => {
          if (up.id == this.tableData[i].id)
            this.tableData[i] = { ...up, ...this.tableData[i] };
        });
        this.tableData[i].evidencetype = this.tableData[i].evidencetype;
        this.tableData[i].numtype = this.tableData[i].numtype;
        if (this.tableData[i].evidencetype == 1) {
          this.tableData[i].parcelpart = '';
          this.tableData[i].originalcadaster = '';
        }
        delete this.tableData[i].id;
      }
      let data = {
        uuid: this.uuid,
        parcels: this.tableData,
        cadaster_code: this.code
      };
      this.loadingSpinner = true;
      const response = await this.tabParcelsSoft(data);
      if (response.status === 202) {
        this.openModal({
          html: `<h3>${response.data.message}</h3>`,
          type: 'warning',
          heading: 'Uložit změny?',
          customBtns: [{ label: 'Uložit', type: 'confirm', color: 'warning' }]
        }).then(async ({ confirm }) => {
          if (confirm) {
            const resp = await this.tabParcelsHard(data);
            if (resp.status === 200) {
              this.tableSavedModal();
            }
          }
        });
      } else {
        this.tableSavedModal();
        this.setVfkRespXLS(true);
      }
      this.clearUpdatedRows();
      this.loadingSpinner = true;
    },
    tableSavedModal() {
      return this.openModal({
        html: '<h3>Vaše data byla úspěšně nahrána.</h3>',
        type: 'success',
        heading: 'Tabulka uložena',
        btns: {},
        customBtns: [{ label: 'OK', type: 'confirm', color: 'success' }]
      });
    },
    openModal(config) {
      const { openModal } = this.$root.$children[0].$refs.modal;
      return openModal(config);
    }
  }
};
</script>
<style>
.formulateTableButton {
  margin: 0 20px !important;
}
.popupRow {
  display: flex;
  justify-content: flex-end;
}
.table-top {
  margin: 20px -13px;
  background-color: white;
  padding: 5px;
  overflow: hidden;
  overflow-y: auto;
  max-height: 450px;
}
.table-top table {
  margin-bottom: 0;
}
.table-top th {
  background-color: #c2d1e4;
  color: #001641;
}

.table-top .vt-sort:after {
  background-color: rgba(0, 22, 65, 0.7);
}
.table-top td {
  background-color: white;
}
.table-top th select {
  border: none;
  height: 30px;
  border-radius: 4px;
  padding: 0 5px;
}
</style>