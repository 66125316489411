<template>
  <section class="center">
    <h1>Stránka nenalezena</h1>
    <h2>Chyba 404 </h2>
  </section>
</template>

<script>
export default {
  name: 'ErrorPage'
};
</script>
<style scoped>
.center {
  flex-direction: column;
  text-align: center;
  display: flex;
  height: 100%;
  justify-content: center;
  color: red;
}
</style>