<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: COSUP specific implementation of header, composed of Header and HeaderItem components
:dependencies: Header, HeaderItem, Icon
:version: 1
:updated: 2021-09-13
-->

<template>
  <Header ref="header">
    <div class="dialog-menu-backdrop" v-if="backdropOpened" @click.prevent="closeBackdrop($event)"></div>
    <HeaderItem :active="activeItem === 'project'" :avatar="activeProject.image_url" placeholderBg="transparent"
      :label="activeProject.name" @click.native="openItem('project')">
      <li v-for="project in projects.filter(({id}) => id !== activeProject.id)" :key="project.id"
        class="dialog-menu-item" @click.prevent="setProject(project.id)">
        <img :src="project.image_url" style="width: 20px; height: 20px; border-radius: 50%;"
          class="dialog-menu-item-icon" />
        <span class="dialog-menu-item-label">{{project.name}}</span>
      </li>
    </HeaderItem>
    <HeaderItem :active="activeItem === 'user'" :placeholder="username" :label="username"
      @click.native="openItem('user')">
      <li class="dialog-menu-item" @click="logout">
        <Icon class="dialog-menu-item-icon" type="logout" size="20" fill="black" />
        <span class="dialog-menu-item-label">Odhlásit se</span>
      </li>
    </HeaderItem>
    <HeaderItem :active="activeItem === 'language'" :icon="lang" :label="lang.toUpperCase()"
      @click.native="openItem('language')">
      <li v-for="language in languages.filter(availableLang => availableLang !== lang)" :key="language"
        class="dialog-menu-item" @click.prevent="setLang(language)">
        <Icon class="dialog-menu-item-icon" :type="language" size="20" />
        <span class="dialog-menu-item-label">{{language.toUpperCase()}}</span>
      </li>
    </HeaderItem>
  </Header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Header from '../generic/Header.vue';
import HeaderItem from '../generic/HeaderItem.vue';
import Icon from '../generic/Icon.vue';

export default {
  name: 'AppHeader',
  data() {
    return {
      backdropOpened: false,
      activeItem: null
    };
  },
  components: {
    Header,
    HeaderItem,
    Icon
  },
  computed: mapGetters({
    username: 'getUserFullName',
    projects: 'getUserProjects',
    activeProject: 'getActiveProject',
    lang: 'getLang',
    languages: 'getLanguages'
  }),
  methods: {
    ...mapActions(['logout', 'setProject', 'setLang']),
    openItem(name) {
      if (this.activeItem === name) {
        this.activeItem = null;
      } else {
        this.backdropOpened = true;
        this.activeItem = name;
      }
    },
    closeBackdrop({ clientX: x, clientY: y }) {
      this.backdropOpened = false;
      this.activeItem = null;
      setTimeout(() => {
        const pt = document.elementFromPoint(x, y);
        if (pt.click) pt.click();
      }, 0);
    }
  }
};
</script>

<style scoped>
.dialog-menu-backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.dialog-menu-item {
  display: grid;
  font-size: 14px;
  grid-template-columns: 28px auto;
  cursor: pointer;
  padding: 4px 8px 4px 6px;
  transition: 0.4s;
}

.dialog-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dialog-menu-item:last-of-type:hover {
  border-radius: 0 0 4px 4px;
}

.dialog-menu-item-label {
  text-align: left;
  padding-left: 6px;
  padding-top: 2px;
}
</style>