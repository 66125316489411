import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=48921b2a&scoped=true"
import script from "./Modal.vue?vue&type=script&lang=js"
export * from "./Modal.vue?vue&type=script&lang=js"
import style0 from "./Modal.vue?vue&type=style&index=0&id=48921b2a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48921b2a",
  null
  
)

export default component.exports