import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import SmartTable from 'vuejs-smart-table';
import UUID from 'vue-uuid';
import VTooltip from 'v-tooltip';
import VueFormulate from '@braid/vue-formulate';
import { VF_config, ALERT_TEXT } from '@/config';
import VueI18n from 'vue-i18n';
import { languages, defaultLocale } from '@/locales';

Vue.use(VueFormulate, VF_config);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['token'] = store.getters.getToken;
axios.defaults.headers.common['user_id'] = store.getters.getUserId;
axios.defaults.headers.common['project_id'] = store.getters.getActiveProject
  ? store.getters.getActiveProject.id
  : null;

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  // !! this function should be properly described and ways how to test it defined
  function(error) {
    /* intereceptor catches an error*/
    if (error.response) {
      /* error is from axios response  */
      if (error.response.status === 409) {
        /* error 409 is custom backend message*/
        if (error.response.config.responseType === 'arraybuffer') {
          /* the structure of the respnse for response type arraybuffer is different */
          /* the message needs to be decoded and parsed first */
          var decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
          var obj = JSON.parse(decodedString);
          openModalError(obj.message);
        } else {
          openModalError(error.response.data.message);
        }
      } else if (error.response.status == 415) {
        openModalError(ALERT_TEXT.ERROR_FORMAT);
      } else {
        openModalError(ALERT_TEXT.ERROR_GENERIC);
      }
      return Promise.reject(error);
    }
  }
);

const openModalError = text => {
  app.$children[0].$refs.modal.openModal({ type: 'error', heading: 'Chyba', text });
};
Vue.use(UUID);
Vue.use(SmartTable);
Vue.use(VTooltip);
Vue.use(VueI18n);

const messages = Object.assign(languages);
const i18n = new VueI18n({
  locale: defaultLocale,
  messages
});
Vue.config.productionTip = false;

const app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');

store.$app = app;
