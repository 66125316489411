<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz
:contact: jakub.mraz@hrdlicka.cz
:copyright: HSRO
:summary: Wrapper for sending file and table data to make EasementForm lighter
:dependencies: VueFormulate, Icon, EasementsDescInfoForm, Button, Spinner, ALERT_TEXT util && vuex
:version: 1
-->

<template>
  <div class="inside">
    <FormulateForm name="form" ref="form"
      :schema="schema"
      @submit="analyzeEasementFiles($event)"
      @file-removed="updateFile($event, false)"
      @file-upload-complete="updateFile($event, true)"
      @change="disabledBtn=false"
      :class="{'disabled-step':( !cadasterDigital || !correctCadaster) && fileType === 'zvb'}">
      <Button
        v-if="!loading"
        type="submit"
        height="40"
        width="calc(100% - 20px)"
        :class="{'disabled-step':disabledBtn}"
        margin="20px 10px 10px 10px "
        textAlign="center"
        :disabled="disabledBtn"
        :color="dataSend && fileUploaded ? 'success' : 'main'"
        :label="dataSend && fileUploaded ? 'Soubor nahrán': 'Nahrát soubor'" />
      <Spinner v-if="loading" />
    </FormulateForm>
    <span v-if="fileType === 'zvb'"
      class="table-icon-section"
      :class="{'showTable':  correctCadaster, 'disabled-step':  !correctCadaster}">
      <label class="formulate-label-input">Načtěte data z tabulky</label>
      <Icon type="table" height="120" width="128" @click.native="correctCadaster?showPopUpTable() : null"
        :style="{margin: '15px 0 0 0'}" :fill="tableSend? 'green' :correctCadaster ? 'var(--main-color)': 'gray'  " />
      <p class="formulate-input-help">Po kliknutí na ikonu spusťte průvodce pro načtení dat</p>
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/generic/Icon.vue';
import Button from '@/components/generic/Button.vue';
import { EasementsDescInfoForm } from '@/components/forms';

import Spinner from 'vue-simple-spinner';
import { ALERT_TEXT } from '@/config';

export default {
  name: 'FileForm',
  components: {
    Spinner,
    Icon,
    Button
  },
  props: {
    fileType: {
      type: String,
      required: true
    },
    schema: {
      type: Array,
      required: false
    },
    correctCadaster: {
      type: Boolean,
      required: false,
      default: false
    },
    cadasterDigital: {
      type: Boolean,
      required: false,
      default: false
    },
    tableSend: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    uuid: {
      type: String,
      required: true
    },
    cadaster_code: {
      type: Number
    }
  },
  data: () => {
    return {
      size: process.env.VUE_APP_MAX_FILESIZE,
      dataSend: false,
      disabledBtn: true,
      loading: false,
      fileUploaded: false,
      fileData: []
    };
  },
  methods: {
    ...mapActions([
      'postVfkEasement',
      'postVfkSpi',
      'postVfkPdf',
      'changePopupHtml',
      'deleteFile'
    ]),

    async updateFile(event, boolean) {
      if (!boolean)
        await this.deleteFile({ type: this.fileType, uuid: this.uuid });
      this.fileUploaded = boolean;
      this.fileData = event;
      this.dataSend = false;
    },

    async analyzeEasementFiles() {
      let end = 'vfk';
      this.loading = true;
      if (this.fileType === 'pdf') {
        end = this.fileType;
      }
      if (this.fileData.file)
        if (this.fileData.file.size <= this.size * 1048576) {
          let splitName = this.fileData.name.split('.');
          if (splitName[1].toLowerCase() == end) {
            let caseData = undefined;
            const formData = new FormData();
            formData.append(this.uuid, this.fileData.file);
            if (this.fileType === 'spi') {
              caseData = await this.postVfkSpi(formData);
            } else if (this.fileType === 'pdf') {
              caseData = await this.postVfkPdf(formData);
            } else {
              caseData = await this.postVfkEasement(formData);
            }
            this.loading = false;
            if (typeof caseData !== 'string') {
              this.dataSend = true;
              this.$emit('data-send', caseData);
            } else {
              this.dataSend = false;
              this.fileUploaded = false;
              this.fileData.removeFile();
            }
          } else {
            end === 'pdf'
              ? this.openModal({
                  text: ALERT_TEXT.FORMATPDF,
                  type: 'error',
                  heading: 'Špatný formát'
                })
              : this.openModal({
                  text: ALERT_TEXT.FORMAT,
                  type: 'error',
                  heading: 'Špatný formát'
                });
            this.loading = false;
          }
        } else {
          this.openModal({
            text: `Soubor má více jak ${this.size} MB!`,
            type: 'error',
            heading: 'Chyba'
          });
          this.loading = false;
        }
      else {
        this.openModal({
          text: `Chybí soubor!`,
          type: 'error',
          heading: 'Chyba'
        });
        this.loading = false;
      }
    },

    showPopUpTable() {
      this.changePopupHtml(false);
      this.openModal({
        component: EasementsDescInfoForm,
        componentProps: { uuid: this.uuid, cadaster_code: this.cadaster_code },
        btns: {},
        closeBtn: 1,
        lock: true,
        customStyles: {
          closeBtnColor: 'var(--main-color)',
          border: '1px solid var(--main-color)',
          borderRadius: '0px',
          backgroundColor: 'var(--sidebar-color)'
        }
      });
    },

    openModal(config) {
      const { openModal } = this.$root.$children[0].$refs.modal;
      return openModal(config);
    }
  }
};
</script>

<style scoped >
@import '../../styles/new-easement.css';
.table-icon-section {
  margin-left: 30px;
}
.table-icon-section label {
  margin: 15px 0px 0px 0px;
}
.showTable {
  opacity: 1;
}
.showTable svg {
  fill: var(--main-color) !important;
}
.showTable div :hover {
  cursor: pointer;
}
</style>
