import axios from 'axios';
import router from '@/router';
import { storage } from '@/utils/storage.util.js';

const setHttpHeaders = headers => {
  Object.keys(headers).forEach(header => {
    axios.defaults.headers.common[header] = headers[header];
  });
};

const persistAuthState = authState => {
  Object.keys(authState).forEach(key => {
    if (authState[key] !== undefined) {
      storage.set(key, authState[key]);
    }
  });
};

const getExpiration = (expiration = 24 * 60 * 60 * 1000) => {
  return new Date().getTime() + expiration;
};

const state = {
  user: storage.get('user'),
  loginExpiration: storage.get('loginExpiration'),
  activeProject: storage.get('activeProject')
};

const getters = {
  getUser: state => state.user,
  isLoggedIn: state => !!state.user,
  loginExpired: ({ loginExpiration }) => new Date().getTime() > +loginExpiration,
  getUserId: ({ user }) => (user ? user.id : null),
  getUserFullName: ({ user }) => (user ? `${user.name} ${user.surname}` : null),
  getToken: ({ user }) => (user ? user.token : null),
  getUserProjects: ({ user }) => (user ? user.projects : null),
  getUserProject: ({ user }) => _id => {
    const project = user.projects.find(({ id }) => id === +_id);
    return project ? project : user.projects[0];
  },
  getActiveProject: ({ activeProject }) => activeProject
};

const actions = {
  login: async ({ commit, dispatch, getters }, { login, password }) => {
    const { data: user } = await axios.post('/login', { login, password });
    commit('setUser', user);
    const project = getters.getUserProject();
    commit('setActiveProject', project);
    const loginExpiration = getExpiration();
    commit('setLoginExpiration', loginExpiration);
    persistAuthState({ user, loginExpiration, activeProject: project });
    setHttpHeaders({ user_id: user.id, token: user.token, project_id: project.id });
    dispatch('fetchResources', ['fetchCadastres', 'fetchCodeList']);
  },
  logout: ({ commit }) => {
    commit('setUser', null);
    commit('setActiveProject', null);
    persistAuthState({ user: null, activeProject: null });
    setHttpHeaders({ user_id: null, project_id: null, token: null });
    router.push('/');
  },
  setProject: ({ commit, dispatch, getters }, id) => {
    const project = getters.getUserProject(id);
    commit('setActiveProject', project);
    persistAuthState({ activeProject: project });
    setHttpHeaders({ project_id: project.id });
    dispatch('fetchResources', ['fetchCodeList']);
    router.push('/');
  }
};

const mutations = {
  setUser: (state, payload) => {
    state.user = payload;
  },
  setLoginExpiration: (state, payload) => {
    state.loginExpiration = payload;
  },
  setActiveProject: (state, payload) => {
    state.activeProject = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
