export const easementCadasterForm = {
  resource: 'easementCadaster',
  schema: [
    {
      type: 'autocomplete',
      id: 'cadaster',
      name: 'cadaster',
      label: 'Název/kód katastrálního území',
      charLimit: 2,
      class: 'requiredlabel relative',
      validation: 'required',
      '@pickedSuggest': 'pickedSuggestCadaster',
      '@inputCleared': 'inputCleared',
      'error-class': 'cadaster-input-error',
      'errors-class': 'cadaster-input-errors',
      $get: { data: 'getCadastres' }
    }
  ]
};
