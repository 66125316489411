import axios from 'axios';

const state = {
  rules: []
};

const getters = {
  getRules: ({ rules }) => rules,
  getRule: ({ rules }) => _id => rules.find(({ code }) => code === _id)
};

const actions = {
  fetchRules: async ({ commit }) => {
    try {
      const { data } = await axios.get('/rules');
      commit('setRules', data.data);
    } catch (error) {
      console.error('Failed to fetch rules');
      console.error(error);
    }
  }
};

const mutations = {
  setRules: (state, payload) => {
    state.rules = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
