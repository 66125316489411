export const easementPDFForm = {
  resource: 'easementPDF',
  schema: [
    {
      type: 'file',
      name: 'pdf',
      id: 'pdf',
      help: 'Přetažením nebo kliknutím vyberte jeden soubor ve formátu PDF',
      'help-class': 'file-form-input-help',
      label:
        'Pro získání čísla jednacího a data přijetí k.ú nahrajte soubor geometrického plánu ve formátu PDF (elektronicky podepsaný).',
      disabled: true,
      'validation-messages': {
        mime:
          'Pro získání čísla jednacího a data přijetí k.ú nahrajte soubor geometrického plánu ve formátu PDF (elektronicky podepsaný).'
      },
      validation: 'mime:application/pdf',
      '@change': 'change',
      '@file-removed': 'file-removed',
      '@file-upload-complete': 'file-upload-complete'
    }
  ]
};
