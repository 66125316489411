<!-- 
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz
:contact: jakub.mraz@hrdlicka.cz
:copyright: HSRO
:summary: Wrapper component for showcasing the main data of the page
:version: 2.0
 -->

<template>
  <section ref="main" class="main">
    <div v-if="hasChilds" class="main-ribbon">
      <slot></slot>
    </div>
    <div class="main-content-frame">
      <main class="main-content">
        <Button
          v-if="fullscreenBtn"
          @action="toggleFullScreen"
          isIcon="contract"
          :style="{position: 'absolute', top: '10px', right: '10px'}" />
        <router-view v-if="showRouter" ref="views" />
      </main>
    </div>
  </section>
</template>

<script>
import Button from './Button';

export default {
  name: 'Main',
  components: { Button },
  props: {
    showRouter: {
      type: Boolean,
      default: true
    },
    fullscreenBtn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasChilds() {
      return !!this.$slots.default;
    }
  },
  methods: {
    toggleFullScreen() {
      /* the guide for fullscreen api
      https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API */
      if (document.fullscreenElement === null) {
        document.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  }
};
</script>

<style scoped>
.main {
  height: calc(100vh - 80px - 20px);
  padding: 0;
  display: flex;
  display: -webkit-box;
  flex-direction: column;
}
.main:fullscreen {
  background-color: white;
  overflow: hidden;
  overflow-y: scroll;
}
.main-ribbon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
}
.main-content-frame {
  background-color: #eff5fb;
  padding: 28px 21px 28px 21px;
  flex: 1;
  display: flex;
  align-items: stretch;
  min-height: 0;
}
.main-content {
  position: relative;
  background-color: #fff;
  padding: 0 15px;
  width: 100%;
  overflow-y: auto;
}
</style>
