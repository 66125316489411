<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Generic Header component
:dependencies: none
:version: 1
:updated: 2021-07-15
-->

<template>
  <header class="header" :style="headerStyle">
    <div @click="goHome" class="logo">
      <img alt="Úžasné logo společnosti HRDLIČKA spol. s r.o." :src="imgLogo" :style="{ objectFit: 'contain' }"
        height="30px" width="230px" />
    </div>
    <ul class="header-items">
      <slot></slot>
    </ul>
  </header>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'Header',
  components: {
    Icon
  },
  props: {
    height: {
      type: [String, Number],
      default: 80
    },
    color: {
      type: String,
      default: 'var(--main-color)'
    },
    padding: {
      type: String,
      default: '0 2vw'
    },
    imgLogo: {
      type: [String, Object],
      default: `${process.env.BASE_URL}img/logo.png`
    },
    iconLogo: {
      type: Object,
      default: () => ({
        type: 'logoCosup',
        width: 230,
        height: 30,
        fill: '#fff'
      })
    }
  },
  computed: {
    headerStyle() {
      const height = `${this.height}px`;
      const backgroundColor = this.color;
      const padding = this.padding;
      return { backgroundColor, height, padding };
    },
    imgLogoAttributes() {
      if (typeof this.imgLogo === 'string') return { src: this.imgLogo };
      return this.imgLogo;
    }
  },
  methods: {
    // doesn't deal with callUpdateAlert() related logic
    // which is expected to be moved globally to router navigation guard
    goHome() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  cursor: pointer;
}

ul.header-items {
  background: none;
  display: flex;
  align-items: center;
  list-style: none;
}
</style>