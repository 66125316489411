import { render, staticRenderFns } from "./TablePreview.vue?vue&type=template&id=313b98da&scoped=true"
import script from "./TablePreview.vue?vue&type=script&lang=js"
export * from "./TablePreview.vue?vue&type=script&lang=js"
import style0 from "./TablePreview.vue?vue&type=style&index=0&id=313b98da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "313b98da",
  null
  
)

export default component.exports