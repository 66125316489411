<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Form for manipulating 'equipments' resource. Designated to be rendered as a ModalForm
:dependencies: ModalForm, VueFormulate, modalFormClasses utility
:version: 2
:updated: 2021-07-29
-->

<template>
  <ModalForm :mode="mode" resource="equipments" :resourceId="resourceId" :schema="schema" />
</template>

<script>
import ModalForm from '../generic/ModalForm.vue';
import { equipmentsForm } from '@/config/formSchemas';
import { VFSchema } from '@/utils';

export default {
  name: 'EquipmentsForm',
  components: { ModalForm },
  created() {
    this.schema = VFSchema(equipmentsForm.schema)
      .bindClasses()
      .toFormRows()
      .get();
  },
  props: {
    mode: String,
    resourceId: Number
  }
};
</script>

<style>
@import '../../styles/modal-form.css';
</style>