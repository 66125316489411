const queue = [];
let fetchingInProgress = false;
let fetchingDone = null;

const doFetching = ({ commit, dispatch }) => {
  return new Promise(async resolve => {
    fetchingInProgress = true;
    while (queue.length) {
      const fetchAction = queue.pop();
      try {
        await dispatch(fetchAction);
      } catch (error) {
        console.error(error);
        console.error(`${fetchAction} failed.`);
      }
    }
    fetchingInProgress = false;
    commit('setLoading', false);
    resolve(true);
  });
};

const state = {
  loading: false
};

const getters = {
  getLoading: state => state.loading
};

const actions = {
  fetchResources: async ({ commit, dispatch }, resources) => {
    if (!Array.isArray(resources)) {
      throw new Error('fetchResources action accepts only Arrays of Strings');
    }
    if (!queue.length) commit('setLoading', true);
    resources.forEach(resource => queue.unshift(resource));
    if (!fetchingInProgress) {
      fetchingDone = doFetching({ commit, dispatch });
    }
    return fetchingDone;
  }
};

const mutations = {
  setLoading: (state, boolean) => {
    if (typeof boolean !== 'boolean') {
      throw new Error('setLoading mutation accept only boolean values');
    }
    state.loading = boolean;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
