<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz
:contact: jakub.mraz@hrdlicka.cz
:copyright: HSRO
:summary: Specific component for global buttons for router level actions
:dependencies: Button, OwnersForm, OwnerfoliosForm, ParcelsForm, EquipmentsForm, CategoriesForm, SubjectsForm, TemplateForm, vuex
:version: 3
::updated: 2021-09-21

-->

<template>
  <div v-if="!getLoading" class="main-buttons">
    <Button
      height="40"
      frontIcon="add"
      :label="btnRouter.add"
      v-if="btnRouter.add"
      @action="openAddPath(btnRouter.name)" />
    <Button
      height="40"
      frontIcon="edit"
      :label="btnRouter.update"
      :style="{marginLeft: '24px'}"
      v-if="btnRouter.update && (getSelectedRows.length === 1 || $router.currentRoute.name === 'siteDetail')"
      @action="openEditPath(btnRouter.name)" />
    <Button
      height="40"
      frontIcon="save"
      :label="btnRouter.submit"
      v-if="btnRouter.submit"
      type="submit"
      @action="onSubmit()" />
  </div>
</template>

<script>
import Button from './Button.vue';
import { mapGetters, mapActions } from 'vuex';

import OwnersForm from '../forms/OwnersForm.vue';
import OwnerfoliosForm from '../forms/OwnerfoliosForm.vue';
import ParcelsForm from '../forms/ParcelsForm.vue';
import EquipmentsForm from '../forms/EquipmentsForm.vue';
import CategoriesForm from '../forms/CategoriesForm.vue';
import SubjectsForm from '../forms/SubjectsForm.vue';

export default {
  name: 'MainButtons',
  components: {
    Button
  },
  data() {
    return {
      btnRouter: {}
    };
  },
  watch: {
    $route() {
      this.getButtonRouter();
    }
  },
  created() {
    this.getButtonRouter();
  },
  computed: mapGetters(['getSelectedRows', 'getUpdatedRows', 'getLoading']),
  methods: {
    ...mapActions(['updatingAlert', 'clearRows']),
    getButtonRouter() {
      const {
        name,
        path,
        meta: { add, update, modal, submit, customResourceId }
      } = this.$router.currentRoute;
      this.btnRouter = {
        add,
        update,
        submit,
        modal,
        name,
        path,
        customResourceId
      };
    },
    onSubmit() {
      document.getElementById('main_form').requestSubmit();
      this.clearRows();
    },
    openAddPath(resource) {
      this.getUpdatedRows.length > 0
        ? this.updatingAlert()
        : this.btnRouter.modal
        ? this.openModalForm({ resource, mode: 'new' })
        : this.$router.push(`${this.$router.currentRoute.path}/new`);
    },
    openModalForm({ resource, mode, resourceId }) {
      const FORMS = {
        owners: OwnersForm,
        ownerfolios: OwnerfoliosForm,
        parcels: ParcelsForm,
        equipments: EquipmentsForm,
        categories: CategoriesForm,
        subjects: SubjectsForm
      };
      const component = FORMS[resource];
      if (!component)
        throw new Error(
          `'${resource}' is an unknown resource name in openAddPath/openEditPath function.`
        );
      return this.$parent.$parent.$refs.modal.openModal({
        type: 'info',
        heading: mode === 'new' ? this.btnRouter.add : this.btnRouter.update,
        component,
        componentProps: { mode, resourceId },
        btns: {},
        lock: true
      });
    },
    openEditPath(resource) {
      const { modal, path, name, customResourceId } = this.btnRouter;
      const [selectedId] = this.getSelectedRows;
      const resourceId = customResourceId
        ? customResourceId(selectedId)
        : selectedId;

      if (modal) {
        return this.openModalForm({ resource, resourceId, mode: 'edit' });
      }
      if (name === 'siteDetail') {
        this.$router.push(`${path}/edit`);
      } else {
        this.$router.push(`${path}/${resourceId}/edit`);
      }
    }
  }
};
</script>
