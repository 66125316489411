  <!--
  Vue 2.5 Component
  
  :organization: HRDLIČKA SPOL. S.R.O
  :author: Jakub Mráz, Michael Košatka
  :contact: michael.kosatka@hrdlicka.cz
  :copyright: HSRO
  :summary: 'Table-class' component for displaying 'subjects' resource data in a table
  :dependencies: Spinner, Table, vuex
  :version: 1
  :updated: 2021-07-26
  -->
  
  <template>
  <div>
    <Spinner v-if="getLoading" />
    <section v-if="!getLoading && getSubjectsForTable.length">
      <Table
        ref="Table"
        :data="getSubjectsForTable"
        :pageSize="200"
        selectionMode='multiple'
        selectedClass='table-info'
        :tableHeads="TABLE_CONFIG.T_SUBJECT"
        :control="CONTROL_CONFIG.C_SUBJECTS"
        @deleteRow="removeSubject($event)" />
    </section>
    <p v-if="!getLoading && !getSubjectsForTable.length" class="no_records">
      Žádné položky k zobrazení
    </p>
  </div>
</template>

<script>
import Table from '@/components/generic/Table.vue';
import Spinner from '@/components/generic/Spinner.vue';
import { TABLE_CONFIG, CONTROL_CONFIG } from '@/config';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SubjectsTable',
  components: { Spinner, Table },
  async created() {
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.TABLE_CONFIG = TABLE_CONFIG;
    await this.fetchResources(['fetchSubjects']);
  },
  computed: mapGetters(['getSubjectsForTable', 'getLoading']),
  methods: mapActions(['fetchResources', 'removeSubject'])
};
</script>
