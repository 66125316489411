import axios from 'axios';
import router from '@/router';
import { deepClone, ratio } from '@/utils';

const state = {
  owners: []
};

const getters = {
  // basic
  getOwners: state => state.owners,
  getOwner: state => _id => state.owners.find(({ id }) => id === +_id),
  // specific
  getOwnersForTable: ({ owners }, { getOwnerPersonsForTable }) => {
    return owners.reduce((parsedOwners, _owner) => {
      const parsedOwnerfolios = _owner.ownerfolios.map(_ownerfolio => {
        const ownerfolio = deepClone(_ownerfolio, ['cadaster', 'ownerfolio_number']);
        ownerfolio.ratio = ratio.fromParts(_ownerfolio);
        ownerfolio.owner_ownerfolio_id = _ownerfolio.id;
        const icoAndAddress = getOwnerPersonsForTable(_owner.id);
        const owner = deepClone(_owner, [
          'id',
          'name',
          'origin_code',
          'ownertype_code',
          'owner_valid_date',
          'alternate_subject',
          'vatpayment_code'
        ]);
        return { ...ownerfolio, ...icoAndAddress, ...owner };
      });
      parsedOwners = [...parsedOwners, ...parsedOwnerfolios];
      return parsedOwners;
    }, []);
  },
  getOwnerPersonsForTable: (state, { getOwner }) => _id => {
    const { persons } = getOwner(_id);
    const ico = persons.map(({ ico, pin }) => (ico ? ico : pin)).join(' | ');
    const address = persons.map(({ address }) => address).join(' | ');
    return { ico, address };
  },
  getOwnOwnerfolios: (state, { getOwner }) => _id => {
    const owner = getOwner(_id);
    return deepClone(
      owner,
      ['id', 'ownerfolios', 'ownerfolio_id', 'partnumerator', 'partdenominator'],
      'include',
      true
    );
  },
  getOwnerByOoId: ({ owners }) => _id => {
    return owners.find(owner => {
      return owner.ownerfolios.map(({ id }) => id).includes(+_id);
    });
  }
};

const actions = {
  fetchOwners: async ({ commit }) => {
    try {
      const { data } = await axios.get(`/sites/${router.currentRoute.params.site_id}/owners`);
      commit('setOwners', data.data);
    } catch (error) {
      console.error('Failed to fetch owners...');
      console.error(error);
    }
  },
  addOwner: async ({ commit }, newOwner) => {
    const { data } = await axios.post(
      `/sites/${router.currentRoute.params.site_id}/owners`,
      newOwner
    );
    commit('addOwner', data.data);
  },
  updateOwner: async ({ commit }, owner) => {
    const { id } = owner;
    const { site_id } = router.currentRoute.params;
    const { data } = await axios.patch(`/sites/${site_id}/owners/${id}`, owner);
    const [updatedOwner] = data.data;
    commit('updateOwner', updatedOwner);
  },
  removeOwner: async ({ commit }, ids) => {
    const { site_id } = router.currentRoute.params;
    await axios.delete(`/sites/${site_id}/owners?ids=${ids}`);
    commit('removeOwner', ids);
  }
};

const mutations = {
  setOwners: (state, payload) => (state.owners = payload),
  addOwner: (state, payload) => {
    const _payload = Array.isArray(payload) ? payload : [payload];
    state.owners = [..._payload, ...state.owners];
  },
  updateOwner: (state, payload) => {
    const idx = state.owners.findIndex(({ id }) => id === payload.id);
    if (idx !== -1) {
      payload.checked = false;
      const updated = { ...state.owners[idx], ...payload };
      state.owners.splice(idx, 1, updated);
      state.owners = [...state.owners];
    }
  },
  removeOwner: (state, _id) => {
    let filtered;
    if (Array.isArray(_id)) {
      filtered = state.owners.filter(({ id }) => !_id.includes(id));
    } else {
      filtered = state.owners.filter(({ id }) => +_id !== id);
    }
    state.owners = filtered;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
