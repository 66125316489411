<template>
  <div>
    <Spinner v-if="getLoading" />
    <section v-if="!getLoading && getParcels.length">
      <Table
        ref="Table"
        :data="getParcels"
        :pageSize="100"
        selectedClass='table-info'
        :tableHeads="TABLE_CONFIG.T_PARCELS"
        :control="CONTROL_CONFIG.C_PARCELS"
        :options="options"
        @deleteRow="removeParcel($event)"
        @full_parcelnumber="openParcelDetailTab($event)"
        @editConfirmed="updateTable($event)"
        @editCanceled="clearUpdate()" />
    </section>
    <p v-if="!getLoading && !getParcels.length" class="no_records">
      Žádné položky k zobrazení
    </p>
  </div>
</template>

<script>
import Spinner from '@/components/generic/Spinner.vue';
import Table from '@/components/generic/Table.vue';
import { TABLE_CONFIG, CONTROL_CONFIG } from '@/config';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ParcelsTable',
  components: {
    Spinner,
    Table
  },
  data() {
    return {
      options: []
    };
  },
  async created() {
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.TABLE_CONFIG = TABLE_CONFIG;
    await this.fetchResources([
      'fetchParcels',
      'fetchOwnerfolios',
      'fetchEasements'
    ]);
    this.options = [
      {
        name: 'getOwnerfoliosByCadaster',
        data: this.getOwnerfoliosByCadaster
      }
    ];
  },
  computed: mapGetters([
    'getParcels',
    'getLoading',
    'getOwnerfoliosByCadaster'
  ]),
  methods: {
    ...mapActions([
      'fetchResources',
      'removeParcel',
      'updateParcel',
      'clearUpdatedRows'
    ]),
    clearUpdate() {
      this.clearUpdatedRows();
      this.fetchResources(['fetchParcels']);
    },
    updateTable(updateRows) {
      let sendParcels = {};
      updateRows.map((row) => {
        sendParcels = {
          id: row.id,
          ownerfolio_id: Number(row.ownerfolio_id) || null,
          area: Number(row.area),
          length: Number(row.length)
        };
        this.updateParcel(sendParcels);
      });
      this.clearUpdatedRows();
    },

    openParcelDetailTab(row) {
      window.open(
        `https://nahlizenidokn.cuzk.cz/ZobrazObjekt.aspx?typ=parcela&id=${row.parcelid}`,
        '_blank'
      );
    }
  }
};
</script>


<style scoped >
.font {
  font-size: 80%;
}
.notLink {
  cursor: default;
}
</style>
