<!-- 
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz
:contact: jakub.mraz@hrdlicka.cz
:copyright: HSRO
:summary: Simple parent component for easier control of v-th component rendering
:version: 1.0
:dependencies: "SmartTable (v-th)"
 -->

<template>
  <component :is="getComponent()" :sortKey="sortKey.toString()">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'CustomTh',
  props: {
    sortKey: {
      required: true,
      type: [String, Number]
    },
    stopSort: {
      required: true,
      type: Boolean
    }
  },
  data: () => {
    return {
      headkey: 0
    };
  },

  methods: {
    getComponent() {
      if (this.stopSort) {
        return 'th';
      } else {
        return 'v-th';
      }
    }
  }
};
</script>

