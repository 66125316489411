<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Wrapper component for Forms rednered in modal windows (Modal component)
:dependencies: Button, {ResourceManager, deepClone, reformat } utils
:version: 3
:updated: 2021-07-29
-->

<template>
  <FormulateForm name="modal-form" class="modal-form" ref="form" :schema="schema" :values="values">
    <div class="modal-form-fields">
      <slot />
    </div>
    <div class="modal-form-buttons">
      <ModalFormButton color="cancel" label="Zrušit" @action="closeModal" invert />
      <ModalFormButton color="info" label="Uložit" @action="triggerSubmit" />
    </div>
  </FormulateForm>
</template>

<script>
import { ResourceManager, deepClone, reformat } from '@/utils';
import { customButton } from './Button.vue';

const ModalFormButton = customButton(
  {
    fontSize: 14,
    height: 32,
    width: 90,
    textAlign: 'center'
  },
  'ModalFormButton'
);

export default {
  name: 'ModalForm',
  components: {
    ModalFormButton
  },
  data() {
    return {
      values: {}
    };
  },
  props: {
    mode: {
      type: String,
      default: 'new'
    },
    resource: {
      type: String,
      required: true
    },
    resourceId: {
      type: [String, Number]
    },
    schema: {
      type: Array,
      required: true
    },
    defaultFormData: {
      type: Object,
      default: () => {}
    },
    mask: {
      type: Object
    },
    iface: {
      type: Function
    }
  },
  created() {
    const resource = ResourceManager(this.resource);
    const { getOne } = resource.getters();
    const { add, update } = resource.actions();
    this.actions = { add, update };
    // preload data
    if (this.mode === 'edit') {
      const getResource = this.$store.getters[getOne];
      const initialData = deepClone(
        getResource(this.resourceId),
        ['valid', 'checked'],
        'exclude',
        true
      );
      this.values = initialData;
    }
  },
  methods: {
    triggerSubmit() {
      const { form } = this.$refs;
      if (!form.isValid) {
        form.showErrors();
        return false;
      }
      this.handleSubmit();
      this.resource === 'subjects'
        ? this.closeModalWithMessage()
        : this.closeModal();
    },
    handleSubmit() {
      let { proxy: formData } = this.$refs.form;
      if (this.mask) formData = reformat(formData, this.mask);
      if (this.iface) formData = this.iface(formData);
      formData = { ...formData, ...this.defaultFormData };

      switch (this.mode) {
        case 'new': {
          this.$store.dispatch(this.actions.add, formData);
          break;
        }
        case 'edit': {
          const updatedResource = { id: this.resourceId, ...formData };
          this.$store.dispatch('clearRows');
          this.$store.dispatch(this.actions.update, updatedResource);
          break;
        }
        default: {
          throw new Error(
            `Unsupported mode (${this.mode})in ModalForm. Only supprted modes are 'new' and 'edit'.`
          );
        }
      }
    },
    closeModalWithMessage() {
      this.closeModal();
      this.$root.$children[0].$refs.modal.openModal({
        type: 'warning',
        heading: 'Pozor!',
        lock: false,
        text: 'Provedené změny se projeví pouze v generovaných dokumentech smluv a jejich přílohách. Pokud chcete změny provést i v údajích pro návrh na vklad do KN, kontaktujte prosím technickou podporu.',
        btns: { confirm: true }
      });
    },
    closeModal(config) {
      const { closeModal } = this.$root.$children[0].$refs.modal;
      return closeModal(config);
    }
  }
};
</script>

<style scoped>
@import '../../styles/modal-form.css';
</style>