<template>
  <div>
    <Spinner v-if="loading" />
    <section v-if="!loading && contracts.length">
      <Table
        ref="Table"
        selectionMode="single"
        :data="tableData"
        :pageSize="100"
        selectedClass='table-info'
        :tableHeads="TABLE_CONFIG.T_CONTRACTS"
        :control="CONTROL_CONFIG.C_CONTRACTS"
        :options="options"
        @editConfirmed="updateItems($event)"
        @editCanceled="clearUpdate()"
        @contract="checkContractDownload($event)"
        @deposit="sendDeposit($event)"
        @openDeposit="openDeposit($event)" />
    </section>
    <p v-if="!loading && !contracts.length" class="no_records">
      Žádné položky k zobrazení
    </p>
  </div>
</template>

<script>
import Spinner from '@/components/generic/Spinner.vue';
import Table from '@/components/generic/Table.vue';
import { ALERT_TEXT, TABLE_CONFIG, CONTROL_CONFIG } from '@/config';

import { mapActions, mapGetters } from 'vuex';
import { deepClone } from '@/utils';

export default {
  name: 'ContractsTable',
  components: {
    Spinner,
    Table
  },
  data() {
    return {
      options: [],
      tableData: []
    };
  },
  async created() {
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.TABLE_CONFIG = TABLE_CONFIG;
    await this.fetchResources(['fetchContracts', 'fetchEquipments']);
    this.options = [
      { name: 'getContractState', data: this.contractState },
      { name: 'equipments', data: this.equipments },
      { name: 'calcMethods', data: this.calcMethods }
    ];
    this.clearUpdatedRows();
    this.tableData = this.contracts;
  },
  computed: mapGetters({
    contracts: 'getContractsForTable',
    loading: 'getLoading',
    equipments: 'getEquipmentsOptions',
    contractState: 'getContractState',
    calcMethods: 'getCalclMethod',
    getContract: 'getContract'
  }),
  methods: {
    ...mapActions([
      'fetchResources',
      'clearUpdatedRows',
      'updateContracts',
      'downloadDocument',
      'createDeposit'
    ]),
    async clearUpdate() {
      this.clearUpdatedRows();
      await this.fetchResources(['fetchContracts']);
      this.tableData = this.contracts;
    },
    updateItems(rows) {
      const updateContracts = rows.map((row) => {
        const contract = deepClone(
          row,
          ['opinion_by', 'opinion_number'],
          'exclude'
        );
        const { opinion_by, opinion_number } = row;
        contract.contractvalues = { opinion_by, opinion_number };
        contract.price = Number(contract.price);
        return contract;
      });
      this.updateContracts({ data: updateContracts });
    },
    checkContractDownload(ids) {
      if (this.alertEmptyFieldsDocument(ids[0])) {
        this.downloadDocument(ids[0]);
      }
    },
    alertEmptyFieldsDocument(id) {
      const row = this.getContract(id);
      if (row.number === null || row.name === null) {
        this.openModal({
          text: ALERT_TEXT.CONTRACTS,
          type: 'warning',
          heading: 'Upozornění'
        });
        return;
      } else {
        return true;
      }
    },
    async sendDeposit(ids) {
      if (this.alertEmptyFieldsDocument(ids[0])) {
        const response = await this.createDeposit(ids[0]);
        if (response.status === 200) {
          const modalResponse = await this.openModal({
            html: `<p style="padding:10px">Vámi vytvořený návrh na vklad byl úspěšně nahrán do ČÚZK.<br> Můžete ho otevřít.</p>`,
            type: 'success',
            heading: 'Návrh na vklad proběhl úspěšně',
            customBtns: [
              {
                label: 'Otevřít',
                color: 'success',
                type: 'openDeposit'
              }
            ]
          });
          if (modalResponse.openDeposit) {
            this.openDeposit([response.data.id]);
          }
          await this.fetchResources(['fetchContracts']);
          this.tableData = this.contracts;
        }
      }
    },
    openDeposit(ids) {
      const row = this.getContract(ids[0]);
      const { lastentryid } = row;
      if (lastentryid) {
        window.open(
          `https://nv.cuzk.cz/Web/secure/OpenNavrhNaVklad.aspx?DetailId=${lastentryid}&amp;metodaPrihlaseni=DP`,
          '_blank'
        );
      }
    },
    openModal(config) {
      const { openModal } = this.$root.$children[0].$refs.modal;
      return openModal(config);
    }
  }
};
</script>


