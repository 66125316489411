import axios from 'axios';
import router from '@/router';
import store from '@/store';

const actions = {
  clearVfkResponses({ commit }) {
    commit('setVfkResponseZVB', false);
    commit('setVfkResponseSPI', false);
    commit('setVfkResponsePDF', false);
    commit('setVfkResponseXLS', false);
  },
  async analyzeEasement({ commit }, ids) {
    commit('setLoading', true);
    commit('clearRows');
    try {
      const { headers, data } = await axios.post(
        `/easements_on_parcels`,
        {
          easements_ids: ids
        },
        {
          responseType: 'arraybuffer'
        }
      );
      commit('setLoading', false);
      const splitName = headers['content-disposition'].split(';');
      const name = splitName[1].split('=');
      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name[1];
      link.click();
      return true;
    } catch (error) {
      console.error('analyzeEasement() failed.');
      console.error(error);
      commit('setLoading', false);
      return false;
    }
  },
  downloadDocument: async ({ commit }, id) => {
    commit('setLoadingContract', true);
    const { site_id } = router.currentRoute.params;
    try {
      const { headers, data } = await axios.get(`/sites/${site_id}/createdocument/${id}`, {
        responseType: 'arraybuffer'
      });
      const splitName = headers['content-disposition'].split(';');
      const name = splitName[1].split('=');

      const blob = new Blob([data]);

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name[1];
      link.click();
    } catch (error) {
      console.error('downloadDocument() failed.');
      console.error(error);
    }
    commit('setLoadingContract', false);
  },
  deleteFile: ({ commit }, { type, uuid }) => {
    commit('setWaitingForDelete', true);
    try {
      axios.delete(`${type === 'pdf' ? '/pdf_ssketch' : `/vfk_${type}`}?uuid=${uuid}`);
    } catch (error) {
      console.error('deleteFile() failed.');
      console.error(error);
    }
    commit('setWaitingForDelete', false);
  },

  async postVfkSpi({ commit }, sendSpi) {
    return await axios
      .post(`/vfk_spi`, sendSpi, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        commit('setVfkResponseSPI', true);
        return true;
      })
      .catch(() => {
        return 'error';
      });
  },
  async postVfkEasement({ commit }, sendVfk) {
    return await axios
      .post(`/vfk_zvb`, sendVfk, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        commit('setVfkResponseZVB', true);
        commit('setLoading', false);
        return true;
      })
      .catch(() => {
        return 'error';
      });
  },
  async postVfkPdf({ commit }, sendPdf) {
    return await axios
      .post(`/pdf_ssketch`, sendPdf, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        commit('setVfkResponsePDF', true);
        return data;
      })
      .catch(() => {
        return 'error';
      });
  },
  async tabParcelsSoft(_, tableParcels) {
    try {
      const response = await axios.post(`/tab_parcels?direct_save=0`, tableParcels);
      return response;
    } catch {
      error => {
        return error;
      };
    }
  },
  async tabParcelsHard({ commit }, tableParcels) {
    try {
      const response = await axios.post(`/tab_parcels?direct_save=1`, {
        uuid: tableParcels.uuid
      });
      commit('setVfkResponseXLS', true);
      return response;
    } catch {
      error => {
        return error;
      };
    }
  },
  setVfkRespXLS({ commit }, bool) {
    commit('setVfkResponseXLS', bool);
  },
  createDeposit: async ({ commit }, id) => {
    commit('setLoadingContract', true);
    const { site_id } = router.currentRoute.params;
    const row = store.getters.getContract(id);
    try {
      const response = await axios.get(`/sites/${site_id}/deposit_proposal/${id}`);
      const data = { ...row, ...response.data };
      commit('setLoadingContract', false);
      commit('updateContracts', [data]);
      return { data: data, status: response.status };
    } catch (error) {
      commit('setLoadingContract', false);
      return { data: undefined, status: error.response.status };
    }
  },
  async downloadResources({ commit }) {
    const { site_id } = router.currentRoute.params;
    const { easement_id } = router.currentRoute.params;
    commit('setLoadResources)', true);
    try {
      const { headers, data } = await axios.get(
        `/sites/${site_id}/easements/${easement_id}/download_resources`,
        {
          responseType: 'arraybuffer'
        }
      );
      const splitName = headers['content-disposition'].split(';');
      const name = splitName[1].split('=');
      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name[1];
      link.click();
      return data;
    } catch (error) {
      console.error('downloadResources() failed.');
      console.error(error);
    }
    commit('setLoadResources', false);
  }
};

export default {
  actions
};
