<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz, Michael Košatka
:contact: jakub.mraz@hrdlicka.cz, michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: 'Table-class' component for displaying 'owners' resource data in a table
:dependencies: Table, vuex
:version: 3
:updated: 2021-09-15
-->

<template>
  <div>
    <Spinner v-if="getLoading" />
    <section v-if="!getLoading && getOwnersForTable.length">
      <Table
        ref="Table"
        keyName="owner_ownerfolio_id"
        :data="getOwnersForTable"
        :pageSize="100"
        selectedClass="table-info"
        :tableHeads="TABLE_CONFIG.T_OWNERS"
        :control="CONTROL_CONFIG.C_OWNERS"
        :options="options"
        @editConfirmed="updateItems($event)"
        @editCanceled="clearUpdate()"
        @removeItem="removeOwnersFromTable($event)"
        @ico="openNewTab($event)" />
    </section>
    <p v-if=" !getLoading && !getOwnersForTable.length" class="no_records">
      Žádné položky k zobrazení
    </p>
  </div>
</template>

<script>
import Spinner from '@/components/generic/Spinner.vue';
import Table from '@/components/generic/Table.vue';
import { TABLE_CONFIG, CONTROL_CONFIG } from '@/config';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'OwnersTable',
  components: { Spinner, Table },
  data() {
    return {
      options: []
    };
  },
  async created() {
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.TABLE_CONFIG = TABLE_CONFIG;
    await this.fetchResources(['fetchOwners', 'fetchOwnerfolios']);
    this.options = [{ name: 'getVatpayment', data: this.getVatpayment }];
  },
  computed: mapGetters([
    'getOwnersForTable',
    'getOwnerByOoId',
    'getOwnOwnerfolios',
    'getLoading',
    'getVatpayment',
    'getVatpaymentForTable'
  ]),
  methods: {
    ...mapActions([
      'fetchResources',
      'removeOwner',
      'updateOwner',
      'clearUpdatedRows'
    ]),
    removeOwnersFromTable(ids) {
      const owners = ids.reduce((_owners, oo_id) => {
        const { id } = this.getOwnerByOoId(oo_id);
        if (!_owners[id]) _owners[id] = [];
        _owners[id].push(oo_id);
        return _owners;
      }, {});

      for (let owner in owners) {
        const currentOwner = this.getOwnOwnerfolios(owner);
        if (owners[owner].length === currentOwner.ownerfolios.length) {
          this.removeOwner(owner);
        } else {
          const ownerfolios = currentOwner.ownerfolios.filter(({ id }) => {
            return !owners[owner].includes(id);
          });
          this.updateOwner({ id: +owner, ownerfolios });
        }
      }
    },
    async clearUpdate() {
      this.clearUpdatedRows();
      await this.fetchResources(['fetchOwners']);
    },
    updateItems(rows) {
      let sendOwner = {};
      rows.map(
        (own) => (
          (sendOwner = { vatpayment_code: own.vatpayment_code, id: own.id }),
          this.updateOwner(sendOwner)
        )
      );
    },
    openNewTab({ ico }) {
      if (ico.includes('|') || ico.length > 8) return;
      window.open(
        `https://ares.gov.cz/ekonomicke-subjekty?ico=${ico}`,
        '_blank'
      );
    }
  }
};
</script>
