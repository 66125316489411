<template>
  <div>
    <div class="modal-form-disabled" v-if="!easements.length">
      <div>Nelze přidávat parcely bez dostupných VB.</div>
      <div class="modal-form-disabled-link" @click="goToEasements">Přejít na <strong>Věcná břemena (GP)</strong></div>
      <div class="modal-form-disabled-buttons">
        <ModalFormButton color="cancel" label="Zrušit" @action="closeModal" invert />
      </div>
    </div>
    <div v-else>
      <Spinner v-if="loadingForm" />
      <FormulateForm
        v-if="!loadingForm"
        ref="form"
        class="modal-form"
        :values="initialData"
        @submit="submitParcel"
        @pickCadaster="pickCadaster($event)"
        @pickParcel="pickParcel($event)">
        <div class="modal-form-fields">
          <!-- row1 -->
          <div class="modal-form-two-columns">
            <div class="modal-form-row relative margin-bottom">
              <FormulateInput
                type="autocomplete"
                name="cadaster"
                customClass="relative"
                label="Název/kód katastrálního území"
                class="requiredlabel"
                :charLimit="2"
                :data="cadastres"
                @pickedSuggest="pickCadaster($event)"
                v-bind="inputClasses()" />
              <div class="helper-text-wrapper">
                <span class="helper-text">Katastry s digitalizací jsou označeny</span>
                <Icon type="screen" class="helper-text-icon" size="13" />
                <span class="helper-text">a bez digitalizace</span>
                <Icon type="map" class="helper-text-icon" size="13" />
              </div>
            </div>
          </div>
          <!-- row2 -->
          <div class="modal-form-two-columns">
            <div class="modal-form-row">
              <FormulateInput
                type="select"
                name="evidencetype_code"
                class="requiredlabel"
                validation="required"
                label="Evidence"
                :options="evidenceTypes"
                v-bind="inputClasses()" />
            </div>
            <div class="modal-form-row">
              <FormulateInput
                type="select"
                name="numtype_code"
                label="Typ parcely"
                class="requiredlabel"
                validation="required"
                :options="numTypes"
                v-bind="inputClasses()" />
            </div>
          </div>
          <!-- row3 -->
          <div class="modal-form-two-columns">
            <div class="modal-form-row">
              <FormulateInput
                type="autocomplete"
                searchType="numbers"
                :charLimit="2"
                name="parcelnumber"
                label="Parcela"
                class="requiredlabel"
                validation="required"
                :disabled="parcelsInputDisabled"
                :data="parcelsData"
                v-bind="inputClasses()" />
            </div>
            <div class="modal-form-row">
              <FormulateInput
                type="number"
                name="parcelpart"
                label="Díl"
                v-bind="inputClasses()" />
              <FormulateInput
                type="number"
                name="originalcadaster_code"
                label="Kód původního k.ú."
                v-bind="inputClasses()" />
            </div>
          </div>
          <!-- row4 -->
          <div class="modal-form-two-columns">
            <div class="modal-form-row">
              <FormulateInput
                type="select"
                name="easement_id"
                label="VB GP"
                class="requiredlabel"
                validation="required"
                :options="surveySketches"
                v-bind="inputClasses()" />
            </div>
          </div>
          <!-- row5 -->
          <div class="modal-form-two-columns">
            <div class="modal-form-row">
              <FormulateInput
                type="select"
                name="ownerfolio_id"
                label="LV"
                :disabled="cadasterOwnerfoliosDisabled"
                :options="cadasterOwnerfoliosOptions"
                v-bind="inputClasses()" />
            </div>
            <div class="modal-form-row">
              <FormulateInput
                type="text"
                name="length"
                label="Délka VB"
                :validation="[['matches', /^\d+([.|,]\d+)?$|^\s*$/]]"
                v-bind="inputClasses()" />
              <FormulateInput
                type="text"
                name="area"
                :validation="[['matches', /^\d+([.|,]\d+)?$|^\s*$/]]"
                label="Plocha VB"
                v-bind="inputClasses()" />
            </div>
          </div>
        </div>
        <div class=" modal-form-buttons">
          <ModalFormButton color="cancel" label="Zrušit" @action="closeModal" invert />
          <ModalFormButton color="info" label="Uložit" btnType="submit" />
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { modalFormClasses, deepClone, reformat } from '@/utils';
import Button from '@/components/generic/Button.vue';
import Spinner from '@/components/generic/Spinner.vue';
import Icon from '@/components/generic/Icon.vue';

export default {
  name: 'ParcelsForm',
  components: {
    ModalFormButton: Button,
    Spinner,
    Icon
  },
  props: {
    mode: {
      type: String,
      default: 'new'
    },
    resourceId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      parcelsInputDisabled: true,
      parcelsData: [],
      cadasterOwnerfoliosDisabled: true,
      cadasterOwnerfoliosOptions: [],
      loadingForm: false
    };
  },
  async created() {
    this.initialData = undefined;
    if (this.mode === 'edit') {
      this.loadingForm = true;
      const initialData = await this.fetchParcel(this.resourceId);
      await this.pickCadaster({ id: initialData.cadaster_code });
      this.initialData = deepClone(initialData);
      this.loadingForm = false;
    }
  },
  computed: mapGetters({
    easements: 'getEasements',
    cadastres: 'getCadastres',
    numTypes: 'getNumType',
    evidenceTypes: 'getEvidence',
    parcelsRUIAN: 'getParcelsRUIAN',
    surveySketches: 'getSurveysketches',
    cadasterOwnerfolios: 'getOwnerfoliosByCadasterOptions'
  }),
  methods: {
    ...mapActions([
      'fetchParcel',
      'fetchParcelsRUIAN',
      'addParcel',
      'updateParcel',
      'clearRows'
    ]),
    async pickCadaster({ id }) {
      await this.fetchParcelsRUIAN(id);
      this.parcelsData = this.parcelsRUIAN;
      this.parcelsInputDisabled = false;
      this.cadasterOwnerfoliosOptions = this.cadasterOwnerfolios(id);
      this.cadasterOwnerfoliosDisabled = false;
    },
    removeUnwantedFieldValues(fieldValues) {
      return deepClone(
        fieldValues,
        [
          'cadaster',
          'ownerfolio_number',
          'surveysketch',
          'vfk_easement_date',
          'checked'
        ],
        'exclude'
      );
    },
    convertNumberValuesToNumberTypes(keys) {
      const toNumber = (val) => {
        if (typeof val === typeof '') val = val.replace(',', '.');
        return val ? +val : null;
      };
      return keys.reduce((rules, key) => {
        rules[key] = toNumber;
        return rules;
      }, {});
    },
    fieldValuesToNumbers(fieldValues) {
      return reformat(
        fieldValues,
        this.convertNumberValuesToNumberTypes([
          'easement_id',
          'area',
          'length',
          'originalcadaster_code',
          'ownerfolio_id',
          'parcelpart'
        ])
      );
    },
    parseFormDataForSubmission(fieldValues) {
      const formData = this.removeUnwantedFieldValues(fieldValues);
      formData.cadaster_code = Number(fieldValues.cadaster.match(/\d+/)[0]);
      return this.fieldValuesToNumbers(formData);
    },
    submitParcel(fieldValues) {
      const formData = this.parseFormDataForSubmission(fieldValues);
      if (this.mode === 'new') {
        this.addParcel(formData);
      } else {
        this.updateParcel(formData);
        this.clearRows();
      }
      this.closeModal();
    },
    inputClasses() {
      return modalFormClasses();
    },
    goToEasements() {
      this.closeModal();
      this.$router.push({ name: 'easements' });
    },
    closeModal() {
      const { closeModal } = this.$root.$children[0].$refs.modal;
      return closeModal();
    }
  }
};
</script>

<style scoped>
@import '../../styles/modal-form.css';
.helper-text-wrapper {
  display: inline-block;
  position: absolute;
  bottom: 0px;
}

.helper-text {
  font-size: 10px;
  color: #595959;
  padding-top: 5px;
}

.helper-text-icon {
  display: inline-block;
  margin: 0px 5px;
  vertical-align: -3px;
}

.margin-bottom {
  margin-bottom: 18px;
}
</style>