<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Component for creating and working with Modal windows
:dependencies: Icon, Button
:version: 1
:updated: 2021-07-12
-->

<template>
  <transition name="fade">
    <div
      id="modal-backdrop"
      class="modal-backdrop"
      :style="{top: scroll.y + 'px', left: scroll.x + 'px'}"
      @click="onClickOutside"
      v-if="visible">
      <div class="modal-dialog" :style="modalDialogStyles">
        <div v-if="closeBtn" class="modal-close-btn" :style="closeBtnStyle" @click="closeModal">✖</div>
        <div v-if="head" class="modal-head" :style="headStyle">
          <Icon v-bind="iconProps" />
          <div class="modal-head-title" :style="headTextStyle">{{head.text}}</div>
        </div>
        <div class="modal-content" :style="contentStyles">
          <div v-if="text" :style="contentTextStyle">{{text}}</div>
          <div v-if="html" v-html="html"></div>
          <div v-if="input">
            <input :type="input" v-model="value" />
          </div>
          <component v-if="component" :is="component" v-bind="componentProps" ref="component" />
        </div>
        <div class="modal-controls" :style="modalControlsMargin">
          <Btn
            v-if="btns.cancel"
            @action="closeModal({type: 'cancel'})"
            color="cancel"
            label="Zavřít"
            invert />
          <Btn
            v-if="btns.confirm"
            @action="closeModal({type: 'confirm'})"
            color="success"
            label="✔ Potvrdit" />
          <Btn
            v-if="btns.deny"
            @action="closeModal({type: 'deny'})"
            color="warning"
            label="✖ Zrušit" />
          <Btn
            v-for="({label, type, color, invert, action}, idx) in customBtns"
            :key="idx"
            @action="closeModal({type, action})"
            :color="color"
            :label="label"
            :invert="invert ? true : false" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from './Icon.vue';
import { customButton } from './Button.vue';
import { deepClone } from '@/utils';

const Btn = customButton({
  fontSize: 14,
  height: 32
});

const _COLORS = {
  warning: 'var(--warning-color)',
  error: 'var(--error-color)',
  info: 'var(--info-color)',
  success: 'var(--success-color)',
  main: 'var(--main-color)',
  cancel: 'var(--cancel-color)'
};

export default {
  name: 'Modal',
  components: {
    Btn,
    Icon
  },
  data() {
    return {
      visible: false,
      head: {},
      text: null,
      html: null,
      component: null,
      componentProps: null,
      componentData: null,
      input: null,
      closeBtn: false,
      btns: {},
      customBtns: [],
      value: null,
      lock: false,
      contentAlignItems: 'center',
      customStyles: {},
      scroll: { y: window.scrollY, x: window.scrollX }
    };
  },
  props: {
    COLORS: {
      type: Object,
      default: () => _COLORS
    }
  },
  computed: {
    modalDialogStyles() {
      if (this.head) {
        const { color, invert } = this.head;
        const border = invert
          ? '1px solid #595959'
          : `1px solid ${this.COLORS[color] || color}`;
        return { border };
      }
      const { border, borderRadius, backgroundColor } = this.customStyles;
      return { border, borderRadius, backgroundColor };
    },
    closeBtnStyle() {
      const color = this.customStyles.closeBtnColor || '#000';
      return { color };
    },
    headStyle() {
      const { color, invert } = this.head;
      const backgroundColor = invert
        ? 'transparent'
        : this.COLORS[color] || color || this.COLORS['main'];
      const borderBottom = invert
        ? `1.6px solid ${this.COLORS[color] || color || '#595959'}`
        : '';
      return { backgroundColor, borderBottom };
    },
    headTextStyle() {
      const { textColor, color: _color, invert, textSize } = this.head;
      const color = invert
        ? textColor || this.COLORS[_color] || _color || '#000'
        : textColor || '#fff';
      const fontSize = textSize + 'px' || '16px';
      return { color, fontSize };
    },
    contentTextStyle() {
      const style = {};
      if (!this.head) style.marginTop = '5vmin';
      if (!Object.keys(this.btns).length && !this.customBtns.length)
        style.marginBottom = '3vmin';
      return style;
    },
    contentStyles() {
      return {
        alignItems: this.contentAlignItems,
        padding: this.customStyles.padding || '2vmin'
      };
    },
    modalControlsMargin() {
      const btns = Object.keys(this.btns).length;
      const customBtns = this.customBtns.length;
      const margin = btns || customBtns ? '0 15px 15px 15px' : 0;
      return { margin };
    },
    iconProps() {
      const { icon: type, iconSize, iconColor, color, invert } = this.head;
      const fill = invert
        ? iconColor || this.COLORS[color] || color || '#000'
        : iconColor || '#fff';
      return {
        size: iconSize || 21,
        fill,
        type
      };
    }
  },
  methods: {
    configHead({ head, type, invert, heading }) {
      if (head) return head;
      if (!type && !heading) return null;
      const _head = {};
      if (type) {
        _head.icon = type;
        _head.color = type;
        _head.text = type.slice(0, 1).toUpperCase() + type.slice(1);
      }
      if (heading !== undefined) {
        _head.text = heading;
        _head.color = _head.color || 'main';
      }
      if (invert) _head.invert = true;
      return _head;
    },
    configModal({
      type,
      heading,
      invert,
      head,
      text,
      html,
      component,
      componentProps,
      closeBtn,
      btns,
      customBtns,
      input,
      alignItems,
      customStyles,
      lock
    }) {
      this.visible = true;
      this.value = null;
      this.head = this.configHead({ head, type, invert, heading });
      this.text = text || null;
      this.html = html || null;
      this.component = component || null;
      this.componentProps = componentProps || null;
      this.closeBtn = closeBtn || false;
      this.btns = btns || { cancel: 1 };
      this.customBtns = customBtns || [];
      this.input = input || null;
      this.lock = lock || false;
      this.contentAlignItems = alignItems || 'center';
      this.customStyles = customStyles || {};
      this.scroll = { y: window.scrollY, x: window.scrollX };
      document.querySelector('body').style.overflow = 'hidden';
    },
    openModal(config) {
      if (!config)
        config = {
          text: 'Hello from <Modal /> component!',
          btns: {},
          closeBtn: 1
        };
      if (typeof config === 'string')
        config = { text: config, btns: {}, closeBtn: 1 };
      this.configModal(config);
      return new Promise((resolve) => {
        window.addEventListener(
          'modal-resolved',
          function resolveModal({ detail }) {
            window.removeEventListener('modal-resolved', resolveModal);
            resolve(detail);
          }
        );
      });
    },
    bindComponentData() {
      const data = this.$refs.component.$data;
      return deepClone(data);
    },
    closeModal(config) {
      if (!config) config = {};
      const { type, action } = config;
      const detail = {
        closed: true,
        deny: false,
        confirm: false,
        cancel: false
      };
      if (type) detail[type] = true;
      if (this.component && this.component.data)
        detail.data = this.bindComponentData();
      if (this.value) detail.value = this.value;
      if (action) action();
      window.dispatchEvent(new CustomEvent('modal-resolved', { detail }));
      this.visible = false;
      document.querySelector('body').style.overflow = 'auto';
    },
    onClickOutside(e) {
      if (e.srcElement.id !== 'modal-backdrop' || this.lock) return;
      return this.closeModal({ value: this.value });
    }
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(191, 191, 191, 0.7);
  overflow: hidden;
  z-index: 99;
}
.modal-dialog {
  box-sizing: border-box;
  position: relative;
  max-width: 80vw;
  min-width: 460px;
  max-height: 80vh;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  z-index: 100;
  display: grid;
  grid-template-rows: repeat(3, auto);
  overflow: auto;
}
.modal-dialog > * {
  box-sizing: border-box;
}
.modal-head {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
}
.modal-head-title {
  padding-left: 10px;
}
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vmin;
  max-height: calc(80vh - 46px);
}
.modal-close-btn {
  font-size: 3vmin;
  position: absolute;
  top: 1vmin;
  right: 2vmin;
  cursor: pointer;
}
.modal-close-btn:hover {
  transform: scale(1.1);
}
.modal-controls {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  gap: 15px;
}
.modal-controls > .btn {
  margin-left: 10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>