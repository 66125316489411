import axios from 'axios';

const state = {
  categories: []
};

const getters = {
  getCategories: state => state.categories,
  getCategory: state => _id => state.categories.find(({ id }) => id === +_id)
};

const actions = {
  fetchCategories: async ({ commit }) => {
    try {
      const { data } = await axios.get('/categories');
      commit('setCategories', data.data);
    } catch (error) {
      console.error('Failed to fetch categories...');
      console.error(error);
    }
  },
  addCategory: async ({ commit }, category) => {
    const { data } = await axios.post('/categories', category);
    commit('addCategory', data.data);
  },
  updateCategory: async ({ commit }, category) => {
    const { data } = await axios.patch(`/categories/${category.id}`, category);
    const [updatedCategory] = data.data;
    commit('updateCategory', updatedCategory);
  },
  removeCategory: async ({ commit }, ids) => {
    await axios.delete(`/categories?ids=${ids}`);
    commit('removeCategory', ids);
  }
};

const mutations = {
  setCategories: (state, payload) => (state.categories = payload),
  addCategory: (state, payload) => {
    const _payload = Array.isArray(payload) ? payload : [payload];
    state.categories = [..._payload, ...state.categories];
  },
  updateCategory: (state, payload) => {
    const idx = state.categories.findIndex(({ id }) => id === payload.id);
    if (idx !== -1) {
      state.categories.splice(idx, 1, payload);
      state.categories = [...state.categories];
    }
  },
  removeCategory: (state, _id) => {
    let filtered;
    if (Array.isArray(_id)) {
      filtered = state.categories.filter(({ id }) => !_id.includes(id));
    } else {
      filtered = state.categories.filter(({ id }) => id !== _id);
    }
    state.categories = filtered;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
