<template>
  <section>
    <Spinner v-if="getLoading" />
    <section v-if="!getLoading && getTemplates.length">
      <Table
        ref="Table"
        :data="getTemplates"
        :pageSize="100"
        selectionMode="single"
        :tableHeads="TABLE_CONFIG.T_TEMPLATE"
        :control="CONTROL_CONFIG.C_TEMPLATES" />
    </section>
    <p v-if="!getLoading && !getTemplates.length" class="no_records">
      Žádné položky k zobrazení
    </p>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Table from '@/components/generic/Table.vue';
import Spinner from '@/components/generic/Spinner.vue';
import { TABLE_CONFIG, CONTROL_CONFIG } from '@/config';

export default {
  name: 'Templates',
  components: { Spinner, Table },
  created() {
    this.TABLE_CONFIG = TABLE_CONFIG;
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.fetchResources(['fetchTemplates']);
  },
  computed: mapGetters(['getTemplates', 'getLoading']),
  methods: mapActions(['fetchResources'])
};
</script>

