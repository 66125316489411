export const ownerfoliosForm = {
  resource: 'ownerfolios',
  schema: [
    {
      row: 1,
      type: 'autocomplete',
      customClass: 'relative',
      name: 'cadaster',
      id: 'cadaster',
      label: 'K. ú.',
      charLimit: 2,
      class: 'requiredlabel',
      validation: 'required',
      $get: { data: 'getCadastres' }
    },
    {
      row: 2,
      type: 'number',
      name: 'ownerfolio_number',
      label: 'Číslo LV',
      class: 'requiredlabel',
      validation: 'required'
    }
  ],
  mask: {
    ownerfolio_number: val => +val
  }
};
