import { deepClone, reshape } from './misc.util';
import store from '@/store';

export const modalFormClasses = () => {
  return {
    'outer-class': 'modal-form-item',
    'wrapper-class': 'modal-form-input',
    'label-class': 'modal-form-label',
    'input-class': ({ hasErrors }) => {
      return hasErrors ? 'modal-form-input-element-error' : 'modal-form-input-element';
    },
    'errors-class': 'modal-form-error-box',
    'error-class': 'modal-form-error-item',
    'help-class': 'modal-form-help'
  };
};

const newFormRow = (rowClass = 'modal-form-row') => {
  return {
    component: 'div',
    class: rowClass,
    children: []
  };
};

class _VFSchema {
  constructor() {
    this.schema = [];
  }

  create(schema) {
    if (!schema) throw new Error('Valid VueFormulate schema is required!');
    if (!Array.isArray(schema))
      throw new Error('Valid VueFormulate schema is required. It is expected to be an array.');
    this.schema = schema;
    return this;
  }

  get() {
    return this.schema;
  }

  fieldNames() {
    return this.schema.map(({ name }) => name);
  }

  bindGetters() {
    const populatedSchema = this.schema.map(input => {
      if (input.$get) {
        const values = Object.keys(input.$get).reduce((_vals, property) => {
          _vals[property] = store.getters[input.$get[property]];
          return _vals;
        }, {});
        return reshape(input, values, ['$get'], 'exclude');
      }
      return input;
    });
    const updatedSchema = new _VFSchema();
    updatedSchema.create(populatedSchema);
    return updatedSchema;
  }

  bindClasses(classFunction = modalFormClasses, condition = _input => !!_input.row) {
    const styledSchema = this.schema.map(input => {
      if (condition(input)) {
        input = { ...input, ...classFunction() };
      }
      if (input.children) {
        input.children = schemaBindClasses(input.children);
      }
      return input;
    });
    const updatedSchema = new _VFSchema();
    updatedSchema.create(styledSchema);
    return updatedSchema;
  }

  toFormRows(rowClassName) {
    let cursor = 0;
    let previousRow = 1;
    const schemaInRows = this.schema.reduce((schema, input) => {
      if (!input.row) {
        if (input.children) {
          const childrenRowed = schemaToFormRows(input.children);
          input.children = childrenRowed;
        }
        schema.push(input);
        cursor++;
      } else {
        if (previousRow !== input.row) {
          previousRow = input.row;
          cursor++;
        }
        if (!schema[cursor]) schema.push(newFormRow(rowClassName));
        const row = schema[cursor];
        if (input.rowClass) {
          row.class = input.rowClass;
        }
        const clearedInput = deepClone(input, ['row', 'rowClass'], 'exclude');
        row.children.push(clearedInput);
      }
      return schema;
    }, []);
    const updatedSchema = new _VFSchema();
    updatedSchema.create(schemaInRows);
    return updatedSchema;
  }
}

export const VFSchema = schema => {
  const _VFS = new _VFSchema();
  _VFS.create(schema);
  return _VFS;
};

export const schemaFieldNames = schema => schema.map(({ name }) => name);

// this function has strong assumptions on the input !!!
// only valid VueFormulate schemas will not lead to errors
export const schemaToFormRows = _schema => {
  let cursor = 0;
  let previousRow = 1;
  return _schema.reduce((schema, input) => {
    if (!input.row) {
      if (input.children) {
        const childrenRowed = schemaToFormRows(input.children);
        input.children = childrenRowed;
      }
      schema.push(input);
      cursor++;
    } else {
      if (previousRow !== input.row) {
        previousRow = input.row;
        cursor++;
      }
      if (!schema[cursor]) schema.push(newFormRow());
      const row = schema[cursor];
      const clearedInput = deepClone(input, ['row'], 'exclude');
      row.children.push(clearedInput);
    }
    return schema;
  }, []);
};

export const schemaBindClasses = (
  _schema,
  classFunction = modalFormClasses,
  condition = _input => !!_input.row
) => {
  return _schema.map(input => {
    if (condition(input)) {
      input = { ...input, ...classFunction() };
    }
    if (input.children) {
      input.children = schemaBindClasses(input.children);
    }
    return input;
  });
};
