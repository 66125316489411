<template>
  <div :class="`autocomplete formulate-input-element formulate-input-element--${context.type}`" :data-type="context.type">
    <input :class="customClass" type="search" v-model="context.model" autocomplete="off" v-bind="context.attributes"
      @input="selectType" @keydown.enter.prevent="emitClick(selection)" @keydown.down.prevent="increment"
      @keydown.up.prevent="decrement" @blur="context.blurHandler" @search="searchEvent($event)" />
    <ul v-if="filtered.length" class="formulate-input-dropdown">
      <li v-for="(option, index) in filtered" :key="index"
        :data-is-selected="selection && selection.label === option.label" @mouseenter="selectedIndex = index"
        @click="emitClick(option)">{{ option.label }}
        <Icon class="icon-digital" :type="option?.digital ? 'screen' : 'map'" :size="15" fill="black" />
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '@/components/generic/Icon.vue';

export default {
  name: 'FormulateAutocomplete',
  components: {
    Icon
  },
  data() {
    return {
      selectedIndex: 0,
      filtered: [],
      optionsData: this.data,
      search: ''
    };
  },
  props: {
    context: {
      type: Object,
      required: true
    },
    customClass: {
      default: '',
      required: false
    },
    data: {
      type: Array,
      required: false,
      default: () => []
    },
    charLimit: {
      type: Number,
      required: true
    },
    searchType: {
      type: String,
      required: false,
      default: 'default'
    }
  },
  watch: {
    model() {
      this.selectedIndex = 0;
    },
    data(updatedData) {
      this.optionsData = updatedData;
    }
  },
  mounted() {
    this.$emit('emitRef', this);
  },
  computed: {
    selection() {
      if (this.filtered[this.selectedIndex]) {
        return this.filtered[this.selectedIndex];
      }
      return false;
    }
  },

  methods: {
    selectType() {
      if (this.context.model.length >= this.charLimit) {
        switch (this.searchType) {
          case 'default':
            this.filteredOptions();
            break;
          case 'numbers':
            this.searchNumbers();
            break;
        }
      }
    },
    isAlreadySelected() {
      return this.optionsData.find(
        (option) => option.label === this.context.model
      );
    },
    searchNumbers() {
      this.filtered = this.optionsData.filter(
        (option) =>
          option.label.substring(0, this.context.model.length) ===
          this.context.model
      );
    },
    filteredOptions() {
      if (!this.isAlreadySelected()) {
        this.filtered = this.optionsData
          .filter((option) =>
            option.label
              .toLowerCase()
              .includes(this.context.model.toLowerCase())
          )
          .sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });
      }
    },

    pickEnter() {
      const ids = this.optionsData.filter(
        (data) => data.label === this.context.model
      );
      if (ids.length > 0) {
        const row = { id: ids[0].id };
        this.$emit('pickedSuggest', row);
      }
    },

    emitClick(e) {
      this.context.model = this.selection.label;
      this.filteredOptions();
      let row = {
        label: this.selection.label,
        id: e.value,
        digital: e.digital
      };
      this.filtered = [];
      this.$emit('pickedSuggest', row);
    },
    handleScrolling() {
      const topPos = document.querySelector('[data-is-selected]').offsetTop;
      document.getElementsByClassName('formulate-input-dropdown')[0].scrollTop =
        topPos - 35;
    },
    increment() {
      this.handleScrolling();
      const length = this.filtered.length;
      if (this.selectedIndex + 1 < length) {
        this.selectedIndex++;
      } else {
        this.selectedIndex = 0;
      }
    },
    decrement() {
      this.handleScrolling();
      const length = this.filtered.length;
      if (this.selectedIndex - 1 >= 0) {
        this.selectedIndex--;
      } else {
        this.selectedIndex = length - 1;
      }
    },
    searchEvent(event) {
      if (!event.target.value) {
        this.$emit('inputCleared');
      }
    }
  }
};
</script>

<style>
.icon-digital {
  vertical-align: middle;
  display: inline-block;
  padding: 0 5px;
}
</style>