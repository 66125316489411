<!-- 
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Wrapper component for displaying and styling svg?inline icons 
:dependencies: "vue-svg?inline-loader", "v-tooltip"
:version: 4
:updated: 2021-07-12
 -->

<template>
  <div v-if="type" :style="getSize" class="icon-wrapper">
    <component
      :is="getIcon"
      :fill="fill"
      v-tooltip="{content: tooltip, show: 400, hide: 50}" />
  </div>
</template>

<script>
import AddIcon from '@/assets/icons/add.svg?inline';
import AddCircleIcon from '@/assets/icons/addCircle.svg?inline';
import AlertIcon from '@/assets/icons/alert.svg?inline';
import AlertTriangleIcon from '@/assets/icons/alertTriangle.svg?inline';
import AnalysisIcon from '@/assets/icons/analysis.svg?inline';
import ArrowCircleIcon from '@/assets/icons/arrowCircle.svg?inline';
import CancelIcon from '@/assets/icons/cancel.svg?inline';
import ContractIcon from '@/assets/icons/contract.svg?inline';
import DepositIcon from '@/assets/icons/deposit.svg?inline';
import EditIcon from '@/assets/icons/edit.svg?inline';
import ErrorIcon from '@/assets/icons/error.svg?inline';
import InfoIcon from '@/assets/icons/info.svg?inline';
import LogoCosupIcon from '@/assets/icons/logo_cosup.svg?inline';
import LogoHsro from '@/assets/icons/logo_hsro.svg?inline';
import LogoutIcon from '@/assets/icons/logout.svg?inline';
import MapIcon from '@/assets/icons/map.svg?inline';
import ConfirmIcon from '@/assets/icons/confirm.svg?inline';
import SettingsIcon from '@/assets/icons/settings.svg?inline';
import SiteIcon from '@/assets/icons/site.svg?inline';
import SuccessIcon from '@/assets/icons/success.svg?inline';
import SupportIcon from '@/assets/icons/support.svg?inline';
import TableIcon from '@/assets/icons/table.svg?inline';
import TrashIcon from '@/assets/icons/trash.svg?inline';
import SaveIcon from '@/assets/icons/save.svg?inline';
import SearchIcon from '@/assets/icons/search.svg?inline';
import ScreenIcon from '@/assets/icons/screen.svg?inline';
import FilterIcon from '@/assets/icons/filter.svg?inline';
import CzechFlagIcon from '@/assets/icons/czechFlag.svg?inline';
import PolishFlagIcon from '@/assets/icons/polishFlag.svg?inline';
import UkrainianFlagIcon from '@/assets/icons/ukrainianFlag.svg?inline';

const ICONS = {
  add: AddIcon,
  addCircle: AddCircleIcon,
  alert: AlertIcon,
  alertTriangle: AlertTriangleIcon,
  warning: AlertIcon,
  analysis: AnalysisIcon,
  arrowCircle: ArrowCircleIcon,
  cancel: CancelIcon,
  contract: ContractIcon,
  confirm: ConfirmIcon,
  deposit: DepositIcon,
  edit: EditIcon,
  error: ErrorIcon,
  info: InfoIcon,
  logout: LogoutIcon,
  logoCosup: LogoCosupIcon,
  logoHsro: LogoHsro,
  map: MapIcon,
  save: SaveIcon,
  settings: SettingsIcon,
  site: SiteIcon,
  success: SuccessIcon,
  support: SupportIcon,
  screen: ScreenIcon,
  trash: TrashIcon,
  search: SearchIcon,
  table: TableIcon,
  filter: FilterIcon,
  czechFlag: CzechFlagIcon,
  cs: CzechFlagIcon,
  polishFlag: PolishFlagIcon,
  pl: PolishFlagIcon,
  ukrainianFlag: UkrainianFlagIcon,
  ua: UkrainianFlagIcon
};

export default {
  name: 'Icon',
  props: {
    type: String,
    fill: {
      type: String,
      default: '#888'
    },
    size: [Number, String],
    width: {
      type: [Number, String],
      default: '25'
    },
    height: {
      type: [Number, String],
      default: '25'
    },
    tooltip: String
  },
  computed: {
    getSize() {
      if (this.size) {
        return { width: this.size + 'px', height: this.size + 'px' };
      }
      return { width: this.width + 'px', height: this.height + 'px' };
    },
    getIcon() {
      if (!this.type) return;
      return ICONS[this.type];
    }
  }
};
</script>