<!-- 
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Generic button component 
:dependencies: "Icon.vue"
:version: 2
:updated: 2021-07-13
 -->

<template>
  <button class="btn" :type="btnType" @click="getAction()" :style="getBtnStyle">
    <Icon v-if="isIcon" v-bind="getIconBtnProps" />
    <div v-else class="btn-content" :style="getTextStyle">
      <Icon
        v-if="frontIcon"
        v-bind="{...getFrontIconType, ...getTextIconProps}"
        :style="iconFrontPadding" />
      <span
        :class="{'btn-content-offset' : frontIcon || backIcon}"
        :style="btnContentTextAlign">
        {{label}}
      </span>
      <Icon
        v-if="backIcon"
        v-bind="{...getBackIconType, ...getTextIconProps}"
        :style="iconBackPadding" />
    </div>
  </button>
</template>

<script>
import Icon from './Icon.vue';

const _COLORS = {
  warning: 'var(--warning-color)',
  error: 'var(--error-color)',
  info: 'var(--info-color)',
  success: 'var(--success-color)',
  main: 'var(--main-color)',
  cancel: 'var(--cancel-color)'
};

const Button = {
  name: 'Button',
  components: {
    Icon
  },
  emits: ['action'],
  props: {
    linkTo: {
      type: [String, Object]
    },
    COLORS: {
      type: Object,
      default: () => _COLORS
    },
    color: {
      type: String,
      default: 'var(--main-color)'
    },
    height: {
      type: [String, Number]
    },
    width: {
      type: [String, Number]
    },
    margin: {
      type: [String, Number],
      default: 0
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    label: {
      type: String,
      default: 'CLICK ME'
    },
    fontColor: {
      type: String
    },
    fontSize: {
      type: [String, Number],
      default: 16
    },
    fontWeight: {
      type: [String, Number],
      default: 400
    },
    frontIcon: {
      type: String
    },
    backIcon: {
      type: String
    },
    iconPadding: {
      type: [String, Number],
      default: 8
    },
    invert: {
      type: Boolean,
      default: false
    },
    btnType: {
      type: String,
      default: 'button'
    },
    isIcon: {
      type: [Object, String]
    }
  },
  computed: {
    getBtnStyle() {
      return {
        backgroundColor: this.isIcon
          ? null
          : this.invert
          ? this.fontColor || '#fff'
          : this.COLORS[this.color] || this.color,
        border: this.invert
          ? `1px solid ${this.COLORS[this.color] || this.color}`
          : null,
        height: this.isIcon
          ? null
          : this.height
          ? this.height + 'px'
          : +this.fontSize + 14 + 'px',
        width: !this.width
          ? 'auto'
          : isNaN(+this.width)
          ? this.width
          : +this.width + 'px',
        margin:
          typeof this.margin === 'string' ? this.margin : this.margin + 'px'
      };
    },
    getTextStyle() {
      return {
        color: this.invert
          ? this.COLORS[this.color] || this.color
          : this.fontColor
          ? this.COLORS[this.fontColor] || this.fontColor
          : '#fff',
        fontSize: this.fontSize + 'px',
        fontWeight: this.fontWeight
      };
    },
    btnContentTextAlign() {
      const textAlign = this.textAlign;
      return { textAlign };
    },
    getFrontIconType() {
      return {
        type: this.frontIcon
      };
    },
    getBackIconType() {
      return {
        type: this.backIcon
      };
    },
    getTextIconProps() {
      return {
        fill: this.invert
          ? this.COLORS[this.color] || this.color
          : this.fontColor
          ? this.COLORS[this.fontColor] || this.fontColor
          : '#fff',
        size: this.fontSize
      };
    },
    iconFrontPadding() {
      const paddingRight = this.iconPadding + 'px';
      return { paddingRight };
    },
    iconBackPadding() {
      const paddingLeft = this.iconPadding + 'px';
      return { paddingLeft };
    },
    getIconBtnProps() {
      if (typeof this.isIcon === 'string') return { type: this.isIcon };
      const { type, fill, size, width, height, tooltip } = this.isIcon;
      return {
        type,
        fill,
        size,
        width,
        height,
        tooltip
      };
    }
  },
  methods: {
    getAction() {
      if (this.linkTo) {
        if (!this.$router)
          throw new Error('vue-router is required for using linkTo parameter');
        return this.$router.push(this.linkTo);
      }
      return this.$emit('action');
    }
  }
};

export default Button;

// helper method to modify default values of component's props
// without the need of specifying other settings (such as type) as well
// @param originalProps is expected to be valid Vue component's props Object
// IF new default value is an object, return it from a function (Vue API requirement)
// ELSE assign the new value directly
const modifyDefaults = (newDefaults, originalProps) => {
  const newProps = {};
  for (let key in originalProps) {
    newProps[key] = { ...originalProps[key] };
  }
  for (let key in newDefaults) {
    if (typeof newDefaults[key] === 'object') {
      newProps[key].default = () => newDefaults[key];
    } else {
      newProps[key].default = newDefaults[key];
    }
  }
  return newProps;
};

// HOC that enables to return the component with different default properties
export const customButton = (newProps, name) => {
  return {
    render(createElement) {
      return createElement(Button);
    },
    ...Button,
    name: name || 'CustomButton',
    props: modifyDefaults(newProps, Button.props)
  };
};
</script>

<style scoped>
.btn {
  color: #fff;
  appearance: none;
  border: none;
  margin: 0;
  padding: 0 15px;
  background: transparent;
  cursor: pointer;
  border-radius: 5px;
  line-height: 1em;
  height: calc(2em + 8px);
  transition: 0.4s;
}
.btn:hover,
.btn:focus {
  outline: none;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
  filter: invert(5%);
  transition: 0.4s;
}
.btn-content {
  margin: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
}
.btn-content-offset {
  padding-top: 1px;
}
</style>