<template>
  <div :class="`formulate-input-element formulate-input-element--${context.type} ruleStep`" :data-type="context.type">
    <select
      class="ruleSelect ruleAlign"
      type="text"
      autocomplete="no"
      :value="rule.rule_code"
      :disabled="mode === 'edit'"
      @input="selectRule($event)">
      <option
        v-for="option in context.options"
        :key="option.id"
        :value="option.value">
        {{option.label}}
      </option>
    </select>
    <div :class="{'ruleBorder': rule.rulevalues}">
      <div v-for="({name}, idx) in rule.rulevalues" :key="name">
        <FormulateInput
          :type="getRuleDefinition[idx].type"
          :name="name"
          :label="getRuleDefinition[idx].label + ':'"
          :value="rule.rulevalues[idx].value || getRuleDefinition[idx].value"
          :subjects="getRuleDefinition[idx].type === 'rank' ? subjects : null"
          required
          @input="updateRuleValue({value: $event, name, idx})" />
      </div>
    </div>
    <Button
      v-if="ruleSelected"
      width="150"
      height="40"
      class="popupRuleButton"
      type="button"
      textAlign="center"
      :label="getButtonText"
      @action="closeModal({type: 'confirm'})" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/generic/Button.vue';

export default {
  name: 'FormulateRule',
  components: {
    Button
  },
  data: () => {
    return {
      ruleSelected: false
    };
  },
  props: {
    rule: {
      type: Object
    },
    mode: {
      type: String,
      default: 'new'
    },
    context: {
      type: Object,
      required: true
    },
    subjects: {
      type: Array,
      required: false
    }
  },
  created() {
    if (!this.getRules) {
      this.$store.dispatch('fetchRules');
    }
    if (this.mode === 'edit') {
      this.ruleSelected = true;
    }
  },
  computed: {
    ...mapGetters(['getRules', 'getRule']),
    getRuleDefinition() {
      return this.getRule(this.rule.rule_code).definition;
    },
    getButtonText() {
      return this.mode === 'new' ? 'Přidat pravidlo' : 'Upravit pravidlo';
    }
  },
  methods: {
    selectRule(event) {
      const rule_code = event.target.value;
      const inputFields = this.getRule(rule_code).definition;
      const rulevalues = inputFields.map(({ name, value }) => ({
        name,
        value
      }));
      this.$emit('ruleSelected', { rule_code, rulevalues });
      this.ruleSelected = true;
    },
    updateRuleValue(data) {
      this.$emit('ruleUpdated', data);
    },
    closeModal(options) {
      const { closeModal } = this.$root.$children[0].$refs.modal;
      if (this.rule.rulevalues.some(({ value }) => !value)) {
        return console.warn('all fields are required.');
      }
      closeModal(options);
      if (this.mode === 'new') {
        this.openModal('Pravidlo vloženo');
      } else {
        this.openModal('Pravidlo upraveno');
      }
    },
    openModal(text) {
      const { openModal } = this.$root.$children[0].$refs.modal;
      return openModal({ type: 'info', text });
    }
  }
};
</script>
