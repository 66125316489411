import Vue from 'vue';
import { cs } from '@braid/vue-formulate-i18n';

import {
  FormulateRank,
  FormulateRules,
  FormulateSubject,
  FormulateTable,
  FormulateAutocomplete
} from '@/components/forms/vf-custom-inputs';

Vue.component('FormulateAutocomplete', FormulateAutocomplete);
Vue.component('FormulateTable', FormulateTable);
Vue.component('FormulateRules', FormulateRules);
Vue.component('FormulateSubject', FormulateSubject);
Vue.component('FormulateRank', FormulateRank);

export const VF_config = {
  plugins: [cs],
  locale: 'cs',
  locales: {
    cs: {
      required: () => 'Pole je povinné.',
      subjectSignature: () => 'Pole je povinné.'
    }
  },
  seInputDecorators: false,
  rules: {
    subjectSignature: ({ value, getGroupValues }) => {
      const { procurations, procurations_attachment: pa } = getGroupValues();
      if (procurations || pa) {
        if (!value) return false;
      }
      return true;
    }
  },
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  library: {
    table: {
      classification: 'text',
      component: 'FormulateTable',
      slotProps: {
        component: ['changeColl', 'uuid', 'code']
      }
    },
    rule: {
      classification: 'text',
      component: 'FormulateRules',
      slotProps: {
        component: ['rule', 'mode', 'subjects']
      }
    },
    subject: {
      classification: 'text',
      component: 'FormulateSubject'
    },
    rank: {
      classification: 'text',
      component: 'FormulateRank',
      slotProps: {
        component: ['subjects']
      }
    },
    autocomplete: {
      classification: 'select',
      component: 'FormulateAutocomplete',
      slotProps: {
        component: ['charLimit', 'data', 'searchType', 'customClass']
      }
    }
  }
};
