<!-- 
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz
:contact: jakub.mraz@hrdlicka.cz
:copyright: HSRO
:summary: Generic table component with filter and configurable buttons and data structures
:version: 4.0
:dependencies: "Button.vue, Spinner.vue, Icon.vue, vuex & SmartTable"
:updated: 2021-09-15
 -->

<template>
  <div id="table" class="table-container">
    <div class="table-menu" v-if="filter">
      <div class="filter-menu">
        <select
          class="filter-controls"
          v-if="!disable"
          tabindex="5"
          v-model="filterOption"
          @change="clearFilter">
          <option
            v-for="option in tableHeads.filter((head) => head.showInFilter !== false)"
            :value="option.value"
            :key="option.name">
            {{ option.name }}
          </option>
        </select>
        <input
          class="filter-controls filter-input"
          placeholder="Vyhledat Záznam"
          tabindex="4"
          id="filter"
          v-model="filters[filterOption].value" />
      </div>
      <div class="secondary_buttons">
        <Button
          class="trash-button"
          :isIcon="{
            type: 'trash',
            tooltip:'Odstranit',
            fill: 'black' }"
          v-if="control.deleteRow && getSelectedRows.length"
          @action="deleteRow" />
        <Button
          :isIcon="{
            type: 'confirm',
            tooltip:'Uložit změny',
            fill: 'black' }"
          v-if="getUpdatedRows.length > 0 && control.editable"
          @action="cleanUpdatableStyles()" />
        <Button
          :isIcon="{
            type: 'cancel',
            tooltip:'Zahodit změny',
            fill: 'black' }"
          v-if="getUpdatedRows.length > 0 && control.editable"
          @action="$emit('editCanceled')" />
        <span
          v-for="(button, index) in control.secondary"
          :key="index">
          <Button
            :class="button.className"
            :isIcon="{type: button.icon, tooltip: button.tooltip, fill: 'black' }"
            v-if="getSelectedRows.length && button.show(data.find(({id}) => id === getSelectedRows[0]))"
            @action="$emit(button.emit, getSelectedRows)" />
        </span>
      </div>
      <Spinner v-if="getLoadingContract" />
    </div>
    <div>
      <v-table
        id="tableID"
        ref="SmartTable"
        class="table"
        :hideSortIcons="!changeColl"
        :data="data"
        :filters="filters"
        :currentPage.sync="currentPage"
        :pageSize="pageSize"
        @totalPagesChanged="totalPages = $event">
        <thead id="head" slot="head" :key="headkey" :class="{'fixed-header':!control.notFixedHeader}">
          <th
            v-if="control.selectable"
            id="check-head"><input
              type="checkbox"
              disabled /></th>
          <CustomTh
            :style="{ minWidth: head.width + 20+ 'px !important', width: head.width + 'px !important'  }"
            :class="{ 'leftDist': !control.selectable}"
            v-for="(head,index) in tableHeadsConfig.filter((head) => head.hide !== true)"
            :key="head.name !== 'empty' ? head.name  : head.name + index"
            :stopSort="changeColl"
            :sortKey="head.value"><span v-if="!changeColl">{{ head.name }}</span>
            <FormulateInput
              v-if="changeColl"
              :value="head.value"
              :id="'head-'+index"
              :key="'head-'+index"
              type="select"
              @change="changeCollumn($event,index)"
              :options="tableOptions.map(head => head = {value:head.value, label: head.name})" />
            <!--  <span v-if="changeColl" @click="deleteColl(index)">X</span> -->
          </CustomTh>
        </thead>
        <tbody
          slot="body"
          slot-scope="{ displayData }">
          <v-tr
            v-for="(row, index) in displayData"
            :key="row[keyName]"
            :row="row"
            :class="{ alternate: row.alternate_subject === 1, 'table-info': getSelectedRows.includes(row[keyName])}"
            :id="'row' + index">
            <td
              v-if="control.selectable"
              id="check-column">
              <input
                type="checkbox"
                :checked="getSelectedRows.includes(row[keyName])"
                @change="correctSelect(row, $event.target.checked)"
                :disabled="getLoadingContract" />
            </td>
            <td
              v-for="cell in tableHeads.filter((head) => head.hide !== true)"
              :key="cell.value"
              :id="cell.value + index"
              @click="cellClick(cell, row)"
              :style="{ maxWidth: cell.width + 'px !important', width: cell.width + 'px !important'  }"
              :class="{'link': cell.emit && cell.emit(row), 'input-cell': !cell.type, 'leftDist': !control.selectable}">
              <FormulateInput
                input-class="dynamic"
                :style="{ maxWidth: cell.width + 'px !important', width: cell.width + 'px !important'  }"
                :disabled="
                row.evidencetype == 1 &&
                cell.options != 'numtype' &&
                cell.value != 'evidencetype'"
                v-if="cell.type"
                :type="cell.type"
                :pattern="cell.pattern"
                :charLimit="cell.charLimit ? cell.charLimit : undefined"
                :data="cell.data ? evalOptions(cell.data) : undefined"
                @change="prepareForUpdate(row, $event.target.value, cell.value + index, cell)"
                @change.native="prepareForUpdate(row, $event.target.value, cell.value + index, cell)"
                @pickedSuggest="prepareForUpdate(row, $event.label, cell.value + index, cell)"
                :value="row[cell.value]"
                v-model="row[cell.value]"
                :options="cell.options ? evalOptions(cell.options, row) : []" />
              <span v-if="!cell.type">{{
             row[cell.value]
            }}</span>
            </td>
          </v-tr>
        </tbody>

      </v-table>
      <smart-pagination
        v-if="pagination"
        :currentPage.sync="currentPage"
        :totalPages="totalPages"
        :maxPageLinks="maxPageLinks"
        :hideSinglePage="hideSinglePage" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Icon from './Icon.vue';
import Button from './Button.vue';
import CustomTh from './CustomTh.vue';
import Spinner from 'vue-simple-spinner';
import Vue from 'vue';

export default {
  name: 'Table',
  components: { Button, Icon, Spinner, CustomTh },
  props: {
    data: {
      required: true,
      type: [Array, Function]
    },
    filter: {
      required: false,
      type: Boolean,
      default: true
    },
    control: {
      required: true,
      type: Object
    },
    pageSize: {
      required: true,
      type: Number,
      default: 50
    },
    hideSinglePage: {
      required: false,
      type: Boolean,
      default: true
    },
    maxPageLinks: {
      required: false,
      type: Number,
      default: 5
    },
    selectedClass: {
      required: false,
      type: String,
      default: 'table-info'
    },
    selectionMode: {
      required: false,
      type: String,
      default: 'multiple'
    },
    tableHeads: {
      required: true,
      type: Array
    },
    options: {
      required: false,
      type: Array,
      default: () => []
    },
    changeColl: {
      required: false,
      type: Boolean,
      default: false
    },
    keyName: {
      type: String,
      default: 'id'
    },
    pagination: {
      default: false
    }
  },
  data: () => {
    return {
      filters: {},
      currentPage: 1,
      totalPages: 2,
      filterOption: 'all',
      filterPastOption: 'all',
      disable: false,
      tableHeadsConfig: [],
      headkey: 0,
      styleElements: []
    };
  },
  watch: {
    currentPage: function () {
      setTimeout(this.handleDots, 0);
    }
  },
  mounted() {
    this.handleDots();
  },
  created() {
    this.tableHeadsConfig = this.tableHeads;
    this.changeColl
      ? (this.tableOptions = JSON.parse(localStorage.getItem('tableOptions')))
      : null;
    this.filter ? this.handleFiltersConfig() : null;
  },
  destroyed() {
    this.clearRows();
  },
  computed: mapGetters([
    'getOwnerfoliosByCadasterOptions',
    'getClipboardData',
    'getLoadingContract',
    'getSelectedRows',
    'getUpdatedRows'
  ]),
  methods: {
    ...mapActions([
      'addRow',
      'removeRows',
      'clearRows',
      'changeClipboardTable',
      'changeClipboardData',
      'clearUpdatedRows',
      'addUpdatedRows',
      'removeUpdatedRows'
    ]),
    handleFiltersConfig() {
      this.filters = this.tableHeads.reduce((pastValue, col) => {
        let keys = '';
        if (col.value === 'all') {
          keys = this.tableHeads.map((head) => {
            if (head.parent) {
              return (head = head.parent + '.' + head.value);
            } else {
              return (head = head.value);
            }
          });
        } else if (col.parent) {
          keys = [col.parent + '.' + col.value];
        } else {
          keys = [col.value];
        }
        pastValue[col.value] = {
          value: '',
          keys: keys
        };
        return pastValue;
      }, {});
    },
    /*  async deleteColl(paraIndex) {
      // this deletes a collumn and updates the set table
      this.tableHeadsConfig.splice(paraIndex, 1);
      let newClipboard = this.getClipboardData;
      newClipboard = newClipboard.map((split) =>
        split.filter((fil, index) => index != paraIndex)
      );
      this.changeClipboardData(newClipboard);
      this.customizeTable();
    }, */
    customizeTable() {
      // this updates the table collumns order and number
      let rows = this.tableHeadsConfig.reduce((pastValue, head, index) => {
        head = { [head.value]: index };
        pastValue = { ...pastValue, ...head };
        return pastValue;
      }, {});
      this.changeClipboardTable(rows);
    },
    changeCollumn(e, indexOfSelectedCol) {
      // this function switches the data of two used collumns, if there is no duplicate, it just rewrites the data.
      let selectedColNewPara = e.target.value;
      let indexOfSecondCol = this.tableHeadsConfig.reduce(
        (pastVal, para, index) => {
          if (para.value === selectedColNewPara) {
            pastVal = index;
            return pastVal;
          }
          return pastVal;
        },
        -1
      );
      let selectedColCurrentPara =
        this.tableHeadsConfig[indexOfSelectedCol].value;
      const data = JSON.parse(localStorage.getItem('tableOptions')).reduce(
        (pastVal, head) => {
          if (head.value === selectedColNewPara) {
            pastVal.selectedColNewConfig = { ...head };
            return pastVal;
          } else if (head.value === selectedColCurrentPara) {
            pastVal.selectedColOldConfig = { ...head };
            return pastVal;
          }
          return pastVal;
        },
        {}
      );
      //vue.set might be reduntand and could be in the Jsonparse
      Vue.set(
        this.tableHeadsConfig[indexOfSelectedCol],
        'value',
        selectedColNewPara
      );

      Vue.set(
        this.tableHeadsConfig[indexOfSelectedCol],
        'name',
        data.selectedColNewConfig.name
      );
      if (indexOfSecondCol !== -1) {
        Vue.set(
          this.tableHeadsConfig[indexOfSecondCol],
          'value',
          indexOfSecondCol
        );

        Vue.set(
          this.tableHeadsConfig[indexOfSecondCol],
          'name',
          indexOfSecondCol
        );
      }
      this.data.map((d) => {
        // rewrites the data with the selected data
        d[selectedColNewPara] = d[selectedColCurrentPara];
      });
      this.headkey += 1;
      this.customizeTable();
    },

    evalOptions(name, row) {
      if (name === 'getCadasterOwnerfolios') {
        return this.getOwnerfoliosByCadasterOptions(row.cadaster_code);
      } else {
        const option = this.options.find((option) => {
          return option.name === name;
        });
        if (option && option.data.length === 1) {
          option.data[0].selected = true;
          option.data[0].disabled = true;
        }
        return option
          ? option.data
          : [
              {
                value: null,
                label: 'Není k dispozici',
                selected: true,
                disabled: true
              }
            ];
      }
    },
    cellClick(cell, row) {
      if (cell.emit)
        if (cell.emit(row)) {
          this.$emit(cell.value, row);
        }
    },
    clearFilter() {
      this.filters[this.filterPastOption].value = '';
      this.filterPastOption = this.filterOption;
    },
    deleteRow() {
      this.$emit('deleteRow', this.getSelectedRows);
      this.clearRows();
    },
    /* gatherOptionValues() {
      let cells = document.getElementsByClassName('numtype');
      let values = [];
      for (let i = 0; i < cells.length; i++) {
        let id = 'numtype' + i;
        values = [...values, document.getElementById(id).value];
      }
      return values;
    }, */
    prepareForUpdate(row, value, id, cell) {
      row[cell.value] = value;
      document.getElementById(id).getElementsByTagName('input').length
        ? (document
            .getElementById(id)
            .getElementsByTagName('input')[0].style.backgroundColor = 'yellow')
        : (document
            .getElementById(id)
            .getElementsByTagName(cell.type)[0].style.backgroundColor =
            'yellow');
      this.styleElements.push(id);
      if (this.control.editable) {
        if (this.getUpdatedRows.length) {
          for (let i = 0; i < this.getUpdatedRows.length; i++) {
            this.removeUpdatedRows([row.id]);
          }
        }
        this.addUpdatedRows(row);
      }
    },
    async cleanUpdatableStyles() {
      await this.$emit('editConfirmed', this.getUpdatedRows);
      this.styleElements.map((id) => {
        const el = document.getElementById(id);
        if (el.getElementsByTagName('input').length)
          el.getElementsByTagName('input')[0].style.backgroundColor = 'white';
        if (el.getElementsByTagName('select').length)
          el.getElementsByTagName('select')[0].style.backgroundColor = 'white';
        if (el.getElementsByTagName('textarea').length)
          el.getElementsByTagName('textarea')[0].style.backgroundColor =
            'white';
      });
      this.clearUpdatedRows();
    },

    singleRowSelection(row, value) {
      this.clearRows();
      if (value) {
        this.addRow(row[this.keyName]);
      }
    },
    multiRowSelection(row, value) {
      if (value) {
        this.addRow(row[this.keyName]);
      } else {
        this.removeRows([row[this.keyName]]);
      }
    },
    correctSelect(row, value) {
      /* Selection mode used as condition for different selection functionality */
      if (this.selectionMode === 'single') this.singleRowSelection(row, value);
      if (this.selectionMode !== 'single') this.multiRowSelection(row, value);
    },
    handleDots() {
      if (this.pagination) {
        const array = document.getElementsByClassName('page-item');
        for (let i = 0; i < array.length; i++) {
          if (array[i].firstElementChild.innerHTML === '...') {
            array[i].style.display = 'none';
          } else {
            array[i].style.display = 'block';
          }
        }
      }
    }
  }
};
</script>

<style scoped>
@import '../../styles/table.css';

.main-buttons {
  display: flex;
  position: absolute;
  left: 60%;
  top: 5%;
}
.btn {
  padding: 10px;
}
.secondary-buttons {
  display: flex;
}
</style>