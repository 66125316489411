<template>
  <div>
    <Spinner v-if="getLoading" />
    <section v-if="!getLoading && getEasements.length">
      <Table
        ref="Table"
        :data="getEasements"
        :pageSize="100"
        selectedClass='table-info'
        :tableHeads="TABLE_CONFIG.T_EASEMENTS"
        :control="CONTROL_CONFIG.C_EASEMENTS"
        @deleteRow="removeEasement($event)"
        @analysis="easementAnalysis($event)" />
    </section>
    <p v-if="!getLoading && !getEasements.length" class="no_records">
      Žádné položky k zobrazení
    </p>
  </div>
</template>

<script>
import Spinner from '@/components/generic/Spinner.vue';
import Table from '@/components/generic/Table.vue';
import { TABLE_CONFIG, ALERT_EASEMENTS, CONTROL_CONFIG } from '@/config';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Easements',
  components: { Spinner, Table },
  created() {
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.TABLE_CONFIG = TABLE_CONFIG;
    this.fetchResources(['fetchEasements']);
  },
  computed: mapGetters(['getEasements', 'getLoading']),
  methods: {
    ...mapActions(['fetchResources', 'removeEasement', 'analyzeEasement']),
    openModal(config) {
      const { openModal } = this.$root.$children[0].$refs.modal;
      return openModal(config);
    },
    async easementAnalysis(ids) {
      const done = await this.analyzeEasement(ids);
      if (done) {
        this.openModal(ALERT_EASEMENTS);
      }
      this.fetchResources(['fetchEasements']);
    }
  }
};
</script>

