<template>
  <section>
    <Spinner v-if="!showForm" />
    <FormulateForm v-else class="template-form" @submit="handleSubmit" id="main_form">
      <!-- subjekty -->
      <div class="template-group">
        <div class="template-group-header">
          <label><strong>{{subjectsLabel}}</strong></label>
          <Button
            width="100"
            height="40"
            @action="addSubject"
            textAlign="center"
            label="Přidat"
            invert
            type="button" />
          <label class="required" v-show="subjects.length">Typ subjektu:</label>
          <label class="required" v-show="subjects.some(({subjecttype_code}) => subjecttype_code !== 'N/A')">
            Název subjektu:
          </label>
        </div>
        <div v-if="!subjects.length" class="template-form-message">
          Zatím nejsou pro šablonu definovány žádné zapojené subjekty.
        </div>
        <div v-else class="template-group-subjects" v-for="(subject, i) in subjects" :key="subject._key">
          <div class="rank">{{i + 1}}.</div>
          <FormulateInput
            type="select"
            name="subjecttype_code"
            required
            @input="selectSubjectType($event, i)"
            input-class="subject-type-select"
            v-model="subjects[i].subjecttype_code"
            :options="getSubjectType" />
          <FormulateInput
            v-show="subjects[i].subjecttype_code && subjects[i].subjecttype_code !== 'N/A'"
            type="select"
            name="subject_id"
            required
            input-class="subject-name-select"
            :options="getSubjects.map(({id, name}) => ({value: id, label: name}))"
            v-model="subjects[i].subject_id"
            @input="selectSubjectName($event, i)" />
          <Button
            width="100"
            height="40"
            @action="deleteSubject(i)"
            color="#F5222D" invert
            textAlign="center"
            label="Odebrat"
            type="button" />
        </div>
      </div>
      <!-- podmínky (rules) -->
      <div class="rules-group">
        <div class="template-group-header">
          <strong><label>{{rulesLabel}}</label></strong>
          <Button
            width="100"
            height="40"
            @action="openRulesForm"
            textAlign="center"
            label="Přidat" invert
            type="button" />
        </div>
        <div v-if="!rules.length" class="template-form-message">
          Zatím nejsou pro šablonu definovány žádné podmínky.
        </div>
        <div v-else class="template-form-message">
          Kompletní zápis podmínky (tučeně zvýrazněné parametry)
        </div>
        <div v-for="(rule, index) in rules" :key="index">
          <div class="rule-block">
            <p class="rule-block-description" v-html="parseRuleDescription(rule)">
              <span
                v-if="rule.rulevalues[1].name === 'color'"
                :style="{'background-color': rule.rulevalues[1].value, 'padding': '0 10px', 'margin': '5px'}"></span>
            </p>
            <Button
              width="100"
              height="40"
              @action="openRulesForm(rule, index)"
              textAlign="center"
              label="Upravit" invert
              type="button" />
            <Button
              width="100"
              height="40"
              @action="rules.splice(index, 1)"
              color="#F5222D" invert
              textAlign="center"
              label="Odebrat"
              type="button" />
          </div>
        </div>
      </div>
    </FormulateForm>
  </section>
</template>

<script>
import RulesForm from './RulesForm';
import { mapGetters, mapActions } from 'vuex';
import { deepClone } from '@/utils';
import Button from '../generic/Button.vue';
import Spinner from '@/components/generic/Spinner.vue';

export default {
  name: 'TemplateForm',
  components: {
    Button,
    Spinner
  },
  data: () => {
    return {
      subjects: [],
      rules: [],
      showForm: false
    };
  },
  async created() {
    await this.fetchResources(['fetchSubjects', 'fetchRules']);

    const { meta, params } = this.$route;
    this.mode = meta.mode;
    this.template_id = Number(params.template_id);

    if (this.mode === 'edit') {
      await this.fetchTemplate(this.template_id);
      const templateData = this.getTemplate(this.template_id);
      this.preloadValues(templateData);
    }
    this.showForm = true;
  },
  computed: {
    ...mapGetters([
      'getSubjects',
      'getSubject',
      'getTemplate',
      'getSubjectType',
      'getRule'
    ]),
    subjectsLabel() {
      return this.subjects.length < 2
        ? 'Zapojený subjekt'
        : 'Zapojené subjekty';
    },
    rulesLabel() {
      return this.rules.length < 2 ? 'Podmínka šablony' : 'Podmínky šablony';
    }
  },
  methods: {
    ...mapActions(['fetchTemplate', 'fetchResources', 'updateTemplate']),
    // subject related methods
    addSubject() {
      const newSubject = {
        _key: Math.floor(Math.random() * 1000000),
        subjecttype_code: 'N/A'
      };
      this.subjects.push(newSubject);
    },
    selectSubjectType(subjecttype_code, idx) {
      this.subjects[idx].subjecttype_code = subjecttype_code;
      this.subjects = deepClone(this.subjects);
    },
    selectSubjectName(id, idx) {
      this.subjects[idx].subject_id = id;
      this.subjects = deepClone(this.subjects);
    },
    // rules related methods
    openModalForRules({ component, componentProps, customStyles }) {
      const { openModal } = this.$root.$children[0].$refs.modal;
      return openModal({
        component,
        componentProps,
        customStyles,
        closeBtn: 1,
        btns: {}
      });
    },
    async openRulesForm(rule, index) {
      const mode = rule ? 'edit' : 'new';
      const initialRuleValue = rule ? deepClone(rule) : null;
      const component = RulesForm;
      const componentProps = {
        initialRuleValue,
        mode,
        subjects: this.subjects
      };
      await this.openModalForRules({
        component,
        componentProps,
        customStyles: {
          closeBtnColor: 'var(--main-color)',
          border: '1px solid var(--main-color)',
          borderRadius: '0px',
          backgroundColor: 'var(--sidebar-color)'
        }
      }).then((resp) => {
        if (resp.confirm) {
          if (rule) {
            this.rules.splice(index, 1, resp.data.rule);
          } else {
            this.rules.push(resp.data.rule);
            this.rules = [...this.rules];
          }
        }
      });
    },
    getRuleColorThumbnail(color) {
      return `<span class="rule-color-thumbnail" style="background-color: ${color}"></span>`;
    },
    parseRuleDescription({ rule_code, rulevalues }) {
      let description = this.getRule(rule_code).description;
      rulevalues.forEach(({ name, value }) => {
        const placeholder = `{${name}}`;
        const replaceValue =
          name === 'subjectid'
            ? this.getSubject(value).name
            : name === 'rank'
            ? value +
              '. ' +
              this.getSubjectType[this.subjects[value - 1].subjecttype_code]
            : value;
        description = description.replace(
          placeholder,
          `<strong>${replaceValue}</strong>`
        );
        if (name === 'color') {
          description += this.getRuleColorThumbnail(value);
        }
      });
      return description;
    },
    // general templateForm related methods
    preloadValues(templateData) {
      const { subjects, rules, id } = templateData;
      this.subjects = subjects || [];
      this.rules = rules || [];
      this.id = id;
    },
    deleteSubject(i) {
      const filter = this.rules.filter(
        ({ rule_code }) => rule_code === 'CONTRACT_PRICE_SUBJECT'
      );
      const values = filter.map(({ rulevalues }) => rulevalues[1].value);
      const length = values.reduce((past, value) => {
        if (value > past) {
          past = value;
        }
        return past;
      }, 0);
      if (i > length - 1) {
        this.subjects.splice(i, 1);
      } else {
        const { openModal } = this.$root.$children[0].$refs.modal;
        openModal({
          text: 'Pořadí subjektu je závislé na pravidlech!',
          type: 'warning',
          heading: 'Pozor'
        });
      }
    },
    async handleSubmit() {
      let formData = {};
      formData.subjects = this.subjects.map(
        (subject) =>
          (subject = {
            subjecttype_code: subject.subjecttype_code,
            subject_id: Number(subject.subject_id)
          })
      );
      formData.rules = this.rules.map(
        (rule) =>
          (rule = {
            rule_code: rule.rule_code,
            rulevalues: rule.rulevalues.map(
              (r) => (r = { value: Number(r.value) || r.value, name: r.name })
            )
          })
      );
      formData.id = this.id;
      await this.updateTemplate(formData);
      this.$root.$children[0].$refs.modal
        .openModal({
          type: 'warning',
          heading: 'Pozor!',
          lock: false,
          text: 'Provedené změny se projeví pouze v generovaných dokumentech smluv a jejich přílohách. Pokud chcete změny provést i v údajích pro návrh na vklad do KN, kontaktujte prosím technickou podporu.',
          btns: { confirm: true }
        })
        .then(async () => {
          this.$router.push({
            name: 'templates'
          });
        });
    }
  }
};
</script>

<style scoped>
@import '../../styles/new-template.css';
@import '../../styles/rules.css';
</style>