<template>
  <li class="project-card"
    :class="activeProjectId === project.id ? 'project-card-enabled' : 'project-card-disabled'"
    @click="setProject(project.id)">
    <div class="project-card-header">
      <img :src="project.image_url" class="project-card-header-image" />
      <h3 class="project-card-header-label">{{project.name}}</h3>
      <div class="project-card-header-control-light"
        :class="activeProjectId === project.id ? 'control-light-enabled' : 'control-light-disabled'"></div>
    </div>
    <p class="project-card-description">
      {{project.description.slice(0,150)}}{{project.description.length > 150 ? '...' : ''}}
    </p>
  </li>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ProjectCard',
  props: {
    project: {
      type: Object,
      required: true
    },
    activeProjectId: {
      type: Number,
      required: true
    }
  },
  methods: mapActions(['setProject'])
};
</script>

<style scoped>
.project-card {
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: 0.4s;
}
.project-card-enabled {
  box-shadow: inset 0 0 10px -5px rgb(0, 55, 123);
  pointer-events: none;
}
.project-card-disabled {
  opacity: 0.5;
  cursor: pointer;
}
.project-card-disabled:hover {
  opacity: 1;
  box-shadow: 0 0 10px -5px rgb(0, 55, 123);
}
.project-card-header {
  position: relative;
  display: grid;
  grid-template-columns: auto auto 1fr;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.project-card-header-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.project-card-header-label {
  font-size: 18px;
  padding: 15px 10px 20px 10px;
}
.project-card-header-control-light {
  position: absolute;
  top: 10px;
  right: 0px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.control-light-enabled {
  background-color: rgb(20, 210, 30);
  box-shadow: 0 0 5px 0 rgb(20, 180, 30);
}
.control-light-disabled {
  background-color: rgb(200, 10, 30);
  box-shadow: 0 0 5px 0 rgb(200, 10, 30);
}
.project-card-description {
  padding-top: 10px;
  font-size: 14px;
}
</style>