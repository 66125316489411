import Vue from 'vue';
import Router from 'vue-router';
import {
  SitesTable,
  EasementsTable,
  ParcelsTable,
  ContractsTable,
  OwnersTable,
  OwnerfoliosTable,
  CategoriesTable,
  EquipmentsTable,
  SubjectsTable
} from '@/components/tables';
import {
  Rules,
  Templates,
  About,
  Support,
  Projects,
  SiteDetail,
  ErrorPage
} from '@/components/views';
import { SitesForm, EasementsForm, TemplateForm } from '@/components/forms';
import store from '../store';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(error => {
    if (error.name !== 'NavigationDuplicated') {
      throw error;
    }
  });
};

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE,
  routes: [
    {
      path: '/',
      name: 'projects',
      component: Projects,
      meta: { heading: 'Projekty' }
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: { heading: 'O Aplikaci' }
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
      meta: { heading: 'Podpora' }
    },
    {
      path: '/sites',
      name: 'sites',
      component: SitesTable,
      meta: { add: 'Vytvořit novou stavbu', update: 'Upravit stavbu', heading: 'Stavby' }
    },
    {
      path: '/sites/new',
      name: 'newSite',
      component: SitesForm,
      meta: { submit: 'Uložit Stavbu', mode: 'new', heading: 'Vytvořit novou stavbu' }
    },
    {
      path: '/sites/:site_id',
      name: 'siteDetail',
      component: SiteDetail,
      meta: {
        update: 'Upravit stavbu',
        heading: () => {
          const { site_id } = router.currentRoute.params;
          const { name } = store.getters.getSite(site_id);
          return `Detail stavby: ${name}`;
        }
      }
    },
    {
      path: '/sites/:site_id/edit',
      name: 'editSite',
      component: SitesForm,
      meta: { submit: 'Uložit Stavbu', mode: 'edit', heading: 'Úprava stavby' }
    },
    {
      path: '/sites/:site_id/easements',
      name: 'easements',
      component: EasementsTable,
      meta: {
        add: 'Přidat věcné břemeno',
        update: 'Upravit věcné břemeno',
        heading: 'Věcná břemena'
      }
    },
    {
      path: '/sites/:site_id/easements/new',
      name: 'newEasement',
      component: EasementsForm,
      meta: { submit: 'Uložit věcné břemeno (GP)', heading: 'Přidat věcné břemeno' }
    },
    {
      path: '/sites/:site_id/easements/:easement_id/edit',
      name: 'editEasement',
      component: EasementsForm,
      meta: { submit: 'Uložit věcné břemeno (GP)', heading: 'Úprava věcného břemene' }
    },
    {
      path: '/sites/:site_id/parcels',
      name: 'parcels',
      component: ParcelsTable,
      meta: {
        add: 'Nové věcné břemeno na parcelách',
        update: 'Upravit věcné břemeno na parcelách',
        modal: true,
        heading: 'Věcná břemena na parcelách'
      }
    },
    {
      path: '/sites/:site_id/contracts',
      name: 'contracts',
      component: ContractsTable,
      meta: { heading: 'Smlouvy' }
    },
    {
      path: '/sites/:site_id/ownerfolios',
      name: 'ownerfolios',
      component: OwnerfoliosTable,
      meta: {
        add: 'Přidat nový list vlastnictví',
        update: 'Upravit list vlastnictví',
        modal: true,
        heading: 'Listy vlastnictví'
      }
    },
    {
      path: '/sites/:site_id/owners',
      name: 'owners',
      component: OwnersTable,
      meta: {
        add: 'Přidat vlastníka na LV',
        update: 'Upravit vlastníka na LV',
        heading: 'Vlastníci',
        modal: true,
        customResourceId: id => store.getters.getOwnerByOoId(id).id
      }
    },
    {
      path: '/settings',
      name: 'settings',
      redirect: { name: 'subjects' },
      meta: { heading: 'Nastavení', disabled: true }
    },
    {
      path: '/settings/categories',
      name: 'categories',
      component: CategoriesTable,
      meta: {
        add: 'Přidat novou kategorii',
        update: 'Upravit kategorii',
        heading: 'Kategorie',
        modal: true
      }
    },
    {
      path: '/settings/equipments',
      name: 'equipments',
      component: EquipmentsTable,
      meta: {
        add: 'Přidat nové zařízení',
        update: 'Upravit zařízení',
        heading: 'Typy zařízení',
        modal: true
      }
    },
    {
      path: '/settings/subjects',
      name: 'subjects',
      component: SubjectsTable,
      meta: {
        add: 'Přidat nový subjekt',
        update: 'Upravit subjekt',
        heading: 'Subjekty',
        modal: true
      }
    },
    {
      path: '/settings/rules',
      name: 'rules',
      component: Rules,
      meta: {
        heading: 'Pravidla'
      }
    },
    {
      path: '/settings/templates',
      name: 'templates',
      component: Templates,
      meta: {
        update: 'Upravit dodatečné informace pro šablonu',
        heading: 'Šablony',
        modal: false
      }
    },
    {
      path: '/settings/templates/:template_id/edit',
      name: 'editTemplates',
      component: TemplateForm,
      meta: {
        submit: 'Uložit Šablonu',
        mode: 'edit',
        heading: 'Úprava dodatečných informací šablony'
      }
    },
    {
      path: '*',
      name: '404',
      component: ErrorPage
    }
  ]
});
router.beforeEach((to, from, next) => {
  if (to.params.project_id) {
    if (to.params.project_id != store.getters.getActiveProject.id) {
      next({ path: '/projects' });
      console.log('?');
    } else {
      next();
    }
  } else {
    next();
  }
});
router.beforeResolve((to, from, next) => {
  if (!store.getters.isLoggedIn && to.path !== '/') {
    next({ path: '/' });
  }
  store.getters.getUpdatedRows.length > 0
    ? store._actions.updatingAlert[0]()
    : (store._actions.clearRows[0](), next());
});

export default router;
