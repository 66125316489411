<template>
  <div>
    <Spinner v-if="!showPage" />
    <div v-if="showPage">
      <section class="basic-info">
        <h2 class="basic-header">Základní informace</h2>
        <hr />
      </section>
      <section class="info-section">

        <div class="info-col"> <span class="info-text"> Číslo stavby: </span> <span class="info-text"> Název stavby:
          </span></div>
        <div class="info-col"> <span>{{getSiteDetail.name}} </span> <span> {{getSiteDetail.longname}}

          </span> </div>
        <div class="info-col"> <span class="info-text"> Kategorie stavby: </span> <span class="info-text"> Šablona/y
            smlouvy:</span> </div>
        <div class="info-col"> <span>
            {{getCategory(getSiteDetail.category_id)?getCategory(getSiteDetail.category_id).name: "Nemá přiřazenou žádnou kategorii"}}</span>
          <span
            v-if="getSiteDetail.contemplates.length">{{getSiteDetail.contemplates.map(({name}) => name).join(" | ")}}
          </span> <span v-else></span>
        </div>

      </section>
      <section class="preview-grid">
        <TablePreview linkTo="easements"
          :tableHeads="TABLE_CONFIG.T_EASEMENTS"
          :control="CONTROL_CONFIG.C_SITEDETAIL" :data="getEasements" :formType="'page'" />
        <TablePreview linkTo="ownerfolios"
          :tableHeads="TABLE_CONFIG.T_OWNERFOLIOS"
          :control="CONTROL_CONFIG.C_SITEDETAIL" :data="getOwnerfolios"
          modalHeading="Přidat nový list vlastnictví" />
        <TablePreview linkTo="parcels"
          :tableHeads="simplifyTableHeads(TABLE_CONFIG.T_PARCELS)"
          :control="CONTROL_CONFIG.C_SITEDETAIL" :data="getParcels"
          modalHeading="Přidat novou parcelu" />
        <TablePreview linkTo="owners"
          :tableHeads="simplifyTableHeads(TABLE_CONFIG.T_OWNERS)"
          :control="CONTROL_CONFIG.C_SITEDETAIL"
          :data="ownerData"
          keyName="owner_ownerfolio_id"
          modalHeading="Přidat nového vlastníka" />
        <TablePreview linkTo="contracts"
          :tableHeads="simplifyTableHeads(TABLE_CONFIG.T_CONTRACTS)"
          :control="CONTROL_CONFIG.C_SITEDETAIL" :data="contractData" :formType="false" />
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Spinner from '@/components/generic/Spinner.vue';
import TablePreview from '@/components/generic/TablePreview.vue';
import { deepClone } from '@/utils';
import { TABLE_CONFIG, CONTROL_CONFIG } from '@/config';

export default {
  name: 'SiteDetail',
  components: {
    Spinner,
    TablePreview
  },
  data() {
    return {
      siteId: this.$route.params.site_id,
      showPage: false,
      ownerData: []
    };
  },
  computed: {
    ...mapGetters([
      'getSite',
      'getOwnerfolios',
      'getEasements',
      'getParcels',
      'getOwnersForTable',
      'getLoading',
      'getCategory',
      'getContractsForTable',
      'getVatpaymentBasic',
      'getContractState',
      'getCalclMethodBasic'
    ]),
    getSiteDetail() {
      return this.getSite(this.siteId);
    }
  },
  async created() {
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.TABLE_CONFIG = TABLE_CONFIG;
    await this.fetchSite(this.siteId);
    await this.fetchResources(['fetchCategories']);
    this.ownerData = this.getOwnersForTable.map((owners) => {
      owners.vatpayment_code = this.getVatpaymentBasic[owners.vatpayment_code];
      return owners;
    });
    this.contractData = this.getContractsForTable.map((contracts) => {
      contracts.calcmethod_code =
        this.getCalclMethodBasic[contracts.calcmethod_code];
      contracts.contractstate_code =
        this.getContractState[contracts.contractstate_code];
      return contracts;
    });
    this.showPage = true;
  },
  methods: {
    ...mapActions(['fetchResources', 'fetchSite', 'fetchCategories']),
    simplifyTableHeads(config) {
      return config.map((col) => {
        let clone = deepClone(col, ['type', 'emit', 'options'], 'exclude');
        clone.width > 100 ? (clone.width = 105) : clone.width;
        return clone;
      });
    }
  }
};
</script>

<style scoped>
.basic-info {
  margin: 30px 0 30px 0;
  position: relative;
}
.basic-header {
  color: #5889c4;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
}
.header-with-button {
  display: flex;
  align-items: center;
}
.info-section {
  display: grid;
  grid-template-columns: 150px 400px 150px 400px;
  grid-template-rows: repeat(2, auto);
  overflow: hidden;
  font-weight: bold;
}

.info-col {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  font-size: 14px;
}
.info-col span {
  margin: 0 10px 10px 0;
}
.detail-button {
  position: absolute;
  top: 87%;
  left: 1%;
}
.info-text {
  color: #00377b;
  font-weight: bold;
}
.preview-grid {
  display: grid;
  grid-template-rows: repeat(5, auto);
}
</style>
