export const easementZVBForm = {
  resource: 'easementZVB',
  schema: [
    {
      type: 'file',
      name: 'zvb',
      id: 'zvb',
      label: 'Nahrajte soubor s prostorovými informacemi',
      help: 'Přetažením nebo kliknutím vyberte jeden soubor ve formátu VFK',
      'help-class': 'file-form-input-help',
      disabled: true,
      '@change': 'change',
      '@file-removed': 'file-removed',
      '@file-upload-complete': 'file-upload-complete'
    }
  ]
};
