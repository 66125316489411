export const subjectsForm = {
  resource: 'subjects',
  schema: [
    {
      row: 1,
      type: 'text',
      name: 'name',
      label: 'Jméno',
      class: 'requiredlabel',
      validation: 'required'
    },
    {
      row: 1,
      type: 'text',
      name: 'organization_name',
      label: 'Organizace',
      class: 'requiredlabel',
      validation: 'required'
    },
    {
      row: 1,
      type: 'number',
      name: 'ico',
      label: 'IČO',
      validation: [['max', 999999999]],
      errorBehavior: 'value'
    },
    {
      row: 2,
      type: 'textarea',
      name: 'description',
      label: 'Popis',
      class: 'requiredlabel',
      validation: 'required'
    },
    {
      type: 'group',
      name: 'signatures',
      repeatable: true,
      label: 'Podpisy',
      class: 'relative',
      'add-label': ' ',
      'groupRepeatable-class': 'subjects-form-group',
      'groupAddMore-class': 'modal-form-add-button modal-form-add-button-subjects',
      children: [
        {
          row: 1,
          type: 'textarea',
          name: 'signature',
          validation: 'subjectSignature',
          label: 'Podpis'
        },
        {
          row: 2,
          type: 'text',
          name: 'procurations',
          label: 'Plná moc'
        },
        {
          row: 3,
          type: 'text',
          name: 'procurations_attachment',
          label: 'Příloha k plné moci'
        }
      ]
    }
  ],
  mask: {
    ico: val => {
      const validated = Number(val);
      return Number.isInteger(validated) ? validated : null;
    }
  }
};
