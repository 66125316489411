import axios from 'axios';
import router from '@/router';

const state = {
  parcels: []
};

const getters = {
  getParcels: state => state.parcels,
  getParcel: state => _id => state.parcels.find(({ id }) => id === _id)
};

const actions = {
  fetchParcels: async ({ commit }) => {
    try {
      const { data } = await axios.get(`/sites/${router.currentRoute.params.site_id}/parcels`);
      commit('setParcels', data.data);
    } catch (error) {
      console.error('Fetching parcels failed...');
      console.error(error);
    }
  },
  fetchParcel: async ({ commit, getters }, id) => {
    try {
      const { data } = await axios.get(
        `/sites/${router.currentRoute.params.site_id}/parcels/${id}`
      );
      if (!getters.getParcel(id)) {
        commit('addParcel', data);
      } else {
        commit('updateParcel', data);
      }
      return data;
    } catch (error) {
      console.error(`Fetching a parcel at id ${id} failed...`);
      console.error(error);
    }
  },
  addParcel: async ({ commit }, newParcel) => {
    const { data } = await axios.post(
      `/sites/${router.currentRoute.params.site_id}/parcels`,
      newParcel
    );
    commit('addParcel', data.data[0]);
  },
  updateParcel: async ({ commit }, updateParcel) => {
    const { id } = updateParcel;
    const { data } = await axios.patch(
      `/sites/${router.currentRoute.params.site_id}/parcels/${id}`,
      updateParcel
    );
    commit('updateParcel', data.data[0]);
  },
  removeParcel: async ({ commit }, ids) => {
    const { site_id } = router.currentRoute.params;
    await axios.delete(`/sites/${site_id}/parcels?ids=${ids}`);
    commit('removeParcel', ids);
  }
};

const mutations = {
  setParcels: (state, payload) => (state.parcels = payload),
  addParcel: (state, payload) => {
    const _payload = Array.isArray(payload) ? payload : [payload];
    state.parcels = [..._payload, ...state.parcels];
  },
  updateParcel: (state, payload) => {
    const idx = state.parcels.findIndex(({ id }) => id === payload.id);
    if (idx !== -1) {
      payload.checked = false;
      const updated = { ...state.parcels[idx], ...payload };
      state.parcels.splice(idx, 1, updated);
      state.parcels = [...state.parcels];
    }
  },
  removeParcel: (state, _id) => {
    let filtered;
    if (Array.isArray(_id)) {
      filtered = state.parcels.filter(({ id }) => !_id.includes(id));
    } else {
      filtered = state.parcels.filter(({ id }) => _id !== id);
    }
    state.parcels = filtered;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
