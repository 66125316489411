import axios from 'axios';
import { deepClone } from '@/utils';

const state = {
  subjects: []
};

const getters = {
  getSubjects: state => state.subjects,
  getSubject: state => _id => state.subjects.find(({ id }) => id === +_id),
  getSubjectsForTable: state => {
    return state.subjects.map(_subject => {
      const subject = deepClone(_subject);
      const { description, signatures } = subject;
      if (description.length > 60) {
        subject.description = description.slice(0, 60) + '...';
      }
      subject.procurations = signatures.map(({ procurations }) => procurations).join(', ');
      subject.signatures = signatures.map(({ signature }) => signature).join(', ');
      return subject;
    });
  }
};

const actions = {
  fetchSubjects: async ({ commit }) => {
    try {
      const {
        data: { data }
      } = await axios.get('/subjects');
      commit('setSubjects', data);
    } catch (error) {
      console.error('Failed to fetch subjects...');
      console.error(error);
    }
  },
  addSubject: async ({ commit }, subject) => {
    const { data } = await axios.post('/subjects', subject);
    commit('addSubject', data.data);
  },
  updateSubject: async ({ commit }, subject) => {
    const { data } = await axios.patch(`/subjects/${subject.id}`, subject);
    const [updatedSubject] = data.data;
    commit('updateSubject', updatedSubject);
  },
  removeSubject: async ({ commit }, ids) => {
    await axios.delete(`/subjects?ids=${ids}`);
    commit('removeSubject', ids);
  }
};

const mutations = {
  setSubjects: (state, payload) => {
    state.subjects = payload;
  },
  addSubject: (state, payload) => {
    const _payload = Array.isArray(payload) ? payload : [payload];
    state.subjects = [..._payload, ...state.subjects];
  },
  updateSubject: (state, payload) => {
    const idx = state.subjects.findIndex(({ id }) => id === payload.id);
    if (idx !== -1) {
      state.subjects.splice(idx, 1, payload);
      state.subjects = [...state.subjects];
    }
  },
  removeSubject: (state, _id) => {
    let filtered;
    if (Array.isArray(_id)) {
      filtered = state.subjects.filter(({ id }) => !_id.includes(id));
    } else {
      filtered = state.subjects.filter(({ id }) => id !== _id);
    }
    state.subjects = filtered;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
