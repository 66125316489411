export const TABLE_CONFIG = {
  T_SITES: [
    { value: 'all', name: 'Všechny sloupce', hide: true, showInFilter: true },
    {
      value: 'name',
      name: 'Stavba',
      emit: () => {
        return true;
      }
    },
    { value: 'category', name: 'Kategorie' },
    { value: 'parcels_owners', name: 'VB na PAR/vlastníci' },
    { value: 'contracts_count', name: 'Počet SM' },
    { value: 'created_date', name: 'Datum vzniku' }
  ],
  T_PARCELS: [
    { value: 'all', name: 'Všechny sloupce', hide: true, showInFilter: true },
    { value: 'cadaster_code', name: 'Katastrální kód', hide: true },
    { value: 'cadaster', name: 'Katastrální území' },
    {
      value: 'ownerfolio_id',
      name: 'LV',
      type: 'select',
      options: 'getCadasterOwnerfolios',
      width: 100
    },
    {
      value: 'full_parcelnumber',
      name: 'Parcela',
      emit: row => {
        return row.parcelid ? true : false;
      }
    },
    { value: 'length', name: 'Délka VB (m)', type: 'number' },
    { value: 'area', name: 'Plocha VB (m²)', type: 'number' },
    { value: 'surveysketch', name: 'VB GP' },
    { value: 'origin_code', name: 'Způsob vzniku' },
    { value: 'vfk_easement_date', name: 'Datum analýzy' }
  ],
  T_EASEMENTS: [
    { value: 'all', name: 'Všechny sloupce', hide: true, showInFilter: true },
    { value: 'surveysketch', name: 'Číslo GP' },
    { value: 'cadaster', name: 'Katastrální území' },
    { value: 'vfk_easement_date', name: 'Datum analýzy' },
    { value: 'vfk_spi_valid_date', name: 'Aktuálnost VFK SPI' },
    { value: 'parcel_owners', name: 'VB na PAR/Vlastníci' }
  ],
  T_OWNERS: [
    { value: 'all', name: 'Všechny sloupce', hide: true, showInFilter: true },
    { value: 'ownertype_code', name: 'Typ', width: 40 },
    { value: 'name', name: 'Vlastník', width: 100 },
    {
      value: 'ico',
      width: 90,
      name: 'RČ/IČO',
      emit: () => {
        return true;
      }
    },
    { value: 'ownerfolio_number', name: 'LV', width: 40 },
    { value: 'cadaster', name: 'Katastrální území', width: 100 },
    { value: 'ratio', name: 'Podíl LV', width: 50 },
    { value: 'vatpayment_code', name: 'DPH', type: 'select', options: 'getVatpayment', width: 95 },
    { value: 'address', name: 'Adresa' },
    { value: 'origin_code', name: 'Způsob vzniku', width: 90 },
    { value: 'owner_valid_date', name: 'Datum platnosti', width: 95 },
    { value: 'alternate_subject', name: 'Zástupce', hide: true, showInFilter: false }
  ],
  T_OWNERFOLIOS: [
    { value: 'all', name: 'Všechny sloupce', hide: true, showInFilter: true },
    { value: 'cadaster', name: 'Katastrální území' },
    { value: 'ownerfolio_number', name: 'Číslo LV' },
    { value: 'surveysketch_list', name: 'Číslo GP' },
    { value: 'created_date', name: 'Datum vzniku LV' }
  ],
  T_CONTRACTS: [
    { value: 'all', name: 'Všechny sloupce', hide: true, showInFilter: true, width: 0 },
    { value: 'name', name: 'Název smlouvy', type: 'text', width: 180 },
    { value: 'number', name: 'Číslo smlouvy', type: 'text', width: 80 },
    { value: 'price', name: 'Cena', type: 'number', pattern: 'd+', width: 70 },
    {
      value: 'calcmethod_code',
      name: 'Metoda výpočtu',
      type: 'select',
      options: 'calcMethods',
      width: 90
    },
    {
      value: 'equipment_name',
      name: 'Typ zařízení',
      type: 'autocomplete',
      charLimit: 2,
      data: 'equipments',
      width: 155
    },
    { value: 'opinion_number', name: 'Číslo posudku', type: 'text', width: 80 },
    { value: 'opinion_by', name: 'Zhotovitel posudku', type: 'text', width: 110 },
    {
      value: 'contractstate_code',
      name: 'Stav',
      type: 'select',
      options: 'getContractState',
      width: 160
    },

    { value: 'note', name: 'Poznámka', type: 'textarea', width: 180 }
  ],
  T_SUBJECT: [
    { value: 'all', name: 'Všechny sloupce', hide: true, showInFilter: true },
    { value: 'name', name: 'Jméno' },
    { value: 'organization_name', name: 'Organizace' },
    { value: 'description', name: 'Popis' },
    { value: 'ico', name: 'IČO', width: 40 },
    { value: 'signatures', name: 'Podpis' },
    { value: 'procurations', name: 'Plná moc' }
  ],
  T_CATEGORIES: [
    { value: 'all', name: 'Všechny sloupce', hide: true, showInFilter: true },
    { value: 'name', name: 'Jméno' }
  ],
  T_EQUIPMENT: [
    { value: 'all', name: 'Všechny sloupce', hide: true, showInFilter: true },
    { value: 'name', name: 'Jméno' }
  ],
  T_SETTINGCSV: [
    { value: 'ownerfolio_number', name: 'Číslo LV', width: 80 },
    { value: 'parcel_number', name: 'Dotčené parcely na LV', width: 100 },
    { value: 'area', name: 'Plocha VB [m2]', width: 100 },
    { value: 'length', name: 'Délka (trasa) sítě [m]', width: 100 },
    { value: 'empty', name: '', width: 100 }
  ],
  T_SIMPLECSV: [
    {
      value: 'numtype',
      name: 'Typ pozemku',
      type: 'select',
      options: 'getNumType',
      width: 80
    },
    { value: 'ownerfolio_number', name: 'Číslo LV', width: 100 },
    { value: 'parcel_number', name: 'Dotčené parcely na LV', width: 100 },
    {
      value: 'evidencetype',
      name: 'Evidence',
      type: 'select',
      options: 'getEvidenceCodes',
      width: 50
    },
    { value: 'parcelpart', name: 'Díl', type: 'text', width: 100 },
    { value: 'originalcadaster', name: 'Původní k.ú', type: 'number', width: 100 }
  ],
  T_AREACSV: [
    {
      value: 'numtype',
      name: 'Typ pozemku',
      type: 'select',
      options: 'getNumType',
      width: 120
    },
    { value: 'ownerfolio_number', name: 'Číslo LV', width: 60 },
    { value: 'parcel_number', name: 'Parcely ', width: 60 },
    {
      value: 'evidencetype',
      name: 'Evidence',
      type: 'select',
      options: 'getEvidenceCodes',
      width: 65
    },
    { value: 'parcelpart', name: 'Díl', type: 'text', width: 70 },
    { value: 'originalcadaster', name: 'Původní k.ú', type: 'number', width: 80 },
    { value: 'area', name: 'Plocha VB [m2]', width: 100 },
    { value: 'length', name: 'Délka (trasa) sítě [m]', width: 130 }
  ],
  T_TEMPLATE: [
    { value: 'all', name: 'Všechny sloupce', hide: true, showInFilter: true },
    {
      value: 'doctype_code',
      name: 'Typ šablony'
    },
    {
      value: 'name',
      name: 'Název šablony'
    }
  ]
};

export const CONTROL_CONFIG = {
  C_FORMULATE_TABLE: {
    editable: false,
    deleteRow: false,
    selectable: false,
    notFixedHeader: true,
    secondary: []
  },
  C_CONTRACTS: {
    deleteRow: false,
    selectable: true,
    editable: true,
    secondary: [
      {
        icon: 'contract',
        emit: 'contract',
        tooltip: 'Generuj smlouvy',
        show: () => {
          return true;
        }
      },
      {
        icon: 'deposit',
        emit: 'deposit',
        tooltip: 'Odeslat návrh na vklad',
        show: () => {
          return true;
        }
      },
      {
        icon: 'analysis',
        emit: 'openDeposit',
        tooltip: 'Otevřít návrh na vklad',
        show: row => {
          return row.lastentryid === null ? false : true;
        }
      }
    ]
  },
  C_SUBJECTS: { deleteRow: true, selectable: true, editable: true, secondary: [] },
  C_SITES: { deleteRow: true, selectable: true, secondary: [] },
  C_OWNERS: {
    deleteRow: false,
    editable: true,
    selectable: true,
    secondary: [
      {
        icon: 'trash',
        tooltip: 'Odstranit vybraná LV/vlastníky',
        className: 'trash-button',
        emit: 'removeItem',
        show: () => {
          return true;
        }
      }
    ]
  },
  C_PARCELS: { deleteRow: true, selectable: true, editable: true, secondary: [] },
  C_OWNERFOLIOS: {
    deleteRow: true,
    selectable: true,
    secondary: []
  },
  C_CATEGORIES: {
    deleteRow: true,
    selectable: true,
    secondary: []
  },
  C_EQUIPMENTS: { deleteRow: true, selectable: true, secondary: [] },
  C_EASEMENTS: {
    deleteRow: true,
    selectable: true,
    secondary: [
      {
        icon: 'analysis',
        tooltip: 'Analyzovat Věcné břemeno',
        emit: 'analysis',
        show: () => {
          return true;
        }
      }
    ]
  },
  C_SITEDETAIL: {
    notFixedHeader: true,
    deleteRow: false,
    selectable: false,
    editable: false,
    secondary: []
  },
  C_TEMPLATES: { deleteRow: false, selectable: true, secondary: [] }
};
