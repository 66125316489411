import axios from 'axios';
import { deepClone } from '@/utils';

const state = {
  cl_calcmethod: {},
  cl_doctype: {},
  cl_numtype: {},
  cl_origin: {},
  cl_ownertype: {},
  cl_contractstate: {},
  cl_vatpayment: {},
  cl_subjecttype: {},
  cl_evidencetype: {},
  cl_sex: {}
};

const getters = {
  getCalclMethod: ({ cl_calcmethod }) => {
    return Object.keys(cl_calcmethod).map(code => {
      return { value: code, label: cl_calcmethod[code] };
    });
  },
  getCalclMethodBasic: ({ cl_calcmethod }) => cl_calcmethod,
  getNumType: ({ cl_numtype }) => cl_numtype,
  getOrigin: ({ cl_origin }) => cl_origin,
  getOwnerType: ({ cl_ownertype }) => {
    return ['N/A', 'OFO', 'OPO', 'SJM', 'MCP'].map(code => {
      const option = { value: code, label: cl_ownertype[code] };
      if (code === 'N/A') option.disabled = true;
      return option;
    });
  },
  getContractState: ({ cl_contractstate }) => cl_contractstate,
  getVatpaymentBasic: ({ cl_vatpayment }) => cl_vatpayment,
  getVatpayment: ({ cl_vatpayment }) => {
    return ['N/A', 'NP', 'YP', 'CN', 'CP'].map(code => {
      const option = { value: code, label: cl_vatpayment[code] };
      if (code === 'N/A') option.selected = true;
      return option;
    });
  },
  getVatpaymentForTable: ({ cl_vatpayment }) =>
    Object.keys(cl_vatpayment).reduce((past, value) => {
      past = [...past, { value: cl_vatpayment[value], label: cl_vatpayment[value] }];
      return past;
    }, []),
  getSubjectType: ({ cl_subjecttype }) => cl_subjecttype,
  getEvidence: ({ cl_evidencetype }) => cl_evidencetype,
  getDocTypeContract: ({ cl_doctype }) => deepClone(cl_doctype, ['ECONTR'], 'include'),
  getDocTypeOther: ({ cl_doctype }) => deepClone(cl_doctype, ['ECONTR'], 'exclude'),
  getDocType: ({ cl_doctype }) => cl_doctype,
  getSex: ({ cl_sex }) => cl_sex,
  getSexAsOptions: ({ cl_sex }) => {
    let keys = Object.keys(cl_sex);
    return keys.map(key => {
      return {
        value: key,
        label: cl_sex[key].charAt(0).toUpperCase() + cl_sex[key].slice(1)
      };
    });
  },
  getEvidenceCodes: ({ cl_evidencetype }) =>
    Object.keys(cl_evidencetype).reduce((past, codes) => {
      past[codes] = codes;
      return past;
    }, {})
};

const actions = {
  fetchCodeList: async ({ commit }) => {
    try {
      const { data } = await axios.get('/codelists');
      commit('setCodeList', data);
    } catch (error) {
      console.error('Failed to fetch codelists...');
      console.error(error);
    }
  }
};

const mutations = {
  setCodeList: (state, payload) => {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
