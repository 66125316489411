<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz, Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Generic Footer layout-type component
:dependencies: none
:version: 3
:updated: 2021-07-19
-->

<template>
  <footer class="footer" :style="footerStyle">
    <div>
      <slot name="left" />
    </div>
    <div>
      <slot v-if="hasCenterSlot" name="center" />
      <span v-else class="footer-text">
        Aplikace ContractSupport je produkt portálu
        <a href="https://mawis.eu" target="_blank" :style="linkStyle">Mawis.eu</a>
        © {{new Date().getFullYear()}}
        <a href="https://hrdlicka.cz/" target="_blank" :style="linkStyle">HRDLIČKA spol. s r.o.</a>
      </span>
    </div>
    <div>
      <slot v-if="hasRightSlot" name="right" />
      <span v-else class="footer-text">
        <a :href="versionLink" target="_blank" id="custom-link" :style="linkStyle">Verze {{getVersion}}</a>
      </span>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Footer',
  props: {
    color: {
      type: String,
      default: '#eff5fb'
    },
    sidePadding: {
      type: [String, Number],
      default: 20
    },
    fontSize: {
      type: [String, Number],
      default: 12
    },
    fontColor: {
      type: String,
      default: 'var(--main-color)'
    },
    versionLink: {
      type: String,
      default: `${process.env.BASE_URL}cfg/changelog.html`
    }
  },
  computed: {
    ...mapGetters(['getVersion']),
    footerStyle() {
      const backgroundColor = this.color;
      const color = this.fontColor;
      const fontSize = +this.fontSize + 'px';
      const padding = `0 ${+this.sidePadding}px`;
      return { backgroundColor, color, fontSize, padding };
    },
    linkStyle() {
      const color = this.fontColor;
      const textDecoration = 'none';
      return { color, textDecoration };
    },
    hasCenterSlot() {
      return !!this.$slots.center;
    },
    hasRightSlot() {
      return !!this.$slots.right;
    }
  }
};
</script>

<style scoped>
.footer {
  height: 20px;
  display: flex;
  justify-content: space-between;
}
.footer-text a:hover {
  filter: brightness(105%);
}
</style>
