export const categoriesForm = {
  resource: 'categories',
  schema: [
    {
      row: 1,
      type: 'text',
      name: 'name',
      label: 'Jméno',
      class: 'requiredlabel',
      validation: 'required'
    }
  ]
};
