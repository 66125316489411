import axios from 'axios';
import { deepClone } from '@/utils';

const state = {
  templates: []
};

const getters = {
  getTemplates: ({ templates }, getters, { codelists }) => {
    return templates.map(_template => {
      const template = deepClone(_template);
      template.doctype_code = codelists.cl_doctype[_template.doctype_code];
      return template;
    });
  },
  getTemplate: ({ templates }) => _id => templates.find(({ id }) => id === +_id),
  getTemplatesByType: ({ templates }) => code => {
    return templates.filter(({ doctype_code }) => doctype_code === code);
  }
};

const actions = {
  fetchTemplates: async ({ commit }) => {
    try {
      const { data } = await axios.get('/templates');
      commit('setTemplates', data.data);
    } catch (error) {
      console.error('Failed to fetch templates...');
    }
  },
  fetchTemplate: async ({ commit, getters }, id) => {
    try {
      const { data } = await axios.get(`/templates/${id}`);
      if (!getters.getTemplate(id)) {
        commit('addTemplate', data);
      } else {
        commit('updateTemplate', data);
      }
    } catch (error) {
      console.error('Failed to fetch template...');
    }
  },
  updateTemplate: async ({ commit }, template) => {
    const { data } = await axios.patch(`/templates/${template.id}`, template);
    const [updatedTemplate] = data.data;
    commit('updateTemplate', updatedTemplate);
  }
};

const mutations = {
  setTemplates: (state, payload) => {
    state.templates = payload;
  },
  addTemplate: (state, payload) => {
    const _payload = Array.isArray(payload) ? payload : [payload];
    state.templates = [..._payload, ...state.templates];
  },
  updateTemplate: (state, payload) => {
    const idx = state.templates.findIndex(({ id }) => id === payload.id);
    if (idx !== -1) {
      state.templates.splice(idx, 1, payload);
      state.templates = [...state.templates];
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
