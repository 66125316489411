<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz, Michael Košatka
:contact: jakub.mraz@hrdlicka.cz, michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Form for manipulating 'owners' resource. Designated to be rendered as a ModalForm
:dependencies: VueFormulate, vuex
:version: 4
:updated: 2021-09-14
-->

<template>
  <div>
    <div class="modal-form-disabled" v-if="!getOwnerfolios.length">
      <div>Nelze přidávat vlastníky bez dostupných LV.</div>
      <div class="modal-form-disabled-link" @click="goToOwnerfolios">Přejít na <strong>Listy Vlastnictví</strong></div>
    </div>
    <FormulateForm v-else
      ref="form"
      class="modal-form"
      :values="initialData"
      @submit="handleSubmit($event)">
      <div class="modal-form-fields">
        <div class="modal-form-row">
          <FormulateInput
            type="text"
            name="name"
            label="Vlastník"
            class="requiredlabel"
            validation="required"
            v-bind="getClasses()" />
        </div>
        <div class="modal-form-row">
          <FormulateInput
            type="select"
            name="ownertype_code"
            label="Typ vlastnictví"
            :options="getOwnerType"
            :disabled="mode === 'edit'"
            class="requiredlabel"
            validation="not:N/A"
            :validation-messages="{not: 'Pole Typ vlastnictví je povinné'}"
            v-bind="getClasses()"
            @change="updatePersonsFieldsetByType($event.target.value)" />
          <FormulateInput
            type="select"
            name="vatpayment_code"
            label="Plátce/neplátce DPH"
            :options="getVatpayment"
            v-bind="getClasses()" />
        </div>
        <FormulateInput
          type="group"
          name="ownerfolios"
          :repeatable="true"
          :minimum="1"
          :limit="getOwnerfolios.length"
          :label="getOwnerfoliosSectionLabel"
          class="modal-form-border-top relative"
          groupRepeatable-class="ownerfolios-group-row"
          groupRepeatableRemove-class="ownerfolios-group-remove-button"
          add-label=" "
          groupAddMore-class="modal-form-add-button modal-form-add-button-owners">
          <div class="modal-form-row-ownerfolio">
            <FormulateInput
              type="select"
              name="ownerfolio_id"
              :input-class="({ hasErrors }) => hasErrors ? 'modal-form-input-element-error' : 'modal-form-input-element'"
              errors-class="modal-form-error-box"
              error-class="modal-form-error-item"
              outer-class="modal-form-row-ownerfolio-wrapper"
              label-class="modal-form-row-ownerfolio-label"
              validation="required"
              label="LV"
              :options="getOwnerfoliosOptions" />
            <FormulateInput
              type="text"
              name="ratio"
              placeholder="např. 1/2"
              :input-class="({ hasErrors }) => hasErrors ? 'modal-form-input-element-error' : 'modal-form-input-element'"
              errors-class="modal-form-error-box"
              error-class="modal-form-error-item"
              outer-class="modal-form-row-ownerfolio-wrapper"
              label-class="modal-form-row-ownerfolio-label"
              label="Podíl"
              :validation="[['matches', /^[1]$|^\d+\/\d+$/]]"
              :validation-messages="{
            matches: 'Neplatná hodnota.'
          }" />
          </div>
        </FormulateInput>
        <div v-if="ownerfoliosDuplicated" class="modal-form-ownerfolios-error">Odstraňte duplicitní LV</div>
        <fieldset v-if="selectedOwnerType !== 'N/A' || mode === 'edit'"
          class="modal-form-fieldset modal-form-border-top">
          <div v-for="idx in personsRepeats" :key="`persons[${idx}]`">
            <div class="modal-form-row">
              <FormulateInput type="text"
                v-model="persons[idx - 1].signature"
                label="Jméno/podpis"
                class="requiredlabel"
                validation="required"
                v-bind="getClasses()" />
              <FormulateInput type="text"
                v-model="persons[idx - 1].address"
                label="Adresa"
                class="requiredlabel"
                validation="required"
                v-bind="getClasses()" />
            </div>
            <div class="modal-form-row">
              <FormulateInput type="text"
                v-if="selectedOwnerType ==='OPO'"
                v-model="persons[idx - 1].ico"
                label="IČO"
                class="requiredlabel"
                v-bind="getClasses()"
                placeholder="01212121"
                :validation="[['matches', /^\d{4,8}$/], ['required']]"
                :validation-messages="{matches: 'Neplatné IČO.'}" />
              <FormulateInput type="text"
                v-else
                v-model="persons[idx - 1].pin"
                label="RČ"
                v-bind="getClasses()"
                class="requiredlabel"
                placeholder="900101/0001"
                :validation="[['matches', /^\d{10}$|^\d{6}\/\d{4}$/], ['required']]"
                :validation-messages="{matches: 'Neplatné RČ.'}" />
              <FormulateInput type="text"
                v-model="persons[idx - 1].alternate"
                label="Zástupce"
                v-bind="getClasses()" />
            </div>
            <div class="modal-form-row">
              <FormulateInput
                type="date"
                v-if="selectedOwnerType !=='OPO'"
                v-model="persons[idx - 1].birthdate"
                label="Datum narození"
                v-bind="getClasses()"
                class="requiredlabel"
                validation="required"
                :validation-messages="{matches: 'Neplatné datum.'}" />
              <FormulateInput
                type="select"
                v-if="selectedOwnerType !=='OPO'"
                v-model="persons[idx - 1].sex_code"
                label="Pohlaví"
                :options="getSexAsOptions"
                v-bind="getClasses()"
                class="requiredlabel"
                validation="required" />
            </div>
            <div class="modal-form-row">
              <FormulateInput type="text"
                v-if="selectedOwnerType ==='OPO'"
                v-model="persons[idx - 1].dic"
                label="DIČ"
                v-bind="getClasses()"
                placeholder="01212121" />
            </div>
          </div>
        </fieldset>
        <div class="modal-form-buttons">
          <ModalFormButton color="cancel" label="Zrušit" @action="closeModal" invert />
          <ModalFormButton color="info" label="Uložit" btnType="submit" />
        </div>
      </div>
    </FormulateForm>
    <div class="modal-form-disabled-buttons" v-if="!getOwnerfolios.length">
      <ModalFormButton color="cancel" label="Zrušit" @action="closeModal" invert />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { customButton } from '@/components/generic/Button.vue';
import { modalFormClasses, deepClone, reformat, ratio } from '@/utils';

const ModalFormButton = customButton(
  {
    fontSize: 14,
    height: 32,
    width: 90,
    textAlign: 'center'
  },
  'ModalFormButton'
);

export default {
  name: 'OwnersForm',
  components: {
    ModalFormButton
  },
  data: () => {
    return {
      personsRepeats: 1,
      persons: [{}, {}],
      selectedOwnerType: 'N/A',
      ownerfoliosDuplicated: false
    };
  },
  props: {
    mode: String,
    resourceId: Number
  },
  created() {
    this.initialData = undefined;
    if (this.mode === 'edit') {
      const initialData = this.getOwner(this.resourceId);
      this.initialData = deepClone(initialData, [
        'id',
        'name',
        'ownerfolios',
        'ownertype_code',
        'vatpayment_code'
      ]);
      this.initialData.ownerfolios = this.initialData.ownerfolios.map(
        (ownerfolio) => {
          ownerfolio.ratio = ratio.fromParts(ownerfolio);
          return ownerfolio;
        }
      );
      this.persons = deepClone(initialData.persons);
      this.selectedOwnerType = this.initialData.ownertype_code;
      this.updatePersonsFieldsetByType(this.initialData.ownertype_code);
    }
  },
  computed: {
    ...mapGetters([
      'getOwner',
      'getOwnerType',
      'getVatpayment',
      'getOwnerfolios',
      'getOwnerfoliosCadaster',
      'getSexAsOptions'
    ]),
    getOwnerfoliosOptions() {
      const options = this.getOwnerfoliosCadaster.map((ownerfolio) => {
        const { id: value, cadaster, ownerfolio_number: number } = ownerfolio;
        return { label: `${number} | ${cadaster}`, value };
      });
      options[0].selected = true;
      return options;
    },
    getOwnerfoliosSectionLabel() {
      return 'List Vlastnictví';
    }
  },
  methods: {
    ...mapActions(['addOwner', 'updateOwner', 'clearRows']),
    updatePersonsFieldsetByType(value) {
      this.selectedOwnerType = value;
      this.personsRepeats = (value === 'SJM') | (value === 'MCP') ? 2 : 1;
    },
    validateOwnerfolios(ownerfolios) {
      const ids = ownerfolios.map(({ ownerfolio_id: id }) => +id);
      return new Set(ids).size === ids.length;
    },
    parseOwnerfolios(ownerfolios) {
      return ownerfolios.map(({ ownerfolio_id, ratio: _ratio }) => {
        return {
          ownerfolio_id: +ownerfolio_id,
          ...ratio.parse(_ratio)
        };
      });
    },
    parsePersons(persons) {
      return persons.reduce((parsed, person) => {
        if (Object.keys(person).length) {
          parsed.push(
            reformat(person, {
              ico: (val) => (val ? +val : null),
              dic: (val) => (val ? val : null)
            })
          );
        }
        return parsed;
      }, []);
    },
    parseFormDataForSubmission(fieldValues) {
      if (!this.validateOwnerfolios(fieldValues.ownerfolios)) {
        this.ownerfoliosDuplicated = true;
        return false;
      }
      const formData = deepClone(fieldValues, [
        'name',
        'ownertype_code',
        'vatpayment_code',
        'ownerfolios',
        'id'
      ]);
      formData.ownerfolios = this.parseOwnerfolios(formData.ownerfolios);
      formData.persons = this.parsePersons(this.persons);
      return formData;
    },
    handleSubmit(fieldValues) {
      const formData = this.parseFormDataForSubmission(fieldValues);
      if (!formData) return;
      if (this.mode === 'new') {
        this.addOwner(formData);
      } else {
        this.updateOwner(formData);
        this.clearRows();
      }
      this.closeModal();
    },
    getClasses() {
      return modalFormClasses();
    },
    closeModal() {
      const { closeModal } = this.$root.$children[0].$refs.modal;
      closeModal();
    },
    goToOwnerfolios() {
      this.closeModal();
      this.$router.push({ name: 'ownerfolios' });
    }
  }
};
</script>

<style>
@import '../../styles/modal-form.css';
</style>
