<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.type}` "
    :data-type="context.type">
    <select class="ruleValue" type="range" required :name="name" v-model.number="context.model">
      <option
        :value="subject.id"
        v-for="subject in getSubjects"
        :key="subject.id">{{subject.name}}</option>
    </select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FormulateSubject',
  data: () => {
    return {
      edit: false,
      buttonText: 'Přidat pravidlo'
    };
  },
  props: {
    context: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  },

  computed: mapGetters(['getSubjects']),
  methods: {
    addRule(rule) {
      if (this.edit) {
        /* use index here */
        this.$emit('addRule', { rule: rule, edit: this.edit });
      } else {
        this.$emit('addRule', { rule: rule, edit: this.edit });
      }
    }
  }
};
</script>
