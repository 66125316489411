<template>
  <ModalForm resource="subjects" :mode="mode" :resourceId="resourceId" :schema="schema" :mask="mask" />
</template>

<script>
import ModalForm from '../generic/ModalForm.vue';
import { subjectsForm } from '@/config/formSchemas';
import { VFSchema } from '@/utils';

export default {
  name: 'SubjectsForm',
  components: { ModalForm },
  created() {
    this.schema = VFSchema(subjectsForm.schema)
      .bindClasses()
      .toFormRows()
      .get();
    this.mask = subjectsForm.mask;
  },
  props: {
    mode: String,
    resourceId: Number
  }
};
</script>

<style scoped>
@import '../../styles/modal-form.css';
</style>
