<template>
  <section>
    <h1 class="project-header">
      <span class="project-header-text">Vítejte v projektu</span> "{{activeProject.name}}"
    </h1>
    <div class="project-description-block">
      <img :src="activeProject.image_url" class="project-image" />
      <p class="project-description">{{activeProject.description}}</p>
    </div>
    <div v-if="projects.length > 1">
      <h2 class="projects-grid-header">Dostupné projekty:</h2>
      <ul class="projects-grid">
        <ProjectCard v-for="project in projects"
          :key="project.id"
          :project="project"
          :activeProjectId="activeProject.id" />
      </ul>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectCard from '../generic/ProjectCard.vue';

export default {
  name: 'Projects',
  components: {
    ProjectCard
  },
  computed: mapGetters({
    projects: 'getUserProjects',
    activeProject: 'getActiveProject'
  })
};
</script>

<style scoped>
.project-header {
  padding: 10px 10px 30px 10px;
}
.project-header-text {
  color: var(--main-color);
}
.project-description-block {
  display: grid;
  grid-template-columns: repeat(2, auto);
  padding: 0 0 20px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.project-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.project-description {
  padding-left: 15px;
}
.projects-grid-header {
  padding: 30px 10px 15px 10px;
  color: var(--main-color);
  font-size: 16px;
}
.projects-grid {
  padding: 0 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  list-style: none;
}
</style>