<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mrát, Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: 'Table-class' component for displaying 'equipments' resource data in a table
:dependencies: Table, Spinner, vuex
:version: 2
:updated: 2021-07-29
-->

<template>
  <div>
    <Spinner v-if="getLoading" />
    <section v-if="!getLoading && getEquipments.length">
      <Table
        ref="Table"
        :data="getEquipments"
        :pageSize="100"
        selectedClass="table-info"
        :tableHeads="TABLE_CONFIG.T_EQUIPMENT"
        :control="CONTROL_CONFIG.C_EQUIPMENTS"
        @deleteRow="removeEquipment($event)" />
    </section>
    <p v-if="!getLoading && !getEquipments.length" class="no_records">
      Žádné položky k zobrazení
    </p>
  </div>
</template>

<script>
import Spinner from '@/components/generic/Spinner.vue';
import Table from '@/components/generic/Table.vue';
import { TABLE_CONFIG, CONTROL_CONFIG } from '@/config';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Equipments',
  components: { Spinner, Table },
  created() {
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.TABLE_CONFIG = TABLE_CONFIG;
    this.fetchResources(['fetchEquipments']);
  },
  computed: mapGetters(['getEquipments', 'getLoading']),
  methods: mapActions(['fetchResources', 'removeEquipment'])
};
</script>