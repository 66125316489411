<template>
  <section class="spinner-playground">
    <h1 class="support-header">Kontaktní informace: </h1>
    <ul class="support-list">

      <li> <b> email: </b>
        <a href="mailto:cosup@mawis.eu">cosup@mawis.eu</a>
      </li>
      <li> <b> linka zákaznického centra: </b>
        +420 251 618 458
      </li>
    </ul>
  </section>
</template>

<script>
import Spinner from '@/components/generic/Spinner.vue';

export default {
  name: 'Support',
  components: {
    Spinner
  }
};
</script>

<style scoped>
.spinner-playground {
  position: relative;
  display: flex;
  flex-direction: column;
}
.support-list {
  list-style-type: none;
  font-size: 20px;
  margin: 10px;
}
.support-list li {
  margin: 10px;
}

.support-header {
  margin-top: 10px;
}
</style>
