<!-- 
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz, Michael Košatka
:contact: jakub.mraz@hrdlicka.cz, michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Breadcrumbs navigation generic component
:dependencies: vue-router, vuex, ResourceManager.util
:version: 3.0
:updated: 2021-10-14
 -->

<template>
  <section class="breadcrumbs">
    <nav v-if="$route.path !== '/' && !showCrumbsOnRootRoute">
      <span v-for="(crumb,index) in breadcrumbs.crumbs" :key="index">
        <span
          :class="{'crumb': true, 'clickable': crumb.link && !crumb.disabled }"
          @click="navigate(crumb)">
          {{crumb.label}}
        </span>
        <span v-if="index < breadcrumbs.crumbs.length - 1"
          class="delimiter">
          {{symbol}}
        </span>
      </span>
    </nav>
    <h1 class="heading">{{printHeading(breadcrumbs.heading)}}</h1>
  </section>
</template>

<script>
import { ResourceManager as rm } from '@/utils';

export default {
  name: 'Breadcrumbs',
  data: () => {
    return {
      breadcrumbs: {}
    };
  },
  props: {
    symbol: {
      type: String,
      default: '/'
    },
    routerMode: {
      type: Boolean,
      default: true
    },
    showCrumbsOnRootRoute: {
      type: Boolean,
      default: false
    },
    rootCrumbs: {
      type: Function
    },
    leafCrumbs: {
      type: Function
    },
    idParseMap: {
      type: Object,
      default: () => {
        return {
          site_id: { resourceName: 'sites' },
          easement_id: { resourceName: 'easements', nameKey: 'surveysketch' },
          template_id: { resourceName: 'templates' }
        };
      }
    }
  },
  async created() {
    this.breadcrumbs = await this.getCrumbs(this.$route);
  },
  watch: {
    async $route() {
      this.breadcrumbs = await this.getCrumbs(this.$route);
    }
  },
  methods: {
    async getResourceNameFromParamId(param, id) {
      const { resourceName, nameKey } = this.idParseMap[param];
      const resourceMethods = rm(resourceName);
      const { getOne } = resourceMethods.getters();
      const { fetchOne } = resourceMethods.actions();

      const getter = (id) => this.$store.getters[getOne](id);
      const fetcher = (id) => this.$store.dispatch(fetchOne, id);

      if (!getter(id)) await fetcher(id);
      return getter(id)[nameKey || 'name'];
    },
    splitPath(path) {
      const segments = path.split('/').filter((segment) => segment);
      return segments.slice(0, segments.length - 1);
    },
    parsePathParams(params) {
      return new Promise(async (resolve) => {
        const result = {};
        for (let p of Object.keys(params)) {
          result[p] = await this.getResourceNameFromParamId(p, params[p]);
        }
        resolve(result);
      });
    },
    getSegmentLabel(segment, params) {
      const { routes } = this.$router.options;
      const route = routes.find(({ name }) => name === segment);
      return route ? route.meta.heading : params[segment.slice(1)];
    },
    getSegmentLink(segment, segments) {
      const idx = segments.findIndex((_s) => _s === segment);
      const _path = '/' + segments.slice(0, idx + 1).join('/');
      const route = this.$router.options.routes.find(({ path }) => {
        return path === _path;
      });
      return route ? { link: route.name, disabled: route.meta.disabled } : {};
    },
    crumbsFromSegments(segments, params) {
      return segments.map((segment) => {
        const label = this.getSegmentLabel(segment, params);
        const { link, disabled } = this.getSegmentLink(segment, segments);
        return { label, link, disabled };
      });
    },
    async routerCrumbs(route) {
      const [{ path }] = route.matched;
      const segments = this.splitPath(path);
      const params = await this.parsePathParams(route.params);
      return this.crumbsFromSegments(segments, params);
    },
    async getCrumbs(route) {
      const routerCrbs = this.routerMode ? await this.routerCrumbs(route) : [];
      const rootCrumbs = this.rootCrumbs ? this.rootCrumbs(this.$router) : [];
      const leafCrumbs = this.leafCrumbs ? this.leafCrumbs(this.$router) : [];

      const { heading } = route.meta;
      return {
        heading,
        crumbs: [...rootCrumbs, ...routerCrbs, ...leafCrumbs]
      };
    },
    printHeading(heading) {
      return typeof heading == 'function' ? heading() : heading;
    },
    navigate({ link, disabled }) {
      if (link && !disabled) {
        this.$router.push({ name: link });
      }
    }
  }
};
</script>

<style scoped>
.breadcrumbs {
  color: var(--main-color);
  font-size: 14px;
}
.crumb {
  cursor: default;
}
.clickable {
  cursor: pointer;
  transition: 0.3s;
}
.clickable:hover {
  text-decoration: underline;
  font-weight: bold;
}
.delimiter {
  margin: 0 8px;
}
.heading {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 700;
}
</style>