<template>
  <section>
    <Spinner v-if="getLoading" />
    <div v-if="!getLoading && getRules.length">
      <div class="item" v-for="(rule,index) in getRules" :key="rule.d">
        <h2 class="heading">{{index+1}}. podmínka</h2>
        <hr class="hr">
        <p class=" info"><label class="label">Název: </label> {{rule.name}}</p>
        <p class="info"><label class="label">Text: </label> {{rule.description}}</p>
      </div>
    </div>
    <p v-if="!getLoading && !getRules.length" class="no_records">
      Žádné položky k zobrazení.
    </p>
  </section>
</template>

<script>
import Spinner from '@/components/generic/Spinner.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Rules',
  components: {
    Spinner
  },
  created() {
    this.fetchResources(['fetchRules']);
  },
  computed: mapGetters(['getRules', 'getLoading']),
  methods: mapActions(['fetchResources'])
};
</script>


<style scoped>
.item {
  padding: 15px;
}
.heading {
  color: #5889c4;
}
.hr {
  border: 1px solid #c2d1e8;
}
.info {
  margin: 20px 10px;
}

.label {
  color: var(--main-color);
  font-weight: bold;
}
</style>