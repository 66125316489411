<template>
  <section>
    <Spinner v-if="getLoading" />
    <FormulateForm v-else class="site-form" @submit="handleSubmit" id="main_form">
      <FormulateInput
        label="Číslo stavby:"
        name="name"
        v-model="site.name"
        class="siteStep requiredlabel"
        type="text"
        required />
      <FormulateInput
        label="Název Stavby"
        name="longname"
        class="siteStep"
        v-model="site.longname"
        type="text" />
      <FormulateInput
        label="Kategorie"
        name="category_id"
        :options="getCategories.map(({id, name}) => ({value: id, label: name}))"
        class="siteStepTemplate requiredlabel"
        v-model.number="site.category_id"
        type="select"
        required />
      <!-- typ šablony - příloh -->
      <div class="siteGroup">
        <div class="siteGroup-header">
          <label>{{templatesSectionLabel}}</label>
          <Button
            class="siteGroup-header-button"
            width="100"
            height="40"
            @action="addAttachment"
            textAlign="center"
            label="Přidat" invert
            type="button" />
        </div>
        <div class="group-labels-contract">
          <label class="required">Typ šablony (Smlouvy)</label>
          <label class="required">Název šablony</label>
        </div>
        <div class="site-contract-row required-row">
          <FormulateInput
            input-class="contract-type-select"
            type="select"
            required
            value="ECONTR"
            :options="getDocTypeContract" />
          <FormulateInput
            type="select"
            input-class="contract-name-select"
            v-model="contemplate.id"
            :options="getTemplatesByType('ECONTR').map(({id, name}) => ({value: id, label: name}))"
            required />
        </div>
        <div class="site-contract-row" v-for="(attachment, index) in attachments" :key="attachment._key">
          <FormulateInput
            type="select"
            input-class="contract-type-select"
            name="doctype_code"
            v-model="attachments[index].doctype_code"
            :options="getDocTypeOther"
            @input="updateAttachment($event, index, 'doctype_code')"
            required />
          <FormulateInput
            type="select"
            input-class="contract-name-select"
            name="id"
            v-model="attachments[index].id"
            :options="loadAttachmentOptions(index)"
            :disabled="!loadAttachmentOptions(index).length"
            @input="updateAttachment($event, index, 'id')"
            required />
          <Button
            width="100"
            height="40"
            color="#F5222D" invert
            margin="0px 0px 0px 10px"
            textAlign="center"
            label="Odebrat"
            type="button"
            @action="attachments.splice(index, 1)" />
        </div>
      </div>
    </FormulateForm>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { deepClone, reformat } from '@/utils';
import Button from '@/components/generic/Button.vue';
import Spinner from '@/components/generic/Spinner.vue';

export default {
  name: 'SitesForm',
  components: {
    Button,
    Spinner
  },
  data: () => {
    return {
      site: { name: '', category_id: null },
      contemplate: { id: null, name: '', doctype_code: 'ECONTR' },
      attachments: []
    };
  },
  async created() {
    await this.fetchResources([
      'fetchSites',
      'fetchCategories',
      'fetchTemplates'
    ]);

    const { meta, params } = this.$route;
    this.mode = meta.mode;
    this.site_id = params.site_id;

    if (this.mode === 'edit') {
      await this.fetchSite(this.site_id);
      const siteData = this.getSite(this.site_id);
      this.preloadValues(siteData);
    }
  },
  computed: {
    ...mapGetters([
      'getLoading',
      'getTemplatesByType',
      'getTemplates',
      'getSite',
      'getCategories',
      'getDocTypeContract',
      'getDocTypeOther'
    ]),
    templatesSectionLabel() {
      return this.attachments.length ? 'Šablony smlouvy' : 'Šablona smlouvy';
    }
  },
  methods: {
    ...mapActions(['fetchSite', 'fetchResources', 'addSite', 'updateSite']),
    // contracts and attachments related methods
    addAttachment() {
      const newAttachment = { _key: Math.floor(Math.random() * 1000000) };
      this.attachments.push(newAttachment);
    },
    loadAttachmentOptions(index) {
      const { doctype_code } = this.attachments[index];
      const options = this.getTemplatesByType(doctype_code).map(
        ({ id, name }) => ({
          value: id,
          label: name
        })
      );
      return options;
    },
    updateAttachment(event, index, key) {
      this.attachments[index][key] = event;
      this.attachments = deepClone(this.attachments);
    },
    // general siteForm related methods
    preloadValues(siteData) {
      const { name, category_id, contemplates, longname } = siteData;
      this.site = { name, category_id, longname };
      if (contemplates) {
        this.contemplate = contemplates.filter(
          (contemplate) => contemplate.doctype_code === 'ECONTR'
        )[0] || {
          id: null,
          name: '',
          doctype_code: 'ECONTR'
        };
        this.attachments =
          contemplates.filter(
            (contemplate) => contemplate.doctype_code !== 'ECONTR'
          ) || [];
      }
    },
    async handleSubmit() {
      const formData = { ...this.site };
      if (this.site_id) {
        formData.id = +this.site_id;
      }
      formData.contemplates = [
        this.contemplate,
        ...this.attachments.map((att) => {
          delete att._key;
          return att;
        })
      ];
      const formattedFormData = reformat(formData, {
        id: (val) => +val,
        category_id: (val) => (val ? +val : null),
        contemplates: (val) => val.map((v) => (v = Number(v.id)))
      });
      if (this.mode === 'new') {
        this.site_id = await this.addSite(formattedFormData);
      } else {
        const formattedFormDataEdit = reformat(formData, {
          id: (val) => +val,
          contemplates: (val) => val.map((v) => (v = Number(v.id)))
        });
        await this.updateSite(formattedFormDataEdit);
      }
      this.$router.push({
        name: 'siteDetail',
        params: { site_id: this.site_id }
      });
    }
  }
};
</script>

<style scoped>
@import '../../styles/new-site.css';
</style>