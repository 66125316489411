<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz, Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: 'Table-class' component for displaying 'categories' resource data in a table
:dependencies: Spinner, Table, vuex
:version: 3
:updated: 2021-07-29
-->

<template>
  <div>
    <Spinner v-if="getLoading" />
    <section v-if="!getLoading && getCategories.length">
      <Table
        ref="Table"
        :data="getCategories"
        :pageSize="100"
        selectedClass="table-info"
        :tableHeads="TABLE_CONFIG.T_CATEGORIES"
        :control="CONTROL_CONFIG.C_CATEGORIES"
        @deleteRow="removeCategory($event)" />
    </section>
    <p v-if="!getLoading && !getCategories.length" class="no_records">
      Žádné položky k zobrazení
    </p>
  </div>
</template>

<script>
import Spinner from '@/components/generic/Spinner.vue';
import Table from '@/components/generic/Table.vue';
import { TABLE_CONFIG, CONTROL_CONFIG } from '@/config';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CategoriesTable',
  components: { Spinner, Table },
  created() {
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.TABLE_CONFIG = TABLE_CONFIG;
    this.fetchResources(['fetchCategories']);
  },
  computed: mapGetters(['getCategories', 'getLoading']),
  methods: mapActions(['fetchResources', 'removeCategory'])
};
</script>