export const easementsForm = {
  resource: 'easements',
  schema: [
    {
      row: 1,
      type: 'text',
      id: 'case_number',
      name: 'case_number',
      label: 'Číslo jednací k.ú:',
      class: 'requiredlabel easement-step-main-label',
      validation: 'required',
      disabled: true
    },
    {
      row: 1,
      type: 'date',
      id: 'case_date',
      name: 'case_date',
      label: 'Datum přijetí k.ú:',
      class: 'requiredlabel easement-step-main-label',
      validation: 'required',
      disabled: true
    },
    {
      row: 2,
      type: 'text',
      id: 'surveysketch',
      name: 'surveysketch',
      label: 'Číslo GP VB:',
      class: 'requiredlabel easement-step-main-label',
      validation: 'required',
      disabled: true
    },
    {
      row: 2,
      type: 'autocomplete',
      customClass: 'relative',
      id: 'sketchcontractor',
      name: 'sketchcontractor',
      label: 'Zhotovitel GP:',
      class: 'easement-step-main-label',
      charLimit: 2,
      '@pickedSuggest': '@pickedSuggestContract',
      disabled: true,
      $get: { data: 'getSketchContractorSuggest' }
    }
  ]
};
