<!-- 
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz
:contact: jakub.mraz@hrdlicka.cz
:copyright: HSRO
:summary: Custom Spinner made out of styled divs
:version: 2.0
:updated: 2021-09-29
 -->

<template>
  <div class="spinner-wrapper" :style="spinnerWrapper">
    <div class="spinner-label" :style="spinnerLabel">{{label.text}}</div>
    <div class="spinner-container" :class="`animation ${animation}-container`" :style="spinnerContainer">
      <div
        class="top-sqaure logo"
        :class="`animation ${animation} ${animation}-top`"
        :style="{...logoStyle, ...getTopStyle}">
      </div>
      <div
        class="mid-square logo"
        :class="`animation ${animation} ${animation}-mid`"
        :style="{...logoStyle, ...getMiddleStyle}">
      </div>
      <div
        class="btm-square logo"
        :class="`animation ${animation} ${animation}-btm`"
        :style="{...logoStyle, ...getBottomStyle}">
      </div>
    </div>
  </div>
</template>

<script>
const animationTiming = {
  smooth: (square, speed, reverse) => {
    const delayCoeff = {
      top: reverse ? 0.25 : 0.75,
      mid: 0.5,
      btm: reverse ? 0.75 : 0.25
    };
    return {
      animationDuration: 0.5 / speed + 's',
      animationDelay: `${delayCoeff[square] / speed}s`
    };
  },
  firm: (square, speed) => {
    return {
      animationDuration: `${8 / speed}s`
    };
  },
  twist: (square, speed) => {
    return {
      animationDuration: `${8 / speed}s`
    };
  }
};

const hexToRgb = (color) => {
  const rgb = [];
  for (let i = 1; i < 7; i += 2) {
    const num = color.slice(i, i + 2);
    rgb.push(parseInt(num, 16));
  }
  return rgb;
};

const hexAndOpacityToRgba = (color, opacity) => {
  if (color.split('')[0] !== '#') return color;
  const rgb = hexToRgb(color);
  return `rgba(${rgb.join(',')},${opacity})`;
};

export default {
  name: 'Spinner',
  props: {
    bgColor: {
      type: String,
      default: 'var(--main-color)'
    },
    bgOpacity: {
      type: [String, Number],
      default: 1
    },
    padding: {
      type: [String, Number],
      default: 10
    },
    logo: {
      type: Object,
      default: () => {
        return {
          color: {
            top: '#fff',
            middle: '#fff',
            bottom: '#fff'
          },
          border: {
            color: 'var(--main-color)',
            width: 2
          }
        };
      }
    },
    label: {
      type: Object,
      default: () => {
        return {
          placement: 'bottom',
          offset: 10,
          text: 'Načítám data...'
        };
      }
    },
    animation: {
      type: String,
      default: 'smooth'
    },
    reverseAnimation: {
      type: Boolean,
      default: false
    },
    size: {
      type: [String, Number],
      default: 40
    },
    speed: {
      type: [String, Number],
      default: 1.8
    },
    marginTop: {
      type: [String, Number],
      default: 20
    }
  },
  computed: {
    spinnerWrapper() {
      const flexParams = {
        top: 'column',
        bottom: 'column-reverse',
        left: 'row',
        right: 'row-reverse'
      };
      return {
        flexDirection: flexParams[this.label.placement],
        marginTop: `${this.marginTop}%`
      };
    },
    spinnerLabel() {
      const margin = ['top', 'bottom'].includes(this.label.placement)
        ? `${this.label.offset}px 0`
        : `0 ${this.label.offset}px`;
      return { margin };
    },
    spinnerContainer() {
      return {
        backgroundColor: hexAndOpacityToRgba(this.bgColor, this.bgOpacity),
        width: this.size + 'px',
        height: this.size + 'px',
        padding: this.padding + 'px',
        ...animationTiming[this.animation](null, this.speed)
      };
    },
    logoStyle() {
      return {
        bottom: this.padding + 'px',
        right: this.padding + 'px'
      };
    },
    getTopStyle() {
      return {
        backgroundColor: this.logo.color.top,
        width: this.size + 'px',
        height: this.size + 'px',
        ...animationTiming[this.animation](
          'top',
          this.speed,
          this.reverseAnimation
        )
      };
    },
    getMiddleStyle() {
      return {
        backgroundColor: this.logo.color.middle,
        borderLeft: `${this.logo.border.width}px solid ${this.logo.border.color}`,
        borderTop: `${this.logo.border.width}px solid ${this.logo.border.color}`,
        width: (this.size / 3) * 2 + 'px',
        height: (this.size / 3) * 2 + 'px',
        ...animationTiming[this.animation](
          'mid',
          this.speed,
          this.reverseAnimation
        )
      };
    },
    getBottomStyle() {
      return {
        backgroundColor: this.logo.color.bottom,
        borderLeft: `${this.logo.border.width}px solid ${this.logo.border.color}`,
        borderTop: `${this.logo.border.width}px solid ${this.logo.border.color}`,
        width: this.size / 3 + 'px',
        height: this.size / 3 + 'px',
        ...animationTiming[this.animation](
          'btm',
          this.speed,
          this.reverseAnimation
        )
      };
    }
  }
};
</script>

<style scoped>
@import '../../styles/spinner-animations.css';
.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner-label {
  text-align: center;
}
.spinner-container {
  position: relative;
}
.logo {
  position: absolute;
}
.animation {
  animation-iteration-count: infinite;
}
</style>