<!-- 
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Jakub Mráz, Michael Košatka
:contact: jakub.mraz@hrdlicka.cz, michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Generic menu component for router navigation
:version: 3.0
:dependencies: "Button.vue"
:updated: 2021-08-11
 -->

<template>
  <nav class="menu">
    <ul class="main-menu">
      <li v-for="menuItem in config" :key="menuItem.routerPath">
        <div :class="{'menu-item-active': parentItem === menuItem.routerPath && pathName !== 'projects'}">
          <MenuButton
            :linkTo="{ name: menuItem.routerPath }"
            @click.native="parentItem = menuItem.routerPath"
            :label="menuItem.label"
            :frontIcon="menuItem.icon" />
        </div>
        <ul class="sub-menu" v-if="showSubmenu(menuItem)">
          <li v-for="subItem in menuItem.subSteps" :key="subItem.routerPath">
            <MenuButton
              :linkTo="{ name: subItem.routerPath }"
              :label="subItem.label"
              :frontIcon="subItem.icon"
              :fontWeight="pathName === subItem.routerPath ? 'bold' : 'normal'" />
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import { customButton } from './Button.vue';
import { mapGetters } from 'vuex';

const MenuButton = customButton(
  {
    color: 'transparent',
    fontColor: '#00377B',
    height: 60,
    fontSize: 14
  },
  'MenuButton'
);

export default {
  name: 'Menu',
  components: { MenuButton },
  data: () => {
    return {
      pathName: '',
      parentItem: ''
    };
  },
  props: {
    config: {
      required: true,
      type: Array
    }
  },
  computed: mapGetters(['getActiveProject']),
  watch: {
    $route(to) {
      this.pathName = to.name;
    }
  },
  created() {
    this.parentItem = this.$route.path.split('/')[1];
    this.pathName = this.$route.name;
  },
  methods: {
    showSubmenu({ subSteps, hidePath, routerPath }) {
      if (!subSteps.length) return false;
      if (routerPath !== this.parentItem) return false;
      if (hidePath && hidePath.includes(this.pathName)) return false;
      return true;
    }
  }
};
</script>

<style scoped>
.menu {
  background-color: var(--sidebar-color);
  font-size: 14px;
  min-width: 125px;
}

.main-menu {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
}

.sub-menu {
  list-style-type: none;
  padding-left: 30px;
}

.menu-item-span {
  width: 100%;
  background-color: rgba(255, 0, 0, 0.5);
}

.menu-item-active {
  background-color: var(--secondary-color);
}
</style>
