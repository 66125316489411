<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Form for manipulating 'ownerfolios' resource. Designated to be rendered as a ModalForm
:dependencies: ModalForm, VueFormulate, modalFormClasses utility
:version: 1
:updated: 2021-07-30
-->

<template>
  <ModalForm :mode="mode" resource="ownerfolios" :resourceId="resourceId" :schema="schema" :mask="mask"
    :iface="iface" />
</template>

<script>
import ModalForm from '../generic/ModalForm.vue';
import { ownerfoliosForm } from '@/config/formSchemas';
import { VFSchema, deepClone } from '@/utils';

export default {
  name: 'OwnerfoliosForm',
  components: { ModalForm },
  created() {
    this.schema = VFSchema(ownerfoliosForm.schema)
      .bindGetters()
      .bindClasses()
      .toFormRows()
      .get();
    this.mask = ownerfoliosForm.mask;
  },
  props: {
    mode: String,
    resourceId: Number
  },
  methods: {
    iface(data) {
      const formData = deepClone(data, ['ownerfolio_number']);
      formData.cadaster_code = Number(data.cadaster.match(/\d+/)[0]);
      return formData;
    }
  }
};
</script>

<style>
@import '../../styles/modal-form.css';
</style>