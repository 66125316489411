import Vue from 'vue';
import Vuex from 'vuex';

import basic from './basic';
import auth from './services/auth';
import analysis from './services/analysis';
import fetch from './services/fetch';
import rules from './resources/rules';
import templates from './resources/templates';
import sites from './resources/sites';
import easements from './resources/easements';
import ownerfolios from './resources/ownerfolios';
import owners from './resources/owners';
import equipments from './resources/equipments';
import parcels from './resources/parcels';
import cadastres from './resources/cadastres';
import subjects from './resources/subjects';
import codelists from './resources/codelists';
import contracts from './resources/contracts';
import categories from './resources/categories';
import table from './services/table';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    basic,
    analysis,
    fetch,
    rules,
    templates,
    sites,
    easements,
    ownerfolios,
    contracts,
    owners,
    equipments,
    subjects,
    parcels,
    cadastres,
    codelists,
    categories,
    table
  }
});
