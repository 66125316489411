import axios from 'axios';

const state = {
  cadastres: [],
  parcelsRUIAN: []
};

const getters = {
  getCadastres: state => state.cadastres,
  getParcelsRUIAN: state => state.parcelsRUIAN
};

const actions = {
  async fetchCadastres({ commit }) {
    try {
      const { data } = await axios.get('/cadastres');
      commit('setCadastres', data.data);
    } catch (error) {
      console.error('loading of cadastres failed...');
      console.error(error);
    }
  },
  async fetchParcelsRUIAN({ commit }, id) {
    try {
      const { data } = await axios.get(`/cadastres/${id}/parcels`);
      commit('setParcelsRUIAN', data.data);
    } catch (error) {
      console.error('loading of parcelsRUIAN failed...');
      console.error(error);
    }
  }
};

const mutations = {
  setCadastres: (state, cadastres) => {
    state.cadastres = cadastres.map(({ cadaster_code, cadaster, digital }) => {
      return {
        value: cadaster_code,
        label: cadaster,
        digital
      };
    });
  },
  setParcelsRUIAN: (state, parcelsRUIAN) => {
    state.parcelsRUIAN = parcelsRUIAN.map(({ parcelid, parcelnumber }) => {
      return { value: parcelid, label: parcelnumber };
    });
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
