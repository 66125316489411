import { CONTROL_ALERT } from '@/config';

const state = {
  version: '0.8.6',
  lang: 'cs',
  languages: ['cs', 'ua', 'pl'],
  // loading
  loadingContract: false,
  waitingForDelete: false,
  // ---------
  showCorrectHtml: false
};

const getters = {
  // This stores selected rows
  getVersion: state => state.version,
  getLang: state => state.lang,
  getLanguages: state => state.languages,
  // loading
  getLoadingContract: state => state.loadingContract,
  setWaitingForDelete: state => state.showCorrectHtml,
  /* ------- */
  getPopupHtml: state => state.showCorrectHtml
};

const actions = {
  setLang({ commit, state }, lang) {
    if (!state.languages.includes(lang)) throw new Error(`Language '${lang}' is not supported.`);
    commit('setLang', lang);
  },
  changePopupHtml({ commit }, showCorrectHtml) {
    commit('setPopupHtml', showCorrectHtml);
  },
  updatingAlert({ commit }) {
    const { openModal } = this.$app.$root.$children[0].$refs.modal;
    openModal(CONTROL_ALERT).then(result => {
      if (result.confirm) {
        this.$app.$root.$children[0].$refs.main.$refs.views.$refs.Table.cleanUpdatableStyles();
        commit('clearRows');
      }
      if (result.deny) {
        this.$app.$root.$children[0].$refs.main.$refs.views.$refs.Table.$emit('editCanceled');
      }
    });
  }
};

const mutations = {
  setLang: (state, payload) => (state.lang = payload),
  // loading
  setLoadingContract: (state, boolean) => (state.loadingContract = boolean),
  setWaitingForDelete: (state, boolean) => (state.waitingForDelete = boolean),
  // -------------------
  setPopupHtml: (state, showCorrectHtml) => (state.showCorrectHtml = showCorrectHtml)
};

export default {
  state,
  actions,
  getters,
  mutations
};
