import axios from 'axios';
import router from '@/router';

const state = {
  ownerfolios: []
};

const getters = {
  getOwnerfolios: state => state.ownerfolios,
  getOwnerfolio: state => _id => state.ownerfolios.find(({ id }) => id === +_id),
  getOwnerfoliosCadaster: ({ ownerfolios }) => {
    return ownerfolios.map(({ cadaster, cadaster_code, id, ownerfolio_number }) => {
      return { cadaster, cadaster_code, id, ownerfolio_number };
    });
  },
  getCadasterOwnerfolios: ({ ownerfolios }) => {
    const grouped = ownerfolios.reduce((grouped, { cadaster_code, id, ownerfolio_number }) => {
      if (!grouped[cadaster_code]) {
        grouped[cadaster_code] = [];
      }
      grouped[cadaster_code].push({ ownerfolio_id: id, ownerfolio_number });
      return grouped;
    }, {});
    return Object.keys(grouped).map(cadaster => {
      return { cadaster_code: +cadaster, ownerfolios: grouped[cadaster] };
    });
  },
  getOwnerfoliosByCadaster: ({ ownerfolios }) => code => {
    return ownerfolios.reduce((pastValue, { cadaster_code, id, ownerfolio_number }) => {
      if (cadaster_code === code) pastValue = [...pastValue, { id, ownerfolio_number }];
      return pastValue;
    }, []);
  },
  getOwnerfoliosByCadasterOptions: (state, { getOwnerfoliosByCadaster }) => code => {
    const options = getOwnerfoliosByCadaster(code).map(({ id, ownerfolio_number }) => {
      return { value: id, label: ownerfolio_number };
    });
    return options.length
      ? options
      : [{ value: null, label: 'Žádná LV nejsou k dispozici.', selected: true, disabled: true }];
  }
};

const actions = {
  fetchOwnerfolios: async ({ commit }) => {
    try {
      const { data } = await axios.get(`/sites/${router.currentRoute.params.site_id}/ownerfolios`);
      commit('setOwnerfolios', data.data);
    } catch (error) {
      console.error('Failed to fetch ownerfolios');
      console.error(error);
    }
  },
  addOwnerfolio: async ({ commit }, newOwnerfolio) => {
    const { data } = await axios.post(
      `/sites/${router.currentRoute.params.site_id}/ownerfolios`,
      newOwnerfolio
    );
    commit('addOwnerfolio', data.data);
  },
  updateOwnerfolio: async ({ commit }, ownerfolio) => {
    const { site_id } = router.currentRoute.params;
    const { id } = ownerfolio;
    const { data } = await axios.patch(`/sites/${site_id}/ownerfolios/${id}`, ownerfolio);
    const [updateOwnerfolio] = data.data;
    commit('updateOwnerfolio', updateOwnerfolio);
  },
  removeOwnerfolio: async ({ commit }, ids) => {
    const { site_id } = router.currentRoute.params;
    await axios.delete(`/sites/${site_id}/ownerfolios?ids=${ids}`);
    commit('removeOwnerfolio', ids);
  }
};

const mutations = {
  setOwnerfolios: (state, payload) => {
    state.ownerfolios = payload;
  },
  addOwnerfolio: (state, payload) => {
    const _payload = Array.isArray(payload) ? payload : [payload];
    state.ownerfolios = [..._payload, ...state.ownerfolios];
  },
  updateOwnerfolio: (state, payload) => {
    const idx = state.ownerfolios.findIndex(({ id }) => id === payload.id);
    if (idx !== -1) {
      state.ownerfolios.splice(idx, 1, payload);
      state.ownerfolios = [...state.ownerfolios];
    }
  },
  removeOwnerfolio: (state, _id) => {
    let filtered;
    if (Array.isArray(_id)) {
      filtered = state.ownerfolios.filter(({ id }) => !_id.includes(id));
    } else {
      filtered = state.ownerfolios.filter(({ id }) => _id !== id);
    }
    state.ownerfolios = filtered;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
