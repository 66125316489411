import axios from 'axios';
import { deepClone } from '@/utils';

const state = {
  sites: []
};

const getters = {
  getSites: state => state.sites,
  getSite: state => _id => state.sites.find(({ id }) => id === +_id),
  getSitesForTable: (state, getters, rootState, rootGetters) => {
    return state.sites.map(_site => {
      const site = deepClone(_site, ['category_id'], 'exclude');
      const category = rootGetters.getCategory(_site.category_id);
      site.category = category ? category.name : 'N/A';
      return site;
    });
  },
  getSiteTemplates: state => {
    return state.sites.map(({ id, name }) => {
      return { value: id, label: name };
    });
  }
};

const actions = {
  fetchSites: async ({ commit }) => {
    try {
      const { data } = await axios.get(`/sites`);
      commit('setSites', data.data);
    } catch (error) {
      console.error('Fetching sites failed...');
      console.error(error);
    }
  },
  fetchSite: async ({ commit, getters }, id) => {
    try {
      const { data } = await axios.get(`/sites/${id}`);
      if (!getters.getSite(id)) {
        commit('addSite', data);
      } else {
        commit('updateSite', data);
      }
    } catch (error) {
      console.error(`Fetching site at id ${id} failed...`);
      console.error(error);
    }
  },
  addSite: async ({ commit }, newSite) => {
    const { data } = await axios.post('/sites', newSite);
    commit('addSite', data.data);
    return data.data[0].id;
  },
  updateSite: async ({ commit }, updateSite) => {
    const { id } = updateSite;
    const { data } = await axios.patch(`/sites/${id}`, updateSite);
    commit('updateSite', data);
  },
  removeSite: async ({ commit }, ids) => {
    await axios.delete(`/sites?ids=${ids}`);
    commit('removeSite', ids);
  }
};

const mutations = {
  setSites: (state, payload) => (state.sites = payload),
  addSite: (state, payload) => {
    const _payload = Array.isArray(payload) ? payload : [payload];
    state.sites = [...state.sites, ..._payload];
  },
  updateSite: (state, payload) => {
    const idx = state.sites.findIndex(({ id }) => id === payload.id);
    if (idx !== -1) {
      const updated = { ...state.sites[idx], ...payload };
      state.sites.splice(idx, 1, updated);
      state.sites = [...state.sites];
    }
  },
  removeSite: (state, _id) => {
    let filtered;
    if (Array.isArray(_id)) {
      filtered = state.sites.filter(({ id }) => !_id.includes(id));
    } else {
      filtered = state.sites.filter(({ id }) => _id !== id);
    }
    state.sites = filtered;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
