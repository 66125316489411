<!--
Vue 2.5 Component

:organization: HRDLIČKA SPOL. S.R.O
:author: Michael Košatka
:contact: michael.kosatka@hrdlicka.cz
:copyright: HSRO
:summary: Menu/Settings style item for Header parent component
:dependencies: Icon
:version: 2
:updated: 2021-09-12
-->

<template>
  <li class="header-item">
    <Icon v-if="icon" class="icon" v-bind="iconProps" />
    <div v-else class="avatar" :style="avatarStyle">
      <img alt="Ikona" v-if="avatar" :src="avatar" :style="avatarStyle" />
      <span v-if="!avatar && placeholder" :style="placeholderStyle">{{formatPlaceholder}}</span>
    </div>
    <div v-if="label" class="label" :style="labelStyle">
      {{label}}
    </div>
    <transition name="fade">
      <ul class="dialog-menu" v-if="active">
        <slot></slot>
      </ul>
    </transition>
  </li>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'HeaderItem',
  components: {
    Icon
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: [String, Object]
    },
    iconSize: {
      type: [String, Number],
      default: 24
    },
    label: {
      type: String
    },
    labelSize: {
      type: [String, Number],
      default: 16
    },
    labelColor: {
      type: String,
      default: '#fff'
    },
    avatar: {
      type: String
    },
    placeholder: {
      type: String
    },
    placeholderBg: {
      type: String,
      default: '#fff'
    },
    placeholderColor: {
      type: String,
      default: '#000'
    }
  },
  computed: {
    iconProps() {
      if (typeof this.icon === 'string')
        return { type: this.icon, size: this.iconSize };
      return { ...this.icon, size: this.iconSize };
    },
    avatarStyle() {
      const width = this.iconSize + 'px';
      const height = this.iconSize + 'px';
      const backgroundColor = this.placeholderBg;
      return { backgroundColor, width, height };
    },
    labelStyle() {
      const fontSize = this.labelSize + 'px';
      const color = this.labelColor;
      return { fontSize, color };
    },
    placeholderStyle() {
      const size = +this.iconSize;
      const offset = this.placeholder.length > 1 ? size / 2 : size / 3;
      const fontSize = size - offset + 'px';
      const color = this.placeholderColor;
      return { color, fontSize };
    },
    formatPlaceholder() {
      const split = this.placeholder.split(' ');
      if (split.length === 1) {
        return this.placeholder.slice(0, 2).toUpperCase();
      } else {
        return (
          split[0].slice(0, 1).toUpperCase() +
          split[1].slice(0, 1).toUpperCase()
        );
      }
    }
  }
};
</script>

<style scoped>
.header-item {
  position: relative;
  margin: 0 20px;
  display: flex;
  align-items: center;
  z-index: 2;
}

.icon,
.avatar {
  cursor: pointer;
  z-index: 3;
  transition: 0.4s;
}

.icon:hover,
.avatar:hover {
  transform: scale(1.2);
}

.avatar {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.label {
  margin-left: 10px;
  cursor: pointer;
}

.dialog-menu {
  position: absolute;
  top: 36px;
  left: -4px;
  padding: 0;
  width: max-content;
  list-style: none;
  background-color: var(--sidebar-color);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0 4px 4px 4px;
  z-index: 2;
}

.dialog-menu:before {
  content: '';
  position: absolute;
  top: -40px;
  left: 0px;
  width: 32px;
  height: 40px;
  background-color: var(--sidebar-color);
  box-shadow: -6px -6px 6px -4px rgba(0, 0, 0, 0.5),
    6px -6px 6px -4px rgba(0, 0, 0, 0.5);
  border-radius: 16px 16px 0px 0px;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>