<template>
  <div>
    <Spinner v-if="getLoading" />
    <section v-if="!getLoading && getSitesForTable.length">
      <Table
        ref="Table"
        :data="getSitesForTable"
        :pageSize="100"
        selectionMode="single"
        :tableHeads="TABLE_CONFIG.T_SITES"
        :control="CONTROL_CONFIG.C_SITES"
        @deleteRow="removeSite($event)"
        @name="openSiteDetail($event)" />
    </section>
    <p v-if=" !getLoading && !getSitesForTable.length" class="no_records">
      Žádné položky k zobrazení
    </p>
  </div>
</template>

<script>
import Spinner from '../generic/Spinner.vue';
import Table from '../generic/Table.vue';
import { mapGetters, mapActions } from 'vuex';
import { TABLE_CONFIG, CONTROL_CONFIG } from '@/config';

export default {
  name: 'SitesTable',
  components: { Spinner, Table },
  created() {
    this.CONTROL_CONFIG = CONTROL_CONFIG;
    this.TABLE_CONFIG = TABLE_CONFIG;
    this.fetchResources(['fetchSites', 'fetchCategories']);
  },
  computed: mapGetters(['getSitesForTable', 'getLoading']),
  methods: {
    ...mapActions(['fetchResources', 'removeSite']),
    openSiteDetail(selectedRow) {
      this.$router.push(`/sites/${selectedRow.id}`);
    }
  }
};
</script>

<style scoped>
@import '../../styles/tooltip.css';
</style>
