import axios from 'axios';
import router from '@/router';

const state = {
  easements: [],
  vfkResponseZVB: false,
  vfkResponseSPI: false,
  vfkResponsePDF: false,
  vfkResponseXLS: false,
  loadReport: false
};

const getters = {
  getEasements: state => state.easements,
  getEasement: state => _id => state.easements.find(({ id }) => id === +_id),
  getSurveysketches: ({ easements }) => {
    return easements.map(({ surveysketch, id }) => {
      return { value: id, label: surveysketch };
    });
  },
  getSketchContractorSuggest: ({ easements }) =>
    easements
      .reduce((pastValue, value) => {
        if (value.sketchcontractor !== null) {
          value = {
            value: value.sketchcontractor,
            label: value.sketchcontractor
          };
          pastValue.push(value);
          return pastValue;
        } else {
          return pastValue;
        }
      }, [])
      .filter((value, index, self) => index === self.findIndex(t => t.value === value.value)),
  getVfkResponseZVB: state => state.vfkResponseZVB,
  getVfkResponseSPI: state => state.vfkResponseSPI,
  getVfkResponsePDF: state => state.vfkResponsePDF,
  getVfkResponseXLS: state => state.vfkResponseXLS,
  getLoadReport: state => state.loadReport
};

const actions = {
  fetchEasements: async ({ commit }) => {
    const { site_id } = router.currentRoute.params;
    try {
      const { data } = await axios.get(`/sites/${site_id}/easements`);
      commit('setEasements', data.data);
    } catch (error) {
      console.error('Fetching owners failed...');
      console.error(error);
    }
  },
  fetchEasement: async ({ commit, getters }, id) => {
    const { site_id } = router.currentRoute.params;
    try {
      const { data } = await axios.get(`/sites/${site_id}/easements/${id}`);
      if (!getters.getEasement(id)) {
        commit('addEasement', data);
      } else {
        commit('updateEasement', data);
      }
    } catch (error) {
      console.error(`Fetching an easement at id ${id} failed...`);
      console.error(error);
    }
  },
  addEasement: async ({ commit }, newEasement) => {
    const { site_id } = router.currentRoute.params;
    const { data } = await axios.post(`/sites/${site_id}/easements`, newEasement);
    commit('addEasement', data);
  },
  updateEasement: async ({ commit }, updatedEasement) => {
    const { site_id } = router.currentRoute.params;
    const { id } = updatedEasement;
    const { data } = await axios.patch(`/sites/${site_id}/easements/${id}`, updatedEasement);
    commit('updateEasement', data);
  },
  removeEasement: async ({ commit }, ids) => {
    const { site_id } = router.currentRoute.params;
    await axios.delete(`/sites/${site_id}/easements?ids=${ids}`);
    commit('removeEasement', ids);
  }
};

const mutations = {
  setEasements: (state, payload) => (state.easements = payload),
  addEasement: (state, payload) => {
    const _payload = Array.isArray(payload) ? payload : [payload];
    state.easements = [...state.easements, ..._payload];
  },
  updateEasement: (state, payload) => {
    const idx = state.easements.findIndex(({ id }) => id === payload.id);
    if (idx !== -1) {
      const updated = { ...state.easements[idx], ...payload };
      state.easements.splice(idx, 1, updated);
      state.easements = [...state.easements];
    }
  },
  removeEasement: (state, _id) => {
    let filtered;
    if (Array.isArray(_id)) {
      filtered = state.easements.filter(({ id }) => !_id.includes(id));
    } else {
      filtered = state.easements.filter(({ id }) => _id !== id);
    }
    state.easements = filtered;
  },
  setVfkResponseSPI: (state, boolean) => (state.vfkResponseSPI = boolean),
  setVfkResponseZVB: (state, boolean) => (state.vfkResponseZVB = boolean),
  setVfkResponsePDF: (state, boolean) => (state.vfkResponsePDF = boolean),
  setVfkResponseXLS: (state, boolean) => (state.vfkResponseXLS = boolean),
  setLoadResources: (state, boolean) => (state.vfkResponseXLS = boolean)
};

export default {
  state,
  actions,
  getters,
  mutations
};
