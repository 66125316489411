import axios from 'axios';

const state = {
  equipments: []
};

const getters = {
  getEquipments: state => state.equipments,
  getEquipment: state => _id => state.equipments.find(({ id }) => id === _id),
  getEquipmentsOptions: ({ equipments }) => {
    return equipments.map(({ id, name }) => {
      return { value: id, label: name };
    });
  }
};

const actions = {
  fetchEquipments: async ({ commit }) => {
    try {
      const { data } = await axios.get('/equipments');
      commit('setEquipments', data.data);
    } catch (error) {
      console.error('Failed to fetch equipments');
      console.error(error);
    }
  },
  addEquipment: async ({ commit }, equipment) => {
    const { data } = await axios.post('/equipments', equipment);
    commit('addEquipment', data.data);
  },
  updateEquipment: async ({ commit }, equipment) => {
    const { data } = await axios.patch(`/equipments/${equipment.id}`, equipment);
    const [updatedEquipment] = data.data;
    commit('updateEquipment', updatedEquipment);
  },
  removeEquipment: async ({ commit }, ids) => {
    await axios.delete(`/equipments?ids=${ids}`);
    commit('removeEquipment', ids);
  }
};

const mutations = {
  setEquipments: (state, payload) => {
    state.equipments = payload;
  },
  addEquipment: (state, payload) => {
    const _payload = Array.isArray(payload) ? payload : [payload];
    state.equipments = [..._payload, ...state.equipments];
  },
  updateEquipment: (state, payload) => {
    const idx = state.equipments.findIndex(({ id }) => id === payload.id);
    if (idx !== -1) {
      state.equipments.splice(idx, 1, payload);
      state.equipments = [...state.equipments];
    }
  },
  removeEquipment: (state, _id) => {
    let filtered;
    if (Array.isArray(_id)) {
      filtered = state.equipments.filter(({ id }) => !_id.includes(id));
    } else {
      filtered = state.equipments.filter(({ id }) => id !== _id);
    }
    state.equipments = filtered;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
