<template>
  <div>
    <HSROSpinner v-if="getLoading" />
    <section v-if="!getLoading" class="new-easement">
      <div class="new-easement-column">
        <FormulateForm class="easement-step-cadaster" name="form" ref="form" :schema="schemaCadaster"
          v-model="initialFormDataCadaster" @pickedSuggestCadaster="pickCadaster($event)"
          @inputCleared="clearCadaster($event)">
          <slot>
            <span class="help-text" v-if="$router.currentRoute.name !== 'editEasement'">
              Katastry s digitalizací jsou označeny
              <Icon type="screen" class="icon-digital" style="vertical-align: -3px" size="13" />
              a bez digitalizace
              <Icon type="map" class="icon-digital" style="vertical-align: -3px" size="13" />
            </span>
          </slot>
          <Icon v-if="newEasement.cadaster" class="cadaster-input-icon" :type="newEasement.digital ? 'screen' : 'map'"
            :fill="newEasement.digital ? 'var(--main-color)' : 'var(--warning-color)'"
            :tooltip="newEasement.digital ? 'Digitalizovaný katastr' : 'Katastr bez digitalizace'" />
        </FormulateForm>
        <div class="easement-step-main" :class="{ 'disabled-step': !cadasterSelected, }">
          <p class="easement-description required">
            Vyplňte informace o geometrickém plánu
          </p>
          <FileForm :schema="schemaPdf" v-if="$router.currentRoute.name !== 'editEasement'" :fileType="'pdf'"
            :uuid="uuid" :cadaster_code="newEasement.cadaster_code" :disabled="!cadasterSelected"
            @data-send="updateCaseData($event)" />
          <FormulateForm name="form" ref="form" :schema="schema" id="main_form" @submit="addNewEasement($event)"
            v-model="initialFormData" class="easement-step-main-form" @pickedSuggestContract="prepareSketchCon($event)" />
        </div>
      </div>
      <div class="new-easement-column">
        <FileForm class="easement-step-grid" v-if="$router.currentRoute.name !== 'editEasement'" :schema="schemaZVB"
          :fileType="'zvb'" :correctCadaster="cadasterSelected" :cadasterDigital="cadasterDigital"
          :cadaster_code="newEasement.cadaster_code" :disabled="!cadasterDigital || !cadasterSelected"
          :tableSend="getVfkResponseXLS" :uuid="uuid" @data-send="prepareSchema('zvb')" />
        <FileForm class="easement-step-spi" v-if="$router.currentRoute.name !== 'editEasement'"
          :class="{ 'disabled-step': !(cadasterDigital && cadasterSelected && (getVfkResponseZVB || getVfkResponseXLS)) }"
          :schema="schemaSPI" :fileType="'spi'" :disabled="disableSPI" :cadaster_code="newEasement.cadaster_code"
          :uuid="uuid" @data-send="prepareSchema('spi')" />
        <div v-if="$router.currentRoute.name === 'editEasement' && initialFormData.download_report"
          class="download-easement-zip">
          <Spinner v-if="getLoadReport" />
          <p class="easement-description"> Všechny soubory věcného břemena: </p>
          <Button type="submit" class="cadaster-button" height="60" textAlign="center" label='Stáhnout soubory'
            @action="downloadResources" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from 'vue-simple-spinner';
import HSROSpinner from '@/components/generic/Spinner.vue';

import { ALERT_TEXT } from '@/config';
import Icon from '@/components/generic/Icon.vue';
import Button from '@/components/generic/Button.vue';
import FileForm from './FileForm.vue';
import {
  easementsForm,
  easementCadasterForm,
  easementSPIForm,
  easementZVBForm,
  easementPDFForm
} from '@/config/formSchemas';
import { VFSchema, deepClone } from '@/utils';
import { uuid } from 'vue-uuid';

export default {
  name: 'EasementsForm',
  components: {
    Button,
    FileForm,
    Spinner,
    HSROSpinner,
    Icon
  },
  data: () => {
    return {
      getSketchContractor: [],
      cadasterSelected: false,
      cadasterDigital: false,
      uuid: uuid.v1(),
      schema: [],
      schemaCadaster: [],
      schemaPdf: [],
      schemaZVB: [],
      schemaSPI: [],
      initialFormData: {},
      initialFormDataCadaster: {},
      newEasement: {},
      disableSPI: true
    };
  },
  watch: {
    initialFormDataCadaster: function ({ cadaster }) {
      if (cadaster === '') {
        this.clearCadaster();
      }
    }
  },
  destroyed() {
    this.clearUpdatedRows();
    this.clearRows();
  },
  created() {
    this.clearVfkResponses();
    this.updateSchema();
  },
  updated() {
    if (this.getVfkResponseXLS) {
      this.disableSPI = !this.getVfkResponseXLS;
      document.getElementById('spi').disabled = !this.getVfkResponseXLS;
    }
  },
  computed: mapGetters([
    'getVfkResponseZVB',
    'getVfkResponseXLS',
    'getVfkResponsePDF',
    'getVfkResponseSPI',
    'getLoadReport',
    'getEasement',
    'getLoading'
  ]),
  methods: {
    ...mapActions([
      'addEasement',
      'updateEasement',
      'fetchEasement',
      'clearVfkResponses',
      'clearUpdatedRows',
      'clearRows',
      'downloadResources'
    ]),
    updateCaseData(caseData) {
      this.initialFormData = { ...this.initialFormData, ...caseData };
    },
    prepareSchema(id) {
      this.schema.map((row) =>
        row.children.map(
          (el) =>
            (document.getElementById(el.id).disabled = !this.cadasterSelected)
        )
      );
      if (id !== 'edit') {
        if (id === 'zvb' || id === 'xls') {
          this.disableSPI = false;
          document.getElementById('spi').disabled = false;
        }
        if (id === 'cadaster') {
          document.getElementById('pdf').disabled = !this.cadasterSelected;
          document.getElementById('zvb').disabled =
            !this.cadasterDigital || !this.cadasterSelected;
        }
      }
    },
    async addNewEasement(e) {
      if (this.testUnsendFiles()) {
        const data = {
          ...{
            uuid: this.uuid,
            cadaster_code: this.newEasement.cadaster_code,
            name: 'Věcné břemeno'
          },
          ...e
        };
        if (this.$router.currentRoute.name === 'editEasement') {
          const editData = deepClone(
            data,
            [
              'cadaster',
              'download_report',
              'parcel_owners',
              'vfk_easement_date',
              'vfk_spi_valid_date'
            ],
            'exclude'
          );
          await this.updateEasement(editData);
        } else {
          await this.addEasement(data);
        }
        this.$router.back();
        return false;
      }
      this.openModal({
        type: 'error',
        heading: 'Chyba',
        text: 'Nahrajte prosím vložené soubory.'
      });
    },
    async updateSchema() {
      this.schema = await VFSchema(easementsForm.schema)
        .bindGetters()
        .bindClasses()
        .toFormRows()
        .get();
      this.schemaCadaster = await VFSchema(easementCadasterForm.schema)
        .bindGetters()
        .get();
      this.schemaSPI = VFSchema(easementSPIForm.schema).bindClasses().get();
      this.schemaZVB = VFSchema(easementZVBForm.schema).bindClasses().get();
      this.schemaPdf = VFSchema(easementPDFForm.schema).bindClasses().get();
      const router = this.$router.currentRoute;
      if (router.name === 'editEasement') {
        this.cadasterSelected = true;
        await this.fetchEasement(router.params.easement_id);
        this.initialFormData = deepClone(
          this.getEasement(router.params.easement_id)
        );
        this.initialFormDataCadaster = deepClone(
          this.getEasement(router.params.easement_id)
        );
        document.getElementById('cadaster').disabled = true;
        this.prepareSchema('edit');
      }
    },

    testUnsendFiles() {
      if (this.$router.currentRoute.name !== 'editEasement') {
        const pdf = document.querySelector(`[id=pdf]`).files.length;
        const zvb = document.querySelector(`[id=zvb]`).files.length;
        const spi = document.querySelector(`[id=spi]`).files.length;
        if (pdf > 0 && !this.getVfkResponsePDF) {
          return false;
        } else if (zvb > 0 && !this.getVfkResponseZVB) {
          return false;
        } else if (spi > 0 && !this.getVfkResponseSPI) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
    pickCadaster(cadaster) {
      this.newEasement.cadaster_code = cadaster.id;
      this.newEasement.cadaster = cadaster.label;
      this.newEasement.digital = cadaster.digital;
      this.sendCadaster();
    },
    clearCadaster() {
      this.newEasement.cadaster_code = null;
      this.newEasement.cadaster = null;
      this.newEasement.digital = null;
      this.cadasterSelected = false;
      this.cadasterDigital = false;
      this.prepareSchema('cadaster');
    },
    sendCadaster() {
      let message = true;
      if (this.newEasement.digital) {
        this.cadasterSelected = true;
        this.cadasterDigital = true;
      } else if (!this.newEasement.digital) {
        message = false;
        this.cadasterSelected = true;
        this.openModal({
          text: ALERT_TEXT.missingDigitizationMsg(this.newEasement.cadaster),
          type: 'warning',
          heading: 'Pozor'
        });
      }
      if (this.newEasement.cadaster_code === '' && message) {
        this.openModal({
          text: ALERT_TEXT.cadasterNonExistingMsg(this.newEasement.cadaster),
          type: 'error',
          heading: 'Chyba'
        });
        this.newEasement.cadaster = '';
      }
      this.prepareSchema('cadaster');
    },
    prepareSketchCon(e) {
      this.newEasement.sketchcontractor = e.label;
    },
    openModal(config) {
      const { openModal } = this.$root.$children[0].$refs.modal;
      return openModal(config);
    }
  }
};
</script>

<style scoped >
@import '../../styles/new-easement.css';
</style>
