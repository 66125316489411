export const CONFIG_MENU = [
  {
    icon: 'site',
    label: 'Stavby',
    routerPath: 'sites',
    hidePath: ['sites', 'newSite', 'editSite', 'projects'],
    subSteps: [
      {
        label: 'Věcná břemena (GP)',
        routerPath: 'easements'
      },
      {
        label: 'Listy vlastnictví',
        routerPath: 'ownerfolios'
      },
      {
        label: 'Věcná břemena na parcelách',
        routerPath: 'parcels'
      },
      {
        label: 'Vlastníci na LV',
        routerPath: 'owners'
      },
      {
        label: 'Smlouvy',
        routerPath: 'contracts'
      }
    ]
  },
  {
    icon: 'settings',
    label: 'Nastavení',
    routerPath: 'settings',
    hidePath: ['projects'],
    subSteps: [
      {
        label: 'Subjekty',
        routerPath: 'subjects'
      },
      {
        label: 'Kategorie',
        routerPath: 'categories'
      },
      {
        label: 'Typy zařízení',
        routerPath: 'equipments'
      },
      {
        label: 'Pravidla',
        routerPath: 'rules'
      },
      {
        label: 'Šablony',
        routerPath: 'templates'
      }
    ]
  },
  {
    icon: 'info',
    label: 'O Aplikaci',
    routerPath: 'about',
    subSteps: []
  },
  {
    icon: 'support',
    label: 'Podpora',
    routerPath: 'support',
    subSteps: []
  }
];
