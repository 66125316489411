<template>
  <div>
    <div class="popup">
      <span v-if="!popupHtml && getClipboardData &&showTable" :style="{position:'relative'}">
        <h1 class=" popupHeader">Kontrola a editace dat</h1>
        <p class="popupDesc">
          Prosím zkontrolujte data načtená v tabulce. Upravte infomraci o typu pozemku - zvolte zda se jedná o <b>
            pozemkovou </b> nebo <b> stavební </b> parcelu.
        </p>
        <FormulateInput type="table" key="1" :uuid="uuid"
          :code="cadaster_code" />
        <div class="text-under-table">
          <p>Chcete-li změnit data pro import z tabulky – stiskněte tlačítko <b>Vrátit zpět</b>.</p>
          <p>Pokud jste s přípravou dat spokojeni – stiskněte tlačítko <b> Uložit data</b>.</p>
        </div>
        <Button width="200"
          height="40"
          padding="10 17" textAlign="center" label="Vrátit zpět"
          invert
          margin="6px 0 0 calc(100% - 435px)"
          @action="configTable" />
      </span>
      <span v-if="popupHtml ">
        <h1 class="popupHeader">Načtená data ze schránky</h1>
        <p class="popupDesc">
          Vyberte k datům správný název sloupce (“Číslo LV”, “Délka trasy sítě”, “Plocha VB,” “Dotčená parcela na LV”).
        </p>
        <p class="popupDesc">
          V případě nepotřebných dat ponechte pro další zpracování název sloupce prázdný. </p>
        <FormulateInput type="table" key="2" :changeColl="true" :uuid="uuid" :code="cadaster_code"
          v-if="getClipboardData" />
        <div class="desc-edit-under"> <span>
            <p class="popupDesc">
              Pokud jste s výběrem dat spokojeni – stiskněte tlačítko <b>Potvrdit data.</b> </p>
            <p class="popupDesc validation">
              Pro správné načtení dat z tabulky je povinné vyplnit pole “Dotčená parcela na LV"</p>
          </span>
          <Button width="200"
            height="40"
            padding="10 17" textAlign="center" margin="10px" label="Potvrdit změny"
            @action="configTable" />
        </div>
      </span>
      <span v-if="!getClipboardData && !showTable && !popupHtml" class="no-premission-text">
        Prosím, povolte přístup do vaší
        schránky!
      </span>
    </div>
  </div>
</template>

<script>
import Button from '../generic/Button.vue';
import { mapActions, mapGetters } from 'vuex';
import { TABLE_CONFIG } from '@/config';

export default {
  name: 'EasementsDescInfoForm',
  components: { Button },
  props: {
    uuid: {
      type: String,
      required: true
    },
    cadaster_code: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      popupHtml: false,
      clipboard: '',
      test: null,
      showTable: false,
      showConfigValidation: false
    };
  },
  created() {
    this.validateClibpboard();
  },
  computed: mapGetters(['getTableClipboardConfig', 'getClipboardData']),
  methods: {
    ...mapActions(['changeClipboardData']),
    async validateClibpboard() {
      let clipboard = await navigator.clipboard.readText();
      let substrings = clipboard.split(/\r/);
      let data = substrings.map((str) => str.split(/\t/));
      if (data.length === 1) {
        this.openModal({
          type: 'error',
          heading: 'Chyba',
          text: 'Načtená data ze schránky nelze použít pro tvorbu tabulky!'
        });
      }
      // localstorage save the options for the headers in table
      this.getClipboardData
        ? null
        : localStorage.setItem(
            'tableOptions',
            JSON.stringify(TABLE_CONFIG.T_SETTINGCSV)
          );

      // if clipboard data is different then the last clipboard data used
      if (
        !this.getClipboardData ||
        JSON.stringify(this.getClipboardData) !== JSON.stringify(data)
      ) {
        await this.changeClipboardData(data);
        this.showTable = true;
      } else {
        this.showTable = true;
      }
      // check if clipboard table has the same number of colls as the set table
      if (
        this.getClipboardData[0].length !==
        Object.keys(this.getTableClipboardConfig).length
      ) {
        this.popupHtml = !this.popupHtml;
      }
    },
    configTable() {
      this.popupHtml = !this.popupHtml;
    },
    closeModal() {
      const { closeModal } = this.$root.$children[0].$refs.modal;
      closeModal();
    },
    openModal(config) {
      const { openModal } = this.$root.$children[0].$refs.modal;
      return openModal(config);
    }
  }
};
</script>

<style>
@import '../../styles/popup.css';
.no-premission-text {
  margin: 20px 0;
  padding: 5px;
  overflow: hidden;
  overflow-y: auto;
  max-height: 450px;
  color: red;
}

.validation {
  color: red;
  font-size: 12px;
}
.collapsibleCustom {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.collapsibleCustom:hover {
  cursor: pointer;
}
.desc-edit-under {
  display: flex;
  justify-content: space-between;
}
.text-under-table {
  margin: -52px 0;
  color: #00377b;
}
.text-under-table p {
  margin-bottom: 10px;
}
@media only screen and (max-width: 1300px) {
  .text-under-table {
    margin: -71px 0;
    width: 41%;
  }
}
</style>