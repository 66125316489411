const isRequired = () => {
  throw new Error('resourceName is required to pass to ResourceManager function!');
};

class _RM {
  constructor(resourceName) {
    this.resource = resourceName;
    this.all = this.capitalize(resourceName);
    this.one = this.capitalizeSingularize(resourceName);
  }

  capitalize(string) {
    return string.slice(0, 1).toUpperCase() + string.slice(1);
  }

  capitalizeSingularize(string) {
    const capped = this.capitalize(string);
    if (capped === 'Categories') return 'Category';
    return capped.slice(0, -1);
  }

  getters() {
    const getAll = 'get' + this.all;
    const getOne = 'get' + this.one;
    return { getAll, getOne };
  }

  actions() {
    const fetchAll = 'fetch' + this.all;
    const fetchOne = 'fetch' + this.one;
    const add = 'add' + this.one;
    const update = 'update' + this.one;
    const remove = 'remove' + this.one;
    return { fetchAll, fetchOne, add, update, remove };
  }

  mutations() {
    const set = 'set' + this.all;
    const add = 'add' + this.one;
    const update = 'update' + this.one;
    const remove = 'remove' + this.one;
    return { set, add, update, remove };
  }

  vuexHandlers() {
    return {
      getters: this.getters(),
      actions: this.actions(),
      mutations: this.mutations()
    };
  }
}

export const RMClass = _RM;

export const ResourceManager = (resourceName = isRequired(), pluralException = false) => {
  if (!pluralException && resourceName.slice(-1) !== 's') {
    throw new Error('ResourceManager: resourceName should be plural, i.e. ending with "s"');
  }
  return new _RM(resourceName);
};
